import React from 'react';
import { QueryClient, type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  InfiniteQuery,
  type HttpBaseConfig,
  type QueryProps,
  type InfiniteQueryProps,
} from 'js/api/';

import { Component } from '..';

/**
 * GET - /api/v1/eames/items
 */

// HTTP

interface GetComponentsXhr {
  data: Component[];
  totalCount: number;
  totalPages: number;
}

export interface GetComponentsXhrParams {
  page?: number;
  perPage?: number;
  searchInput?: string;
  projectId?: number;
  only_components?: boolean;
}

export interface GetComponentsXhrConfig extends HttpBaseConfig {
  params?: GetComponentsXhrParams;
}

const CONST_PARAMS = {
  only_components: true,
};

export const getComponentsXhr = (
  config: GetComponentsXhrConfig = {}
): Promise<GetComponentsXhr> =>
  http
    .get<GetComponentsXhr>(`/api/v1/eames/items`, config)
    .then((res) => res.data);

const GET_PROJECT_GROSS_SKUS_QUERY_KEY_NAMESPACE = 'get-components';

// Query Key

type GetComponentsQueryKeyNamespace =
  typeof GET_PROJECT_GROSS_SKUS_QUERY_KEY_NAMESPACE;

export type GetComponentsQueryKey = [
  GetComponentsQueryKeyNamespace,
  GetComponentsXhrParams? /* params */
];

export const mapGetComponentsQuery = (
  key: SkipFirst<GetComponentsQueryKey>
): GetComponentsQueryKey => [
  GET_PROJECT_GROSS_SKUS_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

type GetComponentsQueryFn = QueryFunction<
  GetComponentsXhr,
  GetComponentsQueryKey
>;

export const getComponentsQueryFn: GetComponentsQueryFn = ({
  queryKey: [_d, params],
  pageParam,
}) =>
  getComponentsXhr({ params: { ...params, ...pageParam, ...CONST_PARAMS } });

// Query

export interface GetComponentsQueryProps
  extends Omit<
    QueryProps<
      GetComponentsXhr,
      Error,
      GetComponentsXhr,
      GetComponentsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params?: GetComponentsXhrParams;
}

export const GetComponentsQuery = ({
  params,
  ...props
}: GetComponentsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetComponentsQuery([params])}
      queryFn={getComponentsQueryFn}
    />
  );
};

// Infinite Query

export interface GetComponentsInfiniteQueryProps
  extends Omit<
    InfiniteQueryProps<
      GetComponentsXhr,
      Error,
      GetComponentsXhr,
      GetComponentsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params?: GetComponentsXhrParams;
}

export type GetComponentsQueryResult = Parameters<
  GetComponentsInfiniteQueryProps['children']
>[0];

export const GetComponentsInfiniteQuery = ({
  params,
  options,
  ...props
}: GetComponentsInfiniteQueryProps) => {
  return (
    <InfiniteQuery
      {...props}
      queryKey={mapGetComponentsQuery([params])}
      queryFn={getComponentsQueryFn}
      options={{
        getNextPageParam: (
          _p,
          pages
        ): GetComponentsXhrConfig['params'] | undefined => {
          const totalPages = pages[0]?.totalPages;
          const currentPage = pages.length;
          const hasMore = currentPage < totalPages;

          if (!hasMore) return undefined;

          const pageParam: GetComponentsXhrParams = {
            page: currentPage + 1,
          };

          return pageParam;
        },
        ...options,
      }}
    />
  );
};

// Query Cache Helpers

export const invalidateComponentsInfiniteQueryData = async (
  client: QueryClient
) => {
  await client.invalidateQueries(GET_PROJECT_GROSS_SKUS_QUERY_KEY_NAMESPACE);
};
