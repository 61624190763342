// Event names for Segment's 'track' events
export const TRACKED_EVENTS = {
  LOGGED_IN: 'Logged In',
  LOGGED_OUT: 'Logged Out',

  PASSWORD_RESET: 'Password Reset',
  PASSWORD_RESET_REQUESTED: 'Password Reset Requested',

  PROFILE_EDITED: 'Profile Edited',

  PROJECT_ADDED: 'Project Added',
  PROJECT_EDITED: 'Project Edited',
  PROJECT_EDIT_CANCELLED: 'Project Edit Cancelled',

  ROOM_ADDED: 'Room Added',
  ROOM_EDITED: 'Room Edited',
  ROOM_DELETED: 'Room Deleted',

  PRODUCT_RESERVED: 'Product Reserved',

  RESERVED_PRODUCT_QTY_UPDATED: 'Reserved Product Quantity Updated',
  RESERVED_PRODUCT_DELETED: 'Reserved Product Deleted',
  RESERVED_PRODUCT_MOVED: 'Reserved Product Moved',
  RESERVED_PRODUCT_MOVED_PROJECT: 'Reserved Product Moved to Another Project',

  PRODUCTS_SEARCHED: 'Products Searched',

  PROJECTS_FILTERED_BY_ADMIN: 'Projects Filtered by Admin',
  PROJECTS_FILTERED_BY_DESIGNER: 'Projects Filtered by Designer',

  PROJECT_STATUS_DELIVERY_REQUESTED: 'Project Delivery Requested',
  PROJECT_STATUS_RETURN_REQUESTED: 'Project Return Requested',
  PROJECT_STATUS_CLOSE_REQUESTED: 'Project Close Requested',

  PROJECT_WALK_THROUGH_DATE_CHANGED: 'Walk Through Date Changed',
  PROJECT_RESERVATION_DATE_CHANGED: 'Reservation Start Date Changed',
  PROJECT_INSTALL_DATE_CHANGED: 'Install Date Changed',
  PROJECT_COMPLETED_DATE_SET: 'Marked as Complete',
  PROJECT_COMPLETED_DATE_CHANGED: 'Completed Date Changed',
  PROJECT_LOST_DATE_CHANGED: 'Lost Date Changed',

  PROJECT_MAP_LINK_CLICKED: 'Google Maps Link Clicked',

  SALES_SHEET_REQUESTED: 'Sales Sheet Requested',
  SALES_SHEET_VIEWED: 'Sales Sheet Viewed',

  RESERVATION_LIST_REQUESTED: 'Reservation List Requested',
  RESERVATION_LIST_VIEWED: 'Reservation List Viewed',

  CATEGORY_CLICKED: 'Category Clicked',
  SUBCATEGORY_CLICKED: 'Subcategory Clicked',
} as const;

export type TrackedEventName =
  typeof TRACKED_EVENTS[keyof typeof TRACKED_EVENTS];

// Event names for Segment's 'page' events
export const TRACKED_PAGES = {
  LOGIN: 'Login',

  PROJECT_LIST: 'Project List',
  PROJECT_OVERVIEW: 'Project Overview',
  PROJECT_STAGING: 'Project Staging',
  PROJECT_TRACKING: 'Project Tracking',

  PROJECT_CREATE: 'Create Project',
  PROJECT_EDIT: 'Edit Project',

  PROFILE_EDIT: 'Edit Profile',
  PROFILE_VIEW: 'View Profile',

  PRODUCT_DETAIL: 'Product Detail',
} as const;

type TrackedPageName = typeof TRACKED_PAGES[keyof typeof TRACKED_PAGES];

/**
 * Wrapper for sending Segment's analytics events.
 * Depending on passed in trackedEventName, will send either a 'page' or 'track' event.
 */
export function track(
  trackedEventName: TrackedEventName | TrackedPageName,
  properties = {}
): void {
  const method = Object.values(TRACKED_EVENTS).includes(
    trackedEventName as TrackedEventName
  )
    ? 'track'
    : Object.values(TRACKED_PAGES).includes(trackedEventName as TrackedPageName)
    ? 'page'
    : '';

  if (method) {
    // console.info('Analytics:', method, `"${trackedEventName}"`, properties);
    //Segment.send(method, trackedEventName, properties);
  } else {
    console.error(`Unsupported analytics event: ${trackedEventName}`);
  }
}
