import React from 'react';
import { QueryClient, type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { ProjectNote } from '../';

/**
 * GET - /api/v1/eames/eames_notes
 * +
 * Required params
 * audience =  'eames'
 * notable_type = 'Project'
 * notable_id = :projectId
 */

// HTTP

export type GetProjectNotesXhr = ProjectNote[];

export interface GetProjectNotesXhrConfig extends HttpBaseConfig {
  params: GetProjectNotesXhrParams;
}

export interface GetProjectNotesXhrParams {
  projectId: number;
}

export const getProjectNotesXhr = (
  config: GetProjectNotesXhrConfig
): Promise<ProjectNote[]> => {
  return http
    .get<GetProjectNotesXhr>(`/api/v1/eames/eames_notes`, config)
    .then(async (res) => res.data);
};

const GET_PROJECT_NOTES_QUERY_KEY_NAMESPACE = 'project/notes';

// Query Key

type GetProjectNotesQueryKeyNamespace =
  typeof GET_PROJECT_NOTES_QUERY_KEY_NAMESPACE;

export type GetProjectNotesQueryKey = [
  GetProjectNotesQueryKeyNamespace,
  GetProjectNotesXhrParams
];

export const mapGetProjectNotesQuery = (
  key: SkipFirst<GetProjectNotesQueryKey>
): GetProjectNotesQueryKey => [GET_PROJECT_NOTES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getProjectNotesQueryFn: QueryFunction<
  GetProjectNotesXhr,
  GetProjectNotesQueryKey
> = ({ queryKey: [_d, params], signal }) =>
  getProjectNotesXhr({ params, signal });

// Query

export interface GetProjectNotesQueryProps
  extends Omit<
    QueryProps<
      GetProjectNotesXhr,
      Error,
      GetProjectNotesXhr,
      GetProjectNotesQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params: GetProjectNotesXhrParams;
}

export type GetProjectNotesQueryResult = Parameters<
  GetProjectNotesQueryProps['children']
>[0];

export const GetProjectNotesQuery = ({
  params,
  ...props
}: GetProjectNotesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetProjectNotesQuery([params])}
      queryFn={getProjectNotesQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetProjectNotesQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetProjectNotesQueryKey>
) => {
  await client.invalidateQueries(mapGetProjectNotesQuery(keyParams));
};
