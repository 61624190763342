import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  type Material,
  type MaterialId,
  invalidateProductMaterialsQueryData,
} from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/materials/:id
 */

// HTTP
export interface UpdatedMaterialType
  extends Partial<Pick<Material, 'name' | 'type' | 'instructions'>> {
  image?: string;
}

export interface UpdatedSubMaterialType
  extends UpdatedMaterialType,
    Pick<Material, 'id'> {}

export interface UpdateMaterialBody {
  material: UpdatedMaterialType;
  submaterial?: UpdatedSubMaterialType;
}
export interface UpdateMaterialXhrVariables {
  materialId: MaterialId;
  body: UpdateMaterialBody;
}

export interface UpdateMaterialXhrResponse {
  data: Omit<Material, 'subMaterials'>;
}

export const updateMaterialXhr = ({
  materialId,
  body,
}: UpdateMaterialXhrVariables): Promise<Material> => mockResponse({});
// http
//   .put<UpdateMaterialXhrResponse>(
//     `/inventory/v1/materials/${materialId}`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateMaterialMutation = MutationFunction<
  Material,
  UpdateMaterialXhrVariables
>;

export const updateMaterialMutationFn: UpdateMaterialMutation = (variables) =>
  updateMaterialXhr(variables);

// With Mutation HOC

const withUpdateMaterialMutationPropKey = 'updateMaterialMutation';

export type WithUpdateMaterialMutationProps = WithMutationPropsByMutation<
  typeof withUpdateMaterialMutationPropKey,
  UpdateMaterialMutation
>;

export function withUpdateMaterialMutation<
  P extends WithUpdateMaterialMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateMaterialMutationProps>> {
  return withMutation(
    withUpdateMaterialMutationPropKey,
    updateMaterialMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductMaterialsQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateMaterialMutationProps>);
}
