import styled, { css } from 'styled-components';

import IconBullet from 'js/icons/Bullet';

export type TooltipPosition = 'center' | 'right';

export const tooltipPositionStyles = (position?: TooltipPosition) => {
  switch (position) {
    case 'right':
      return css`
        &::after {
          left: 120%;
        }
        &::before {
          left: 50%;
        }
      `;
    case 'center':
    default:
      return css`
        &::after {
          left: 50%;
        }
        &::before {
          left: 50%;
        }
      `;
  }
};

export const tooltipStyles = (
  message: string,
  position?: TooltipPosition
) => css`
  &::after {
    background: rgba(0, 0, 0, 0.9);
    border-radius: 3px;
    color: #fff;
    content: '${message}';
    font-weight: 400;
    font-size: 12px;
    padding: 4px 10px;
    position: absolute;
    top: 100%;
    white-space: nowrap;
    transform: translateX(-50%);
  }

  &::before {
    bottom: 0;
    border-bottom: 4px solid rgba(0, 0, 0, 0.9);
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    content: '';
    margin-left: -3px;
    position: absolute;
  }

  &::after,
  &::before {
    display: none;
    z-index: 1;
  }

  &:hover {
    &::after,
    &::before {
      display: block;
    }
  }

  ${tooltipPositionStyles(position)}
`;

export const IconBulletStyled = styled(IconBullet)`
  margin: 0 9px 1px -3px;
  transform: rotate(90deg);
`;

export const DesignerError = styled.div`
  margin-top: 100px;

  .error {
    padding: 100px 50px;
    text-align: center;
    background: #fff;
  }
`;
