import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateCollectionsQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - /inventory/v1/collections/:id
 */

// HTTP

export interface DeleteCollectionXhrVariables {
  collectionId: number;
}

export const deleteCollectionXhr = ({
  collectionId,
}: DeleteCollectionXhrVariables): Promise<unknown> => mockResponse({});
// http.delete<unknown>(`/inventory/v1/collections/${collectionId}`);

// MutationFn

type DeleteCollectionMutation = MutationFunction<
  unknown,
  DeleteCollectionXhrVariables
>;

export const deleteCollectionMutationFn: DeleteCollectionMutation = (
  variables
) => deleteCollectionXhr(variables);

// With Mutation HOC

const withDeleteCollectionMutationPropKey = 'deleteCollectionMutation';

export type WithDeleteCollectionMutationProps = WithMutationPropsByMutation<
  typeof withDeleteCollectionMutationPropKey,
  DeleteCollectionMutation
>;

export function withDeleteCollectionMutation<
  P extends WithDeleteCollectionMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteCollectionMutationProps>> {
  return withMutation(
    withDeleteCollectionMutationPropKey,
    deleteCollectionMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateCollectionsQueryData(client);
      },
    })
  )(Component as ComponentType<WithDeleteCollectionMutationProps>);
}
