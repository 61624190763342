import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  type RoomProduct,
  type WithMutationPropsByMutation,
  type RoomProductItemStatusCode,
} from 'js/api';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PATCH - /inventory/v1/room_products/update_statuses
 */

// HTTP

export interface UpdateStatusMultipleRoomProductsXhrVariables {
  body: UpdateStatusMultipleRoomProductsXhrBody;
}

export interface UpdateStatusMultipleRoomProductsXhrBody {
  statusId: RoomProductItemStatusCode;
  roomProductIds: RoomProduct['id'][];
}

export interface UpdateStatusMultipleRoomProductsXhrResponse {
  data: RoomProduct;
}

export const updateStatusMultipleRoomProductsXhr = ({
  body,
}: UpdateStatusMultipleRoomProductsXhrVariables): Promise<RoomProduct> =>
  mockResponse({});
// http
//   .patch<UpdateStatusMultipleRoomProductsXhrResponse>(
//     `/inventory/v1/room_products/update_statuses`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateStatusMultipleRoomProductsMutation = MutationFunction<
  RoomProduct,
  UpdateStatusMultipleRoomProductsXhrVariables
>;

export const updateStatusMultipleRoomProductsMutationFn: UpdateStatusMultipleRoomProductsMutation =
  (variables) => updateStatusMultipleRoomProductsXhr(variables);

// With Mutation HOC

const withUpdateStatusMultipleRoomProductsMutationPropKey =
  'updateStatusMultipleRoomProductsMutation';

export type WithUpdateStatusMultipleRoomProductsMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateStatusMultipleRoomProductsMutationPropKey,
    UpdateStatusMultipleRoomProductsMutation
  >;

export function withUpdateStatusMultipleRoomProductsMutation<
  P extends WithUpdateStatusMultipleRoomProductsMutationProps
>(
  Component: ComponentType<P>
): ComponentType<
  Omit<P, keyof WithUpdateStatusMultipleRoomProductsMutationProps>
> {
  return withMutation(
    withUpdateStatusMultipleRoomProductsMutationPropKey,
    updateStatusMultipleRoomProductsMutationFn
  )(
    Component as ComponentType<WithUpdateStatusMultipleRoomProductsMutationProps>
  );
}
