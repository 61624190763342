import styled, { css, type ThemeProps } from 'styled-components';

import { type Theme } from 'js/theme';
import IconChevronDown from 'js/icons/ChevronDown';

export const DropdownChevron = styled(IconChevronDown)`
  margin-left: 5px;
`;

export interface DropdownProps {
  block?: boolean;
  disabled?: boolean;
  styled?: boolean;
  focused?: boolean;
}

export const Dropdown = styled.span<DropdownProps>`
  position: relative;
  display: inline-block;
  font-size: 14px;
  text-align: left;

  ${(props) =>
    props.block
      ? css`
          display: block;
        `
      : ''};
  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.5;
        `
      : ''};
  ${(props) =>
    props.styled
      ? css`
          background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
          border: 1px solid #d5d7e7;
          border-radius: 3px;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          font-size: 14px;
          height: 40px;
          width: 100%;
          padding: 10px 15px;

          ${DropdownChevron} {
            position: absolute;
            right: 15px;
            top: 15px;
          }
        `
      : ''};
  ${(props) =>
    props.styled && props.focused
      ? css`
          border-color: ${(props: ThemeProps<Theme>) =>
            props.theme.colors.azure};
        `
      : ''}
`;

export interface DropdownValueProps {
  empty?: boolean;
}

export const DropdownValue = styled.span<DropdownValueProps>`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayXXDark};

  ${(props) =>
    props.empty
      ? css`
          color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayMid};
        `
      : ''};
`;

export const DropdownSelect = styled.select`
  cursor: pointer;
  left: 0;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-appearance: menulist-button; // Safari height hack: https://goo.gl/F2AoVh
  width: 100%;
`;
