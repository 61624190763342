export const roomProductMock = {
  id: 634736,
  product: {
    audit_tag: null,
    'status_ready_to_pick?': true,
    'status_on_site?': false,
    'status_damaged?': false,
    'status_investigate?': false,
    'status_reserved?': true,
    unreserved: true,
    id: 22461,
    name: 'CLUB CHAIR ',
    customer_name: null,
    customer_description: null,
    sku: 'HH-022461',
    description: 'MEMPHIS CLUB CHAIR/ CORSICA 901',
    vendor_price: null,
    retail_price: null,
    available_by_region: {
      LA: {
        quantity: 1,
        total: 0,
      },
      FL: {
        quantity: 1,
        total: 8,
      },
      SF: {
        quantity: 0,
        total: 3,
      },
      NY: {
        quantity: 0,
        total: 0,
      },
      TS: {
        quantity: 0,
        total: 0,
      },
      QW: {
        quantity: 0,
        total: 0,
      },
      AS: {
        quantity: 0,
        total: 0,
      },
      GG: {
        quantity: 0,
        total: 0,
      },
    },
    available: {
      quantity: 1,
      total: 8,
    },
    pickable_quantity_by_region: {
      LA: 3,
      FL: 8,
      SF: 0,
      NY: 0,
      TS: 0,
      QW: 0,
      AS: 0,
      GG: 0,
    },
    pickable_quantity: 8,
    color: '',
    material: 'FABRIC',
    height: null,
    width: null,
    depth: null,
    length: null,
    seat_height: null,
    seat_depth: null,
    arm_height: null,
    weight: null,
    image: {
      id: 33599,
      source_url: 'https://cdn.filestackcontent.com/qjN38U1QE6XPaeVmJMWQ',
      url: 'https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
      md5: '45d5896d888f56e148e66b8c7cce26a6',
      s3_bucket: 'showroom-filestack/production',
      key: 'PMvTU9uTW6xnME6zddRA',
      normal_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:720,height:720,fit:clip/https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:240,height:240,fit:crop/https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
    },
    catalog_ready: false,
    filestack_images: [
      {
        id: 33599,
        source_url: 'https://cdn.filestackcontent.com/qjN38U1QE6XPaeVmJMWQ',
        url: 'https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
        md5: '45d5896d888f56e148e66b8c7cce26a6',
        s3_bucket: 'showroom-filestack/production',
        key: 'PMvTU9uTW6xnME6zddRA',
        normal_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:720,height:720,fit:clip/https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:240,height:240,fit:crop/https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
      },
    ],
    bins: [
      {
        name: 'F1 R100 F100 S100',
        count: 6,
      },
      {
        name: 'F1 R8 F1 S1',
        count: 1,
      },
      {
        name: 'F1 R11 F6 S2',
        count: 1,
      },
    ],
    bins_by_region: {
      LA: [
        {
          name: 'F1 R11 F2 S2',
          count: 3,
        },
        {
          name: 'F22311 R1231231 F23123121 S1',
          count: 3,
        },
      ],
      FL: [
        {
          name: 'F1 R100 F100 S100',
          count: 6,
        },
        {
          name: 'F1 R8 F1 S1',
          count: 1,
        },
        {
          name: 'F1 R11 F6 S2',
          count: 1,
        },
      ],
      SF: [],
      NY: [],
      TS: [],
      QW: [],
      AS: [],
      GG: [],
    },
    cant_be_picked: false,
    created_at: '2020-02-04T13:29:53.955Z',
    updated_at: '2020-02-06T16:25:33.882Z',
    images: [
      {
        image_type: 'hero',
        url: 'https://cdn.filestackcontent.com/PMvTU9uTW6xnME6zddRA',
      },
      {
        image_type: 'detail',
        url: null,
      },
      {
        image_type: 'in_project',
        url: null,
      },
      {
        image_type: '1',
        url: null,
      },
      {
        image_type: '2',
        url: null,
      },
      {
        image_type: '3',
        url: null,
      },
      {
        image_type: '4',
        url: null,
      },
      {
        image_type: '5',
        url: null,
      },
    ],
    styles: [],
    shoppable: false,
    com: null,
    com_details: null,
    custom_sizes: null,
    custom_sizes_details: null,
    'vendor_ecomm_eligible?': false,
    'shoppable_live?': false,
    'audit_and_not_shoppable?': false,
    'audit_and_shoppable?': false,
    project_images: [],
    collections: [],
    product_data: 'incomplete',
    photography_status: 'not_taken',
    proforma_invoice_products: [
      {
        id: 21154,
        expected_quantity: 12,
        cost: '113.0',
        name: '191210502-FL',
        proforma_invoice_id: 2392,
      },
    ],
    candidate: false,
    l1_old_category: null,
    l2_old_category: null,
    l3_old_category: null,
    materials: [],
    vendor_sku: null,
    base_height: null,
    state: 'standart',
    vendor: {
      id: 26,
      name: 'Bellagio Asia',
      ecomm_eligible: false,
      com_markup: null,
      custom_sizes_markup: null,
    },
    category: {
      id: 18,
      name: 'Lounge Chairs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: {
        id: 19,
        name: 'Indoor Furniture',
        min_quantity: 1,
        increment_quantity_by: 1,
        parent: null,
      },
    },
    product_types: [],
    proforma_invoices: [
      {
        id: 2392,
        name: '191210502-FL',
        expected_quantity: 12,
        remaining_quantity: 1,
      },
    ],
    tags: [],
  },
  quantity: 1,
  quantity_picked: 0,
  created_at: '2022-10-05T13:34:16.471Z',
  updated_at: '2022-10-05T13:34:16.531Z',
  room_type: 'Unassigned',
  room_id: 23538,
  room_type_text: 'Unassigned',
  statuses: {
    reserved: 1,
    delivery_requested: 0,
    ready_to_pick: 0,
    never_picked: 0,
    in_transit_out: 0,
    on_site: 0,
    return_requested: 0,
    in_transit_in: 0,
    lost: 0,
    sold: 0,
    damaged: 0,
    returned: 0,
    investigate: 0,
    deleted: 0,
  },
  room_product_items: [
    {
      id: 577245,
      room_product_id: 634736,
      item_id: null,
      status: 'reserved',
      created_at: '2022-10-05T13:34:16.514Z',
      updated_at: '2022-10-05T13:34:16.514Z',
      status_updated_at: '2022-10-05T13:34:16.513Z',
      creator_id: 1603,
      ad_hoc_picked_at: null,
      delivery_dateselected: '1',
      gross_sku: false,
    },
  ],
};
