import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  RoomProduct,
  withMutation,
  createGetRoomProductQueryKey,
  type WithMutationPropsByMutation,
  type RoomProductItemStatusCode,
  type GetRoomProductXhr,
} from 'js/api';
import { type IRoomProductItem } from 'js/api/rpi/models/model';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PATCH - /inventory/v1/room_products/:roomProductId/room_product_items/:roomProductItemId`
 */

// HTTP

export interface UpdateRoomProductItemStatusXhrVariables {
  roomProductId: RoomProduct['id'];
  roomProductItemId: IRoomProductItem['id'];
  body: UpdateRoomProductItemStatusXhrBody;
}

export interface UpdateRoomProductItemStatusXhrBody {
  status: RoomProductItemStatusCode;
}

export interface UpdateRoomProductItemStatusXhrResponse {
  data: IRoomProductItem;
}

export const updateRoomProductItemStatusXhr = ({
  roomProductId,
  roomProductItemId,
  body,
}: UpdateRoomProductItemStatusXhrVariables): Promise<IRoomProductItem> =>
  mockResponse({});
// http
//   .patch<UpdateRoomProductItemStatusXhrResponse>(
//     `/inventory/v1/room_products/${roomProductId}/room_product_items/${roomProductItemId}`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateRoomProductItemStatusMutation = MutationFunction<
  IRoomProductItem,
  UpdateRoomProductItemStatusXhrVariables
>;

export const updateRoomProductItemStatusMutationFn: UpdateRoomProductItemStatusMutation =
  (variables) => updateRoomProductItemStatusXhr(variables);

// Update cache

export const updateCacheForUpdateRoomProductItemStatusMutation = (
  client: QueryClient,
  updatedRoomProductItem: Awaited<
    ReturnType<typeof updateRoomProductItemStatusXhr>
  >,
  roomProductId: RoomProduct['id'],
  roomProductItemId: IRoomProductItem['id']
) => {
  client.setQueriesData<GetRoomProductXhr | undefined>(
    createGetRoomProductQueryKey([roomProductId]),
    (data) => {
      if (!data) return data;
      const updatedData = { ...data };

      // update status of roomProductItem
      updatedData.data.roomProductItems = data.data.roomProductItems.map(
        (roomProductItem) => {
          if (roomProductItem.id === roomProductItemId) {
            return {
              ...roomProductItem,
              status: updatedRoomProductItem.status,
            };
          }
          return roomProductItem;
        }
      );

      return updatedData;
    }
  );
};

// With Mutation HOC

const withUpdateRoomProductItemStatusMutationPropKey =
  'updateStatusRoomProductMutation';

export type WithUpdateRoomProductItemStatusMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateRoomProductItemStatusMutationPropKey,
    UpdateRoomProductItemStatusMutation
  >;

export function withUpdateRoomProductItemStatusMutation<
  P extends WithUpdateRoomProductItemStatusMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateRoomProductItemStatusMutationProps>> {
  return withMutation(
    withUpdateRoomProductItemStatusMutationPropKey,
    updateRoomProductItemStatusMutationFn,
    (client) => ({
      onSuccess: (roomProduct, { roomProductId, roomProductItemId }) => {
        // @TODO what else need invalidate
        updateCacheForUpdateRoomProductItemStatusMutation(
          client,
          roomProduct,
          roomProductId,
          roomProductItemId
        );
      },
    })
  )(Component as ComponentType<WithUpdateRoomProductItemStatusMutationProps>);
}
