import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';

import { SingleProduct } from 'js/api/products';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/products
 */

// HTTP

export type CreateNewProductXhrBody = Partial<SingleProduct>;

export interface CreateNewProductXhrResponse {
  data: SingleProduct;
}

export const createNewProductXhr = (
  body: CreateNewProductXhrBody
): Promise<CreateNewProductXhrResponse> => mockResponse({});
// http
//   .post<CreateNewProductXhrResponse>(`/inventory/v1/products`, body)
//   .then((res) => res.data);

// MutationFn

type CreateNewProductMutation = MutationFunction<
  CreateNewProductXhrResponse,
  CreateNewProductXhrBody
>;

export const createNewProductMutationFn: CreateNewProductMutation = (body) =>
  createNewProductXhr(body);

// With Mutation HOC

const withCreateNewProductMutationPropKey = 'createNewProductMutation';

export type WithCreateNewProductMutationProps = WithMutationPropsByMutation<
  typeof withCreateNewProductMutationPropKey,
  CreateNewProductMutation
>;

export function withCreateNewProductMutation<
  P extends WithCreateNewProductMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateNewProductMutationProps>> {
  return withMutation(
    withCreateNewProductMutationPropKey,
    createNewProductMutationFn
  )(Component as ComponentType<WithCreateNewProductMutationProps>);
}
