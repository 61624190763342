import React from 'react';
import { QueryClient, UseQueryOptions, type QueryFunction } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Style } from '../';
import { mockResponse } from 'js/api/mock/utils';
import { stylesMock } from 'js/api/mock/stylesMock';

/**
 * GET - /inventory/v1/styles
 */

// HTTP

export interface GetStylesXhr {
  data: Style[];
}

export interface GetStylesXhrParams {
  onlyActive?: boolean;
}

export interface GetStylesXhrConfig extends HttpBaseConfig {
  params?: GetStylesXhrParams;
}

export const getStylesXhr = (
  config: GetStylesXhrConfig = {}
): Promise<Style[]> => mockResponse(stylesMock);
// http
//   .get<GetStylesXhr>(`/inventory/v1/styles`, config)
//   .then((res) => res.data.data);

// Query Key

const GET_STYLES_QUERY_KEY_NAMESPACE = 'getStyles';

type GetStylesQueryKeyNamespace = typeof GET_STYLES_QUERY_KEY_NAMESPACE;

export type GetStylesQueryKey = [
  GetStylesQueryKeyNamespace,
  GetStylesXhrParams? /* params */
];

export const createGetStylesQueryKey = (
  key: SkipFirst<GetStylesQueryKey>
): GetStylesQueryKey => [GET_STYLES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetStylesQueryFn = QueryFunction<Style[], GetStylesQueryKey>;

export const getStylesQueryFn: GetStylesQueryFn = ({
  queryKey: [_d, params],
  signal,
}) => getStylesXhr({ params, signal });

// create Query Options

export type GetStylesQueryOptions = UseQueryOptions<
  Style[],
  unknown,
  Style[],
  GetStylesQueryKey
>;

export interface GetStylesQueryOptionsProps {
  params?: GetStylesXhrParams;
  options?: Omit<GetStylesQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetStylesQueryOptions = ({
  params,
  options,
}: GetStylesQueryOptionsProps): GetStylesQueryOptions => ({
  queryFn: getStylesQueryFn,
  queryKey: createGetStylesQueryKey([params]),
  ...options,
});

// Query

export interface GetStylesQueryProps
  extends Omit<
    QueryProps<Style[], unknown, Style[], GetStylesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetStylesXhrParams;
}

export type GetStylesQueryRenderFn = GetStylesQueryProps['children'];

export type GetStylesQueryResult = Parameters<GetStylesQueryRenderFn>[0];

export const GetStylesQuery = ({ params, ...props }: GetStylesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetStylesQueryKey([params])}
      queryFn={getStylesQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateStylesQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_STYLES_QUERY_KEY_NAMESPACE);
};
