import { SegmentService } from '@services/segment_service';
import { makeMock } from '@utility_functions/makeMock';
import { HttpService } from '../http_service';
import { SaveReferralCodeResponse } from './SaveReferralCodeResponse';

export interface SessionData {
  zipCode: string | null;
  deliverable: boolean;
  currentMarket: CurrentMarket;
  currentUser: CurrentUser | null;
  userPreferences: UserPreferences; // TODO - remove this
  flash: Flash;
  anonymousId: string;
  trackingMode: TrackingMode | null;
  impersonating?: boolean;
  segmentLocation: SegmentLocation | null;
}

export interface SegmentLocation {
  country: string;
  region: string;
  city: string;
  postal_code: string;
}
export interface TrackingMode {
  consumerTrackingMode: boolean;
  id: number;
}

export interface CurrentMarket {
  id: number;
  name: string;
  displayZipCheck: boolean;
  marketInferred: boolean;
  facebookCatalogId: number;
}

export interface CurrentUser {
  givenName: string;
  familyName: string;
  email: string;
  id: number;
  hasCompletedOrders: boolean;
  hasReferralCode: boolean;
  referralCode: string | null;
  currentOrderGuid: string | null;
  isB2bUser: boolean;
  isAdminUser: boolean;
  twoFactorRequired: boolean;
  twoFactorSetupNeeded: boolean;
  subscriberStartDate: string | null;
  emailAddressConfirmed: boolean;
  hasInvoiceDelinquency: boolean;
  noPaymentCheckout: boolean;
}

export interface UserPreferences {
  hide_wishlist_nudge: boolean;
}

export interface Flash {
  notice: string | null;
  alert: string | null;
  error: string | null;
  info: string | null;
}

export const mockCurrentUser = makeMock<CurrentUser>({
  twoFactorSetupNeeded: false,
  currentOrderGuid: null,
  email: 'aja@fernish.com',
  emailAddressConfirmed: false,
  givenName: 'Test',
  familyName: 'User',
  hasCompletedOrders: false,
  hasReferralCode: false,
  twoFactorRequired: false,
  referralCode: null,
  subscriberStartDate: null,
  id: 1,
  hasInvoiceDelinquency: false,
  noPaymentCheckout: false,
  isB2bUser: false,
  isAdminUser: false,
});

export const mockSessionData = makeMock<SessionData>({
  zipCode: '90002',
  deliverable: true,
  currentMarket: {
    displayZipCheck: true,
    id: 1,
    marketInferred: true,
    name: 'Southern California',
    facebookCatalogId: 123,
  },
  currentUser: mockCurrentUser({}),
  userPreferences: {
    hide_wishlist_nudge: false,
  },
  flash: {
    notice: null,
    alert: null,
    error: null,
    info: null,
  },
  anonymousId: '123456789',
  trackingMode: null,
  impersonating: false,
  segmentLocation: null,
});

const GET_SESSION_DATA_URL = '/api/v1/public/current_user/session_data';
function fetchSessionData(): Promise<SessionData> {
  if (process.env.NEXT_PUBLIC_DEV_MOCK_API_CALLS === 'true') {
    return Promise.resolve(mockSessionData({ currentUser: null }));
  }

  return HttpService.get(GET_SESSION_DATA_URL);
}

function saveReferralCode(code: string): Promise<SaveReferralCodeResponse> {
  return HttpService.post('/api/v1/public/current_user/save_referral_code', {
    code,
  });
}

function enterAdminTrackingMode(id: Number) {
  return HttpService.post(`/api/v1/admin/users/${id}/admin_tracking_mode`, {});
}

function enterConsumerTrackingMode(id: Number) {
  return HttpService.post(
    `/api/v1/admin/users/${id}/consumer_tracking_mode`,
    {}
  );
}

function leaveImpersonatingMode() {
  return HttpService.destroy('/admin/users/impersonation');
}

export const SessionService = {
  fetchSessionData,
  saveReferralCode,
  enterAdminTrackingMode,
  enterConsumerTrackingMode,
  leaveImpersonatingMode,
};
