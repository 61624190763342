import React, { useMemo } from 'react';
import { useQuery, type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { OrderTripLineItemsByStatus } from '../models';

/**
 * GET - /api/v1/eames/projects/:id/order_trip_line_items
 */

// HTTP

export interface OrderTripLineItemsByStatusXhr {
  data: OrderTripLineItemsByStatus;
}

export const getOrderTripLineItemsByStatusXhr = (
  projectId: number,
  config: HttpBaseConfig = {}
): Promise<OrderTripLineItemsByStatus> =>
  http
    .get<OrderTripLineItemsByStatusXhr>(
      `/api/v1/eames/projects/${projectId}/order_trip_line_items`,
      config
    )
    .then((res) => res.data.data);

// Query Key

const GET_ORDER_TRIP_LINE_ITEMS_BY_STATUS = 'getOrderTripLineItemsByStatus';

type GetOrderTripLineItemsByStatusQueryKeyNamespace =
  typeof GET_ORDER_TRIP_LINE_ITEMS_BY_STATUS;

export type GetOrderTripLineItemsByStatusQueryKey = [
  GetOrderTripLineItemsByStatusQueryKeyNamespace,
  number /* project id */
];

export const createGetOrderTripLineItemsByStatusQueryKey = (
  key: SkipFirst<GetOrderTripLineItemsByStatusQueryKey>
): GetOrderTripLineItemsByStatusQueryKey => [
  GET_ORDER_TRIP_LINE_ITEMS_BY_STATUS,
  ...key,
];

// QueryFn

export const getOrderTripLineItemsByStatusQueryFn: QueryFunction<
  OrderTripLineItemsByStatus,
  GetOrderTripLineItemsByStatusQueryKey
> = ({ signal, queryKey: [_d, projectId] }) =>
  getOrderTripLineItemsByStatusXhr(projectId, {
    signal,
  });

// Query

export interface UseGetOrderTripLineItemsByStatusQueryProps {
  projectId: number;
  options?: GetOrderTripLineItemsByStatusQueryProps['options'];
}

export const useGetOrderTripLineItemsByStatusQuery = ({
  projectId,
  options,
}: UseGetOrderTripLineItemsByStatusQueryProps) => {
  const queryKey = useMemo(
    () => createGetOrderTripLineItemsByStatusQueryKey([projectId]),
    [projectId]
  );

  return useQuery(queryKey, getOrderTripLineItemsByStatusQueryFn, options);
};

export interface GetOrderTripLineItemsByStatusQueryProps
  extends Omit<
    QueryProps<
      OrderTripLineItemsByStatus,
      unknown,
      OrderTripLineItemsByStatus,
      GetOrderTripLineItemsByStatusQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
}

export type GetOrderTripLineItemsByStatusQueryResult = Parameters<
  GetOrderTripLineItemsByStatusQueryProps['children']
>[0];

export const GetOrderTripLineItemsByStatusQuery = ({
  projectId,
  ...props
}: GetOrderTripLineItemsByStatusQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetOrderTripLineItemsByStatusQueryKey([projectId])}
      queryFn={getOrderTripLineItemsByStatusQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateOrderTripLineItemsByStatusQueryData = async (
  client: QueryClient,
  projectId: number
) => {
  await client.invalidateQueries(
    createGetOrderTripLineItemsByStatusQueryKey([projectId])
  );
};
