export const productTypesMock = [
  {
    id: 9,
    name: 'new 2',
    description: null,
    created_at: '2022-07-27T14:50:25.904Z',
    updated_at: '2023-02-03T15:30:14.158Z',
    status: 'active',
  },
  {
    id: 7,
    name: 'inactive product type',
    description: null,
    created_at: '2022-07-22T16:13:48.188Z',
    updated_at: '2023-03-02T11:31:25.884Z',
    status: 'active',
  },
  {
    id: 3,
    name: 'Furniture',
    description: null,
    created_at: '2021-09-20T13:27:02.592Z',
    updated_at: '2023-03-02T11:31:27.111Z',
    status: 'active',
  },
  {
    id: 2,
    name: 'Art',
    description: null,
    created_at: '2021-09-20T13:26:56.734Z',
    updated_at: '2023-03-02T11:31:27.779Z',
    status: 'active',
  },
  {
    id: 8,
    name: 'new 1',
    description: null,
    created_at: '2022-07-27T14:50:23.196Z',
    updated_at: '2023-03-02T11:31:29.168Z',
    status: 'active',
  },
  {
    id: 10,
    name: 'TEST by QA',
    description: null,
    created_at: '2023-03-20T20:52:15.329Z',
    updated_at: '2023-03-20T20:52:15.329Z',
    status: 'active',
  },
];
