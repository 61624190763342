export const newPriceInputMock = {
  project: {
    id: 2345,
    name: '7951 W Charleston Blvd  3',
    client: '',
    install_date: '2023-03-15',
    end_date: null,
    contract_value: null,
    contract_number: null,
    user_id: 1603,
    status: 'walk_through_completed',
    dock_id: 1,
    created_at: '2021-11-29T16:22:34.329Z',
    updated_at: '2023-11-13T20:05:01.807Z',
    designer_id: null,
    location_id: 5515,
    items_count: 28,
    items_picked: 0,
    items_received: 0,
    items_total: 22,
    designer_status: 'completed',
    designer_updated_at: null,
    designer_completed_at: '2021-11-27T00:00:00.000Z',
    total_retail_price: 15738.060000000001,
    reservation_start_date: '2023-03-25T00:00:00.000Z',
    last_movement_at: '2023-11-13T20:05:01.802Z',
    shopify_updated_at: null,
    location_lat: null,
    location_long: null,
    shopify_url: null,
    region: 'LA',
    discount: null,
    job_number: '',
    moveout_date: null,
    items_sold: 0,
    ua_items: 0,
    sqft: '',
    staging_term: null,
    initial_staging_fee: null,
    cpp: false,
    cpp_amount: null,
    contract_url: '',
    project_type: 'E-Commerce',
    salesforce_link: '',
    sqft_staged: null,
    additional_staging_term: null,
    monthly_rental_notes: null,
    make_public: false,
    description: null,
    nda: null,
    link_to_nda: '',
    customer_name: 'Andreeva Katy',
    image_rights: false,
    project_level: 'Made to Measure',
    customer_name_id: null,
    lost_at: '2021-11-27T00:00:00.000Z',
    release_form_link: '',
    slack_channel_name: '',
    date_posted: null,
    eames_job_number: '20230315 | 5515 | 2345 | E-Commerce | LA',
    estimated_install_days: 3,
    google_chat_link: '',
    acumatica_project_id: null,
    week_number: '11',
    year: '2023',
    room_product_items: [
      {
        id: 578609,
        item_name: 'Small details for Fireplace (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635925,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/WUY5ikExSPy57zZvbR6K',
        sku: 'HH-024142',
        room_name: 'Unassigned',
      },
      {
        id: 578608,
        item_name: 'Small details for Fireplace (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635925,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/WUY5ikExSPy57zZvbR6K',
        sku: 'HH-024142',
        room_name: 'Unassigned',
      },
      {
        id: 577755,
        item_name: 'Globe Table Lamp',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635337,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/lIk2hSgWRwSwJlayHZwO',
        sku: 'HH-022994',
        room_name: 'Unassigned',
      },
      {
        id: 577935,
        item_name: "Reception Desk 4' | Distressed Wood",
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '10960.00',
        item_id: null,
        room_product_id: 635421,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/IABUDRMCQjGLtiaNHMQF',
        sku: 'HH-022955',
        room_name: 'Unassigned',
      },
      {
        id: 577803,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577859,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577857,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577862,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577858,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577860,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577861,
        item_name: 'Coffee tables',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635357,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: null,
        room_name: 'Unassigned',
      },
      {
        id: 577936,
        item_name: '13.75" Low Bowl | White',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635422,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/okVz06jIQPCuW0wHebFb',
        sku: 'HH-023113',
        room_name: 'Unassigned',
      },
      {
        id: 577937,
        item_name: '13.75" Low Bowl | White',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635422,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/okVz06jIQPCuW0wHebFb',
        sku: 'HH-023113',
        room_name: 'Unassigned',
      },
      {
        id: 577938,
        item_name: '13.75" Low Bowl | White',
        customer_name: null,
        status: 'ready_to_pick',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635422,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/okVz06jIQPCuW0wHebFb',
        sku: 'HH-023113',
        room_name: 'Unassigned',
      },
      {
        id: 580536,
        item_name: 'Types product (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '2640.00',
        item_id: null,
        room_product_id: 636189,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/zRwIpx1qRsK3MWqui60o',
        sku: 'HH-024055',
        room_name: '111111111',
      },
      {
        id: 580535,
        item_name: 'New model chair (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '444.00',
        item_id: null,
        room_product_id: 636188,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: 'HH-024061',
        room_name: '111111111',
      },
      {
        id: 577601,
        item_name: 'Gunmetal Bar Stool | No Back',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '247.00',
        item_id: null,
        room_product_id: 636187,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/8jjOkIjRcql0etF6uFQP',
        sku: 'HH-022923',
        room_name: '111111111',
      },
      {
        id: 580534,
        item_name: 'Eternity Modern (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '40.00',
        item_id: null,
        room_product_id: 636184,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
        sku: 'HH-024063',
        room_name: '111111111',
      },
      {
        id: 580533,
        item_name: 'Small details for Fireplace (SI)',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 636183,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/WUY5ikExSPy57zZvbR6K',
        sku: 'HH-024142',
        room_name: '111111111',
      },
      {
        id: 580528,
        item_name: 'The Stanley Kubrick Archives',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 636181,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/CcGIaFN0SQrirpn4dvSb',
        sku: 'HH-022523',
        room_name: '111111111',
      },
      {
        id: 580527,
        item_name: 'The Stanley Kubrick Archives',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 636181,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/CcGIaFN0SQrirpn4dvSb',
        sku: 'HH-022523',
        room_name: '111111111',
      },
      {
        id: 580526,
        item_name: 'Large Decorative Pitcher  | 17.3x14x16.5"',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '169.02',
        item_id: null,
        room_product_id: 636180,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/rGDgH7UOQkWywGeVzbND',
        sku: 'HH-021687',
        room_name: '111111111',
      },
      {
        id: 580525,
        item_name: 'Large Decorative Pitcher  | 17.3x14x16.5"',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '169.02',
        item_id: null,
        room_product_id: 636180,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/rGDgH7UOQkWywGeVzbND',
        sku: 'HH-021687',
        room_name: '111111111',
      },
      {
        id: 580524,
        item_name: 'Large Decorative Pitcher  | 17.3x14x16.5"',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '169.02',
        item_id: null,
        room_product_id: 636180,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/rGDgH7UOQkWywGeVzbND',
        sku: 'HH-021687',
        room_name: '111111111',
      },
      {
        id: 580523,
        item_name: 'Test Product (Part 4 SI) - Created: 3/3/2022',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '400.00',
        item_id: null,
        room_product_id: 636177,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/5lOOTxhxQ7i1MITEYm3G',
        sku: 'HH-023996',
        room_name: '111111111',
      },
      {
        id: 580517,
        item_name: 'Test Product (Part 4 SI) - Created: 3/3/2022',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '400.00',
        item_id: null,
        room_product_id: 636177,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/5lOOTxhxQ7i1MITEYm3G',
        sku: 'HH-023996',
        room_name: '111111111',
      },
      {
        id: 580516,
        item_name: 'Cap LoranLorun | Supplemental inventory',
        customer_name: null,
        status: 'reserved',
        cycles: null,
        retail_price: '100.00',
        item_id: null,
        room_product_id: 636176,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/Hwhhbw9HS3qg5VjhLkRV',
        sku: 'HH-024105',
        room_name: '111111111',
      },
      {
        id: 577602,
        item_name: 'Gold Candle Holder',
        customer_name: null,
        status: 'never_picked',
        cycles: null,
        retail_price: '0.00',
        item_id: null,
        room_product_id: 635033,
        thumbnail_url:
          'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/QFHO79QRxu1uqkAbtlAf',
        sku: 'HH-022841',
        room_name: '111111111',
      },
    ],
    room_product_items_count: 28,
  },
};
