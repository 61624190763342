import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';

/**
 * POST - /api/v1/eames/items
 */

// HTTP

interface CreateComponentXhrVariables {
  body: CreateComponentXhrBody;
}

export interface CreateComponentXhrBody {
  projectId: number;
  baseCost: number;
  retailPrice?: number;
  name: string;
  description?: string;
  // images?: ImageBase[];
  itemTypeId: number;
  supplier?: string;
  quantity?: number;
}

const CONST_PARAMS = {
  widthUom: 'in',
  depthUom: 'in',
  heightUom: 'in',
  weightUom: 'kg',
};

export const createComponentXhr = ({
  body,
}: CreateComponentXhrVariables): Promise<unknown> =>
  http
    .post<unknown>(`/api/v1/eames/items`, { ...body, ...CONST_PARAMS })
    .then((res) => res.data);

// MutationFn

type CreateComponentMutation = MutationFunction<
  unknown,
  CreateComponentXhrVariables
>;

export const createComponentMutationFn: CreateComponentMutation = (variables) =>
  createComponentXhr(variables);

// With Mutation HOC

const withCreateComponentMutationPropKey = 'createComponentMutation';

export type WithCreateComponentMutationProps = WithMutationPropsByMutation<
  typeof withCreateComponentMutationPropKey,
  CreateComponentMutation
>;

export function withCreateComponentMutation<
  P extends WithCreateComponentMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateComponentMutationProps>> {
  return withMutation(
    withCreateComponentMutationPropKey,
    createComponentMutationFn
  )(Component as ComponentType<WithCreateComponentMutationProps>);
}
