import { type KeysToCamelCase } from 'js/types';

import {
  type ProductTypeBaseRaw,
  type ProductTypeBase,
  type ProductType,
} from '../productTypes';
import {
  type CategoryWithSubCategories,
  type Category,
  type CategoryRaw,
  type ProductLCategory,
} from '../categories';
import { type TagBaseRaw, type TagBase, type Tag } from '../tags';
import { type CollectionBaseRaw, type CollectionBase } from '../collections';
import { type StyleBaseRaw, type StyleBase, type Style } from '../styles';
import {
  type ProformaInvoiceBase,
  type proformaInvoiceRaw,
  type proformaInvoiceProducts,
} from '../proformaInvoices';
import { type FinishWithSubFinishes } from '../finishes';
import { type Vendor, type VendorBase } from '../vendors';
import { type ImageBaseRaw, type ImageRaw } from '../images';
import {
  type Project,
  RoomProductItemStatus,
  type TimestamptsRaw,
  type ItemType,
} from '..';
import { type Material, type MaterialRaw } from '../productMaterials';
import { type Bin } from '../bins';
import { type IRoomProductItem } from '../rpi/models/model';
import { type FilterCount, type FilterCounts } from '@services/item_service';
import { type Item, type ItemDetails } from '@interfaces/item.interface';

export enum ProductData {
  Incomplete = 'incomplete',
  V1 = 'v1.0',
}

export enum PhotographyStatus {
  NotTaken = 'not_taken',
  Taken = 'taken',
  Uploaded = 'uploaded',
}

export interface Product
  extends KeysToCamelCase<Omit<ProductRaw, 'materials' | 'category'>> {
  materials: ProductMaterial[] | null;
  category: Category;
}

export interface ProductMaterialRaw extends Omit<MaterialRaw, 'sub_materials'> {
  parent_material?: ProductMaterialRaw;
}

export interface ProductMaterial
  extends KeysToCamelCase<Omit<ProductMaterialRaw, 'parent_material'>> {
  parentMaterial?: ProductMaterial;
}

// @TODO Compare ProductRaw with SingleProductRaw might its a same type
export interface ProductRaw extends TimestamptsRaw {
  id: number;
  sku: string;
  description: string | null;

  materials: ProductMaterialRaw[] | null;

  height: number | null;
  width: number | null;
  length: number | null;
  depth: number | null;
  weight: number | null;

  name: string;
  image: ImageRaw;
  photography_status: PhotographyStatus;

  category_id: number;
  category: CategoryRaw;
  product_types: ProductTypeBase[];

  photography_completed: boolean;
  metadata_completed: boolean;

  seat_height: number | null;
  seat_depth: number | null;
  arm_height: number | null;
  base_height: number | null;

  product_data: ProductData;
  catalog_ready: boolean;
  cant_be_picked: boolean;
  customer_name: string | null;

  vendor_id: number;
  vendor: Vendor;
  vendor_price: number | null;
  retail_price: number;
  manual_retail_price: boolean;
  latest_cost: number | null;
  landed_cost: number | null;

  shoppable: boolean;
  candidate: boolean;

  com: boolean;
  com_details: string;
  custom_sizes: boolean | null;
  custom_sizes_details: string | null;
  customer_description: string | null;
  upholstered: boolean | null;
  fabric_required: number | null;
  // @TODO l*_category_id there is not exist in entity - only on update
  l1_category_id: number | null;
  l2_category_id: number | null;
  l3_category_id: number | null;

  shopify_product_id: number | null;
  shopify_new_variant_id: number | null;
  shopify_stock_variant_id: number | null;

  vendor_sku: string | null;
  updated_latest_cost: number | null;
  available: ProductAvailable;
  // available_by_region: Record<Region, ProductAvailable>;
  audit_tag: string | null;
  'vendorEcommEligible?': boolean;

  l1Category: ProductLCategory;
  l2Category: ProductLCategory;
  l3Category: ProductLCategory;
}

// GET /inventory/v1/products/new_product
interface GetNewProductPayloadXhrRaw {
  categories: CategoryWithSubCategories[];
  vendors: VendorBase[];
  // @TODO Might this not use or it is a copy of Metarial
  finishes: FinishWithSubFinishes[];
  proforma_invoices: ProformaInvoiceBase[];
  photography_statuses: typeof PhotographyStatus;
  product_data: typeof ProductData;
  styles: StyleBase[];
  collections: CollectionBase[];
  tags: TagBase[];
  product_types: ProductTypeBase[];
}

export type GetNewProductPayloadXhr =
  KeysToCamelCase<GetNewProductPayloadXhrRaw>;

export interface GetProductsXhrParams
  extends Record<OptionalProductFilterParentKey, number[]> {
  page?: number;
  limit: number;
  term?: string;
  inventoryFilter: ProductAvailability | null;
  // searchType?: SearchProductsTypes;
  withFacets?: boolean;
  sortBy?: ProductsSortBy;
  inventory?: ProductAvailability;
  width?: (string | number)[];
  depth?: (string | number)[];
  height?: (string | number)[];
  minAvailable?: number;
  outdoor?: boolean;
  indoor?: boolean;
}

interface ProductAvailable {
  quantity: number;
  total: number;
}
// @TODO Remove after rework product entity
// export interface SingleProduct
//   extends KeysToCamelCase<Omit<SingleProductRaw, 'materials' | 'category'>> {
//   materials: ProductMaterial[] | null;
//   category: Category;
// }

// export type SingleProduct = ItemDetails & { supplierName: string };

interface SingleProductStyle {
  id: number;
  displayValue: string;
  // "description": "industrial loft"
  // value ?
  // images
}

export interface SingleProductMaterial {
  id: number;
  displayValue: string;
  // "description": "industrial loft"
  // value ?
  images: { url: string }[];
}

export interface SingleProduct
  extends Pick<
    ItemDetails,
    | 'baseSku'
    | 'id'
    | 'name'
    | 'primaryImageListUrl'
    | 'weight'
    | 'width'
    | 'height'
    | 'depth'
    | 'itemTypeId'
    | 'itemType'
    | 'description'
    | 'retailPrice'
    | 'createdAt'
    | 'updatedAt'
    | 'images'
    | 'supplierSku'
    | 'whatToKnow'
  > {
  primaryImageUrl: string;
  primaryImageThumbnailUrl: string;
  sku: string | null;
  quantity: number;
  baseCost: number | null;
  quantityInRoom: number | null;
  quantityPicked: number | null;
  quantityRemovable: number | null;
  latestColiCreatedAt: string | null;
  tips: string;
  supplierName: string;
  styles: SingleProductStyle[];
  materials: SingleProductMaterial[];
  finish?: SingleProductMaterial;
  sellable?: boolean;
}

// @TODO Remove when reworking Item model
export interface SingleProductRaw
  extends Omit<ProductRaw, 'image'>,
    TimestamptsRaw {
  status_ready_to_pick: boolean;
  status_on_site: boolean;
  status_damaged: boolean;
  status_investigate: boolean;
  status_reserved: boolean;
  unreserved: boolean;
  vendor_ecomm_eligible: boolean;
  shoppable_live: boolean;

  expected_quantity: number;
  remaining_quantity: number;
  pickable_quantity: number;
  // pickable_quantity_by_region: Record<Region, number>;

  image: ImageRaw;
  images: ImageBaseRaw[];
  project_images: string[];
  filestack_images: ImageRaw[];

  bins: Bin[];
  // bins_by_region: Record<Region, Bin[]>;
  tags: TagBaseRaw[];
  collections: CollectionBaseRaw[];
  product_types: ProductTypeBaseRaw[];

  l1_old_category: ProductLCategory | null;
  l2_old_category: ProductLCategory | null;
  l3_old_category: ProductLCategory | null;

  l1_category: ProductLCategory | null;
  l2_category: ProductLCategory | null;
  l3_category: ProductLCategory | null;
  l4_category: ProductLCategory | null;

  proforma_invoices: proformaInvoiceRaw[];
  proforma_invoice_products: proformaInvoiceProducts[];

  // @TODO
  reservations: {
    id: number;
    name: string;
  }[];
  items: {
    id: number;
    desc: string;
    product_id: Product['id'];
  }[];
  // vendor: {
  //   id: 28;
  //   name: 'Changsha Joysaint Dishware';
  //   ecomm_eligible: false;
  //   com_markup: null;
  //   custom_sizes_markup: null;
  // };
  category: CategoryRaw;
  // product_sets: [];
}

// Temprorary type
export type InventoryProduct = Item & {
  itemTypeIds?: number[];
} & Pick<ItemDetails, 'width' | 'height' | 'depth' | 'baseSku'>;

export enum ProductAvailability {
  AllInventory = 'all_inventory',
  Reservable = 'reservable',
  NotReservable = 'not_reservable',
  Picked = 'picked',
  OnSite = 'on_site',
  Reserved = 'reserved',
  EligibleToSell = 'eligible_to_sell',
  LiveOnVestaHome = 'live_on_vesta_home',
}

export type ProductAvailabilityCounters = {
  allInventory: { qty: number };
  reservable: { qty: number };
  notReservable: { qty: number };
  picked: { qty: number };
  onSite: { qty: number };
  reserved: { qty: number };
  eligibleToSell: { qty: number };
  liveOnVestaHome: { qty: number };
};

export type ProductForRoomInfo = Pick<ProductRaw, 'id' | 'name' | 'image'>;

export interface ProductItemInfoRaw {
  id: IRoomProductItem['id'];
  // @TODO Find and Update type to Item['id']
  item_id: number | null;
  status: RoomProductItemStatus;
  project_id: Project['id'];
  project_name: string;
  location: string | null;
}
export type ProductItemInfo = KeysToCamelCase<ProductItemInfoRaw>;

export interface VestaHomeProduct
  extends KeysToCamelCase<Omit<VestaHomeProductRaw, 'materials'>> {
  materials: ProductMaterial[] | null;
}

export interface VestaHomeProductRaw
  extends Pick<
    ProductRaw,
    | 'id'
    | 'sku'
    | 'name'
    | 'customer_name'
    | 'customer_description'
    | 'audit_tag'
    | 'depth'
    | 'height'
    | 'length'
    | 'width'
    | 'materials'
    | 'retail_price'
    | 'vendor_price'
    | 'manual_retail_price'
    | 'shopify_new_variant_id'
    | 'shopify_product_id'
    | 'shopify_stock_variant_id'
  > {
  customer_name_id: string;
  images: string[];
  styles: StyleBaseRaw[];
  // @TODO check type and use from product after snakecase fields
  l1_category: Category;
  l2_category: Category;
  l3_category: Category;
  l4_category: Category;
}

export enum SearchProductsTypes {
  Supplemental = 'supplemental',
  Inventory = 'inventory',
}

export enum ProductsSortBy {
  createdAt = 'created_at',
  updatedAt = 'updated_at',
}

export type OptionalProductFilters = KeysToCamelCase<OptionalProductFiltersRaw>;
export type OptionalProductFilterParentKey = keyof OptionalProductFilters;
export interface OptionalProductFiltersRaw {
  room_types: FilterCount[];
  item_types: FilterCount[];
  color_families: FilterCount[];
  bed_sizes: FilterCount[];
  rug_sizes: FilterCount[];
  // styles: FilterCount[];
  materials: FilterCount[];
  brands: FilterCount[];
}

export type OutdoodIndoorFilterName = 'outdoor' | 'indoor';

export type OptionalOutdoorProductFilters = Record<
  OutdoodIndoorFilterName,
  FilterCount
>;
export type OptionalOutdoorProductSelectedFilters = {
  outdoor: number[];
};

export interface EamesSearchFacets
  extends OptionalProductFilters,
    OptionalOutdoorProductFilters {
  availability: ProductAvailabilityCounters;
}

export interface ProductOptionalFilters {
  itemTypes: ItemType[];
  colorFamilies: Tag[];
  roomTypes: Tag[];
  materials: Tag[];
  // styles: Tag[];
  bedSizes: Tag[];
  rugSizes: Tag[];
  brands: Tag[];
}

export type ProductTagFilterKey = Exclude<
  OptionalProductFilterParentKey,
  'itemTypes' | 'availability'
>;

export type SelectedOptionalProductFilters = Record<
  OptionalProductFilterParentKey,
  FilterCount['id'][]
>;
