import { type Market, type Markets } from '.';

import { type Option } from 'js/components/partial/Forms/Select';

export type MarketOption = Option<Market['id']>;

export const mapMarketsToOptions = (markets: Markets): MarketOption[] => {
  return markets.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};
