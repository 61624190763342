import { useSessionData } from '@custom_hooks/use_session_data';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';

export const TwoFactorGuard: FunctionComponent<{}> = () => {
  const { data: sessionData } = useSessionData();
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady || !sessionData || !sessionData.currentUser) {
      // wait for data to load
      return;
    }

    if (router.pathname.includes('/n/dashboard/two_factor')) {
      // do nothing b/c already on two factor page
      return;
    }

    if (!sessionData.currentUser.twoFactorSetupNeeded) {
      // two factor setup not needed
      return;
    }

    router.push('/n/dashboard/two_factor');
  }, [router.isReady, router.pathname, sessionData]);

  return <></>;
};
