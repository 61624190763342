import React, { useMemo } from 'react';
import { useQuery, type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { type ProjectItemsTracking } from '..';
import { mockResponse } from 'js/api/mock/utils';
import { projectItemsTrackingMock } from 'js/api/mock/projectItemsTrackingMock';

/**
 * GET - /inventory/v1/projects/:id/items/all
 */

// HTTP

export interface ProjectItemsTrackingXhr {
  data: ProjectItemsTracking;
}

export const getProjectItemsTrackingXhr = (
  projectId: number,
  config: HttpBaseConfig = {}
): Promise<ProjectItemsTracking> => mockResponse(projectItemsTrackingMock);
// http
//   .get<ProjectItemsTrackingXhr>(
//     `/inventory/v1/projects/${projectId}/items/all`,
//     config
//   )
//   .then((res) => res.data.data);

// Query Key

const GET_PROJECT_ITEMS_TRACKING_QUERY_KEY_NAMESPACE =
  'getProjectItemsTrackingById';

type GetProjectItemsTrackingQueryKeyNamespace =
  typeof GET_PROJECT_ITEMS_TRACKING_QUERY_KEY_NAMESPACE;

export type GetProjectItemsTrackingQueryKey = [
  GetProjectItemsTrackingQueryKeyNamespace,
  number /* project id */
];

export const createGetProjectItemsTrackingQueryKey = (
  key: SkipFirst<GetProjectItemsTrackingQueryKey>
): GetProjectItemsTrackingQueryKey => [
  GET_PROJECT_ITEMS_TRACKING_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getProjectItemsTrackingQueryFn: QueryFunction<
  ProjectItemsTracking,
  GetProjectItemsTrackingQueryKey
> = ({ signal, queryKey: [_d, projectId] }) =>
  getProjectItemsTrackingXhr(projectId, {
    signal,
  });

// Query

export interface UseGetProjectItemsTrackingQueryProps {
  projectId: number;
  options?: GetProjectItemsTrackingQueryProps['options'];
}

export const useGetProjectItemsTrackingQuery = ({
  projectId,
  options,
}: UseGetProjectItemsTrackingQueryProps) => {
  const queryKey = useMemo(
    () => createGetProjectItemsTrackingQueryKey([projectId]),
    [projectId]
  );

  return useQuery(queryKey, getProjectItemsTrackingQueryFn, options);
};

export interface GetProjectItemsTrackingQueryProps
  extends Omit<
    QueryProps<
      ProjectItemsTracking,
      unknown,
      ProjectItemsTracking,
      GetProjectItemsTrackingQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
}

export type GetProjectItemsTrackingQueryResult = Parameters<
  GetProjectItemsTrackingQueryProps['children']
>[0];

export const GetProjectItemsTrackingQuery = ({
  projectId,
  ...props
}: GetProjectItemsTrackingQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProjectItemsTrackingQueryKey([projectId])}
      queryFn={getProjectItemsTrackingQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProjectItemsTrackingQueryData = async (
  client: QueryClient,
  projectId: number
) => {
  await client.invalidateQueries(
    createGetProjectItemsTrackingQueryKey([projectId])
  );
};
