export const stylesMock = [
  {
    id: 2,
    name: 'Architectural',
    description: null,
    created_at: '2021-02-22T14:49:32.916Z',
    updated_at: '2021-02-22T14:49:32.916Z',
    status: 'active',
  },
  {
    id: 3,
    name: 'Contemporary',
    description: null,
    created_at: '2021-02-22T14:49:32.921Z',
    updated_at: '2021-02-22T14:49:32.921Z',
    status: 'active',
  },
  {
    id: 4,
    name: 'Traditional',
    description: null,
    created_at: '2021-02-22T14:49:32.926Z',
    updated_at: '2021-02-22T14:49:32.926Z',
    status: 'active',
  },
  {
    id: 5,
    name: 'Transitional',
    description: null,
    created_at: '2021-02-22T14:49:32.931Z',
    updated_at: '2021-02-22T14:49:32.931Z',
    status: 'active',
  },
  {
    id: 1,
    name: 'Modern',
    description: null,
    created_at: '2021-02-22T14:49:32.910Z',
    updated_at: '2022-07-19T15:31:56.265Z',
    status: 'active',
  },
];
