import * as Sentry from '@sentry/nextjs';
import { SessionData } from './session_service';
import { NEXTJS_FRONTEND_FINGERPRINT } from '../sentry.client.config';

const identifyUser = (sessionData: SessionData) => {
  if (!sessionData.currentUser) {
    return;
  }
  const sentryUser: Sentry.User = {};
  sentryUser.email = sessionData.currentUser.email;
  if (sessionData.trackingMode) {
    sentryUser.id = sessionData.trackingMode.id.toString();
  } else if (sessionData.currentUser) {
    sentryUser.id = sessionData.currentUser.id.toString();
  }
  sentryUser.impersonating = !!sessionData.impersonating;
  Sentry.setUser(sentryUser);
};

/**
 * Catch an error object and report to Sentry.
 *
 * This has to pass an error object b/c stack traces are made whenever new error objects are instantiated.
 * If we create the error object in this function, the stack trace will show this file instead of where the error was actually made.
 */
function captureError(error: Error, extraContext: any) {
  Sentry.captureException(error, {
    extra: { extraContext },
    fingerprint: [NEXTJS_FRONTEND_FINGERPRINT, error.message],
  });
}

function catchPromise(extraContext: string): (error: any) => void {
  return (error: any) => {
    captureError(error, { extraContext, error });
  };
}

export const SentryService = {
  identifyUser,
  captureError: captureError,
  catchPromise,
};
