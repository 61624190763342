import { decamelize } from 'humps';
/**
 * API Sort
 */

export type SortDirection = 'asc' | 'desc';

export interface SortParams<K extends string = string> {
  sortBy: K;
  sortDir?: SortDirection | null;
}

export interface SortQueryParams {
  sort: string;
}

interface MapSortObjectToQueryParamOptions {
  withDecamelize?: boolean;
}

/**
 * Map sort object to sort query params supported by our api.
 *
 * @return - query param.
 * Example:
 *  mapSortObjectToQueryParam({ sortBy: "created", sortDir: "desc" })
 *  // -> { sortBy: 'created', sortOrder: 'desc' }
 */
export function mapSortObjectToQueryParam<K extends string = string>(
  sortParams: SortParams<K>,
  options?: MapSortObjectToQueryParamOptions
): Record<'sortBy' | 'sortOrder', string> {
  const { withDecamelize } = options ?? {};
  const { sortBy, sortDir } = sortParams;
  const key = withDecamelize ? decamelize(sortBy) : sortBy;

  return { sortBy: key, sortOrder: sortDir ?? 'desc' };
}

export const serializeParamsArrays = (params?: Object) => {
  if (!params) return params;

  const entries = Object.entries(params);
  const serialised = entries.map(([key, value]) => {
    if (Array.isArray(value)) {
      return [key, value.join(',')];
    }
    return [key, value];
  });
  return Object.fromEntries(serialised);
};

export type SortHandler<K extends string = string> = (
  sortBy: K,
  nextSortDir: SortDirection | null
) => void;

export interface SortProps<K extends string = string>
  extends Partial<SortParams<K>> {
  onSort?: SortHandler<K>;
}
