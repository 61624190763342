import { type ComponentType } from 'react';
import {
  type MutationFunction,
  useMutation,
  useQueryClient,
  QueryClient,
} from 'react-query';

import {
  OrderTripLineItem,
  OrderTripLineItemsByStatus,
  createGetOrderTripLineItemsByStatusQueryKey,
  http,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';
import { OrderTripStatus } from '@interfaces/order_trip.interface';

/**
 * PUT - /api/v1/eames/projects/:id/order_trip_line_items/:id
 */

// HTTP

export interface UpdateOrderTripLineItemXhrParams {
  orderTripId?: number;
  tripStatus?: OrderTripStatus | 'canceled';
}

export interface UpdateOrderTripLineItemXhrVariables {
  id: number;
  projectId: number;
  params: UpdateOrderTripLineItemXhrParams;
}

export const updateOrderTripLineItemXhr = (
  id: number,
  projectId: number,
  params: UpdateOrderTripLineItemXhrParams
): Promise<OrderTripLineItem> =>
  http
    .put<OrderTripLineItem>(
      `/api/v1/eames/projects/${projectId}/order_trip_line_items/${id}`,
      params
    )
    .then((res) => res.data);

export const updateCacheForUpdateOrderTripLineItemMutation = (
  client: QueryClient,
  updatedOtli: Awaited<ReturnType<typeof updateOrderTripLineItemXhr>>,
  options: UpdateOrderTripLineItemXhrVariables
) => {
  client.setQueriesData<OrderTripLineItemsByStatus | undefined>(
    createGetOrderTripLineItemsByStatusQueryKey([options.projectId]),
    (data) => {
      if (!data) return data;

      const destinationOrderTripId = options.params.orderTripId;
      if (!destinationOrderTripId) return data;

      const destinationStatusName = Object.keys(data).find(
        (status) => data[status].orderTripId === destinationOrderTripId
      );
      const sourceStatusName = Object.keys(data).find((status) =>
        data[status].otlis.find((otli) => otli.id === options.id)
      );

      if (
        !destinationStatusName ||
        !sourceStatusName ||
        !data[destinationStatusName] ||
        !data[sourceStatusName]
      )
        return data;

      const source = { ...data[sourceStatusName] };
      source.otlis = source.otlis.filter((otli) => otli.id !== updatedOtli.id);

      const destination = { ...data[destinationStatusName] };
      destination.otlis.push(updatedOtli);

      return {
        ...data,
        [sourceStatusName]: source,
        [destinationStatusName]: destination,
      };
    }
  );
};

// MutationFn

type UpdateOrderTripLineItemMutation = MutationFunction<
  OrderTripLineItem,
  UpdateOrderTripLineItemXhrVariables
>;

export const updateOrderTripLineItemMutationFn: UpdateOrderTripLineItemMutation =
  ({ id, projectId, params }) =>
    updateOrderTripLineItemXhr(id, projectId, params);

// Hook
export const useUpdateOrderTripLineItemMutation = () => {
  const client = useQueryClient();

  return useMutation(updateOrderTripLineItemMutationFn, {
    onSuccess: async (updatedOtli, options) => {
      updateCacheForUpdateOrderTripLineItemMutation(
        client,
        updatedOtli,
        options
      );
    },
  });
};

// With Mutation HOC

const withUpdateOrderTripLineItemMutationPropKey =
  'updateOrderTripLineItemMutation';

export type WithUpdateOrderTripLineItemMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateOrderTripLineItemMutationPropKey,
    UpdateOrderTripLineItemMutation
  >;

export function withUpdateOrderTripLineItemMutation<
  P extends WithUpdateOrderTripLineItemMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateOrderTripLineItemMutationProps>> {
  return withMutation(
    withUpdateOrderTripLineItemMutationPropKey,
    updateOrderTripLineItemMutationFn
  )(Component as ComponentType<WithUpdateOrderTripLineItemMutationProps>);
}
