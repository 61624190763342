import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/room_product_items/:id/delivery_dateselected
 */

// HTTP

interface UpdateProjectItemDeliveryDateXhrParams {
  deliveryDateselected?: string;
}

interface UpdateProjectItemDeliveryDateXhrVariables {
  itemId: number;
  params: UpdateProjectItemDeliveryDateXhrParams;
}

export const updateProjectItemDeliveryDateXhr = ({
  itemId,
  params,
}: UpdateProjectItemDeliveryDateXhrVariables): Promise<unknown> =>
  mockResponse({});
// http.put<unknown>(
//   `/inventory/v1/room_product_items/${itemId}/delivery_dateselected`,
//   null,
//   {
//     params,
//   }
// );

// MutationFn

type UpdateProjectItemDeliveryDateMutation = MutationFunction<
  unknown,
  UpdateProjectItemDeliveryDateXhrVariables
>;

export const updateProjectItemDeliveryDateMutationFn: UpdateProjectItemDeliveryDateMutation =
  (variables) => updateProjectItemDeliveryDateXhr(variables);

// With Mutation HOC

const withUpdateProjectItemDeliveryDateMutationPropKey =
  'updateProjectItemDeliveryDateMutation';

export type WithUpdateProjectItemDeliveryDateMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateProjectItemDeliveryDateMutationPropKey,
    UpdateProjectItemDeliveryDateMutation
  >;

export function withUpdateProjectItemDeliveryDateMutation<
  P extends WithUpdateProjectItemDeliveryDateMutationProps
>(
  Component: ComponentType<P>
): ComponentType<
  Omit<P, keyof WithUpdateProjectItemDeliveryDateMutationProps>
> {
  return withMutation(
    withUpdateProjectItemDeliveryDateMutationPropKey,
    updateProjectItemDeliveryDateMutationFn
  )(Component as ComponentType<WithUpdateProjectItemDeliveryDateMutationProps>);
}
