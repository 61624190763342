import React from 'react';
import { type QueryFunction } from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Project } from '../';

/**
 * GET - /api/v1/eames/projects/options
 */

export type ProjectsOption = Pick<Project, 'id' | 'name'>;
export type ProjectsOptionWithStatus = Pick<Project, 'id' | 'name' | 'status'>;

export type GetProjectsOptionsXhr = ProjectsOptionsXhr;

export type ProjectsOptionsXhr = ProjectsOptionWithStatus[];

export interface GetProjectsOptionsXhrParams {
  status: '-closed';
}

export interface GetProjectsOptionsXhrConfig extends HttpBaseConfig {
  params: GetProjectsOptionsXhrParams;
}

const mapQueryKeyToXhrParams = (): GetProjectsOptionsXhrParams => {
  return {
    status: '-closed',
  };
};

// HTTP

export const getProjectsOptionsXhr = (
  config: GetProjectsOptionsXhrConfig
): Promise<ProjectsOptionsXhr> =>
  http
    .get<GetProjectsOptionsXhr>(`/api/v1/eames/projects/options`, config)
    .then((res) => res.data);

// Query Key

const GET_PROJECTS_OPTIONS_QUERY_KEY_NAMESPACE = 'projects_options';

type GetProjectsOptionsQueryKeyNamespace =
  typeof GET_PROJECTS_OPTIONS_QUERY_KEY_NAMESPACE;

export type GetProjectsOptionsQueryKey = [
  GetProjectsOptionsQueryKeyNamespace,
  boolean? /* withStatus */
];

export const createGetProjectsOptionsQuery = (
  withStatus?: boolean
): GetProjectsOptionsQueryKey => [
  GET_PROJECTS_OPTIONS_QUERY_KEY_NAMESPACE,
  withStatus,
];

// QueryFn

export const getProjectsOptionsQueryFn: QueryFunction<
  ProjectsOptionsXhr,
  GetProjectsOptionsQueryKey
> = ({ signal }) =>
  getProjectsOptionsXhr({
    signal,
    params: mapQueryKeyToXhrParams(),
  });

// Query

export interface GetProjectsOptionsQueryProps
  extends Omit<
    QueryProps<
      ProjectsOptionsXhr,
      Error,
      ProjectsOptionsXhr,
      GetProjectsOptionsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {}

export type GetProjectsOptionsQueryResult = Parameters<
  GetProjectsOptionsQueryProps['children']
>[0];

export const GetProjectsOptionsQuery = ({
  ...rest
}: GetProjectsOptionsQueryProps) => {
  return (
    <Query
      {...rest}
      queryKey={createGetProjectsOptionsQuery()}
      queryFn={getProjectsOptionsQueryFn}
    />
  );
};
