import React, { useMemo } from 'react';
import { type QueryFunction } from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { SkipFirst } from 'js/types';

import { type Category } from '../';
import { categoriesMock } from 'js/api/mock/categoriesMock';
import { mockResponse } from 'js/api/mock/utils';

/**
 * GET - /inventory/v1/categories
 */

// HTTP

export interface GetInventoryCategoriesXhr {
  data: Category[];
}

export interface GetInventoryCategoriesXhrParams {
  reservable?: boolean;
  subcategory?: boolean;
  category_id?: number[];
}

export interface GetInventoryCategoriesXhrConfig extends HttpBaseConfig {
  params?: GetInventoryCategoriesXhrParams;
}

export const getInventoryCategoriesXhr = (
  config: GetInventoryCategoriesXhrConfig = { params: { reservable: true } } // DEFAULT do not include "accessories"
): Promise<Category[]> => mockResponse<Category[]>(categoriesMock);
// http
//   .get<GetInventoryCategoriesXhr>(`/inventory/v1/categories`, config)
//   .then((res) => res.data.data);

// Query Key

const GET_INVENTORY_CATEGORY_QUERY_KEY_NAMESPACE = 'inventoryCategories';

type GetInventoryCategoriesQueryKeyNamespace =
  typeof GET_INVENTORY_CATEGORY_QUERY_KEY_NAMESPACE;

export type GetInventoryCategoriesQueryKey = [
  GetInventoryCategoriesQueryKeyNamespace,
  GetInventoryCategoriesXhrParams? /* params */
];

export const createGetInventoryCategoriesQueryKey = (
  key: SkipFirst<GetInventoryCategoriesQueryKey>
): GetInventoryCategoriesQueryKey => [
  GET_INVENTORY_CATEGORY_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getInventoryCategoriesQueryFn: QueryFunction<
  Category[],
  GetInventoryCategoriesQueryKey
> = ({ queryKey: [_d, params], signal }) =>
  getInventoryCategoriesXhr({ params, signal });

// Query

export interface GetInventoryCategoriesQueryProps
  extends Omit<
    QueryProps<Category[], unknown, Category[], GetInventoryCategoriesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetInventoryCategoriesXhrParams;
}

export type GetInventoryCategoriesQueryRenderFn =
  GetInventoryCategoriesQueryProps['children'];

export type GetInventoryCategoriesQueryResult =
  Parameters<GetInventoryCategoriesQueryRenderFn>[0];

export const GetInventoryCategoriesQuery = ({
  params,
  ...props
}: GetInventoryCategoriesQueryProps) => {
  const queryKey = useMemo(
    () => createGetInventoryCategoriesQueryKey([params]),
    [params]
  );
  return (
    <Query
      {...props}
      queryKey={queryKey}
      queryFn={getInventoryCategoriesQueryFn}
    />
  );
};
