import { useSessionData } from '@custom_hooks/use_session_data';
import { SegmentService } from '@services/segment_service';
import { ServerSideSegmentService } from '@services/server_side_segment_service';
import { pathNameToPageName } from '@utility_functions/getPageName';
import { getProductSlugFromPath } from '@utility_functions/getProductSlugFromPath';
import { getUtmQueryParams } from '@utility_functions/getUtmQueryParams';
import { useRouter } from 'next/router';
import { FunctionComponent, useEffect } from 'react';

export const PageTracker: FunctionComponent<{}> = () => {
  const { data: sessionData } = useSessionData();
  const router = useRouter();

  useEffect(() => {
    // router is not ready on initial page render
    // when router is not ready, router.query is an empty {} to it doesn't have the query params in it
    if (!router.isReady || !sessionData) {
      return;
    }

    SegmentService.trackPage();

    // This event is used for funnel and bounce rate analysis
    ServerSideSegmentService.trackEvent('Website Visited', {
      ...getUtmQueryParams(router.query),
      pathAndQuery: router.asPath,
      productSlug: router.asPath ? getProductSlugFromPath(router.asPath) : null,
      pageName: pathNameToPageName(router.pathname),
      pathname: location.pathname,
      referer: document.referrer,
      pageTitle: document.title,
    });
  }, [router.isReady, router.pathname, sessionData]);

  return <></>;
};
