import React, { useMemo } from 'react';
import { type QueryFunction, type QueryClient, useQuery } from 'react-query';
import { decamelizeKeys } from 'humps';

import { type KeysToCamelCase } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { FILTER_STATUS } from './';
import { mockResponse } from 'js/api/mock/utils';
import { projectsFacetsMock } from 'js/api/mock/projectsFacetsMock';

/**
 * GET - /api/v1/eames/projects/by_status_facets
 */

// HTTP

export type ProjectsFacets = Record<FILTER_STATUS, number>;

export type GetProjectsFacetsXhrRaw = ProjectsFacets;

export type GetProjectsFacetsXhr = KeysToCamelCase<GetProjectsFacetsXhrRaw>;

export const getProjectsFacetsXhr = (
  config: HttpBaseConfig
): Promise<ProjectsFacets> =>
  http
    .get<ProjectsFacets>(`/api/v1/eames/projects/by_status_facets`, config)
    .then(
      (res) => decamelizeKeys(res.data, { separator: '-' }) as ProjectsFacets
    );

// Query Key

const GET_PROJECTS_FACETS_QUERY_KEY_NAMESPACE = 'projects_facets';

type GetProjectsFacetsQueryKeyNamespace =
  typeof GET_PROJECTS_FACETS_QUERY_KEY_NAMESPACE;

export type GetProjectsFacetsQueryKey = [GetProjectsFacetsQueryKeyNamespace];

export const createGetProjectsFacetsQuery = (): GetProjectsFacetsQueryKey => [
  GET_PROJECTS_FACETS_QUERY_KEY_NAMESPACE,
];

// QueryFn

export const getProjectsFacetsQueryFn: QueryFunction<
  GetProjectsFacetsXhr,
  GetProjectsFacetsQueryKey
> = ({ signal }) => getProjectsFacetsXhr({ signal });

// useQuery

export const useGetProjectsFacetsQuery = (
  options?: GetProjectsFacetsQueryProps['options']
) => {
  const queryKey = useMemo(createGetProjectsFacetsQuery, []);
  return useQuery(queryKey, getProjectsFacetsQueryFn, options);
};

// Query

export type GetProjectsFacetsQueryProps = Omit<
  QueryProps<
    GetProjectsFacetsXhr,
    Error,
    GetProjectsFacetsXhr,
    GetProjectsFacetsQueryKey
  >,
  'queryFn' | 'queryKey'
>;

export type GetProjectsFacetsQueryRenderFn =
  GetProjectsFacetsQueryProps['children'];

export const GetProjectsFacetsQuery = (props: GetProjectsFacetsQueryProps) => {
  const queryKey = useMemo(createGetProjectsFacetsQuery, []);

  return (
    <Query {...props} queryKey={queryKey} queryFn={getProjectsFacetsQueryFn} />
  );
};

// Query Cache Helpers

export const invalidateAllProjectsQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(createGetProjectsFacetsQuery());
};
