import { Project } from '..';

import { type Option } from 'js/components/partial/Forms/Select';
import { PROJECT_STATUS_PICKING } from 'js/config';
import { type ProjectsOption as ProjectOptionRaw } from './';

export type ProjectOption = Option<Project['id']>;

export const mapProjectToOption = (
  project: ProjectOptionRaw
): ProjectOption => {
  return {
    value: project.id,
    label: project.name,
  };
};

export const mapProjectsToOptions = (
  data?: ProjectOptionRaw[]
): ProjectOption[] => {
  if (!data) return [];
  return data.map(mapProjectToOption);
};

export const isProjectStatusPicking = (project?: Project) => {
  return !!project && project.status === PROJECT_STATUS_PICKING;
};
