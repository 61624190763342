import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProductMaterialsQueryData, Material } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/materials
 */

// HTTP

export interface CreateMaterialXhrBody
  extends Pick<Material, 'name' | 'type' | 'instructions'> {
  image?: string;
}

export interface CreateMaterialXhrResponse {
  data: Material;
}

export const createMaterialXhr = (
  body: CreateMaterialXhrBody
): Promise<Material> => mockResponse({});
// http
//   .post<CreateMaterialXhrResponse>(`/inventory/v1/materials`, body)
//   .then((res) => res.data.data);

// MutationFn

type CreateMaterialMutation = MutationFunction<Material, CreateMaterialXhrBody>;

export const createMaterialMutationFn: CreateMaterialMutation = (body) =>
  createMaterialXhr(body);

// With Mutation HOC

const withCreateMaterialMutationPropKey = 'createMaterialMutation';

export type WithCreateMaterialMutationProps = WithMutationPropsByMutation<
  typeof withCreateMaterialMutationPropKey,
  CreateMaterialMutation
>;

export function withCreateMaterialMutation<
  P extends WithCreateMaterialMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateMaterialMutationProps>> {
  return withMutation(
    withCreateMaterialMutationPropKey,
    createMaterialMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductMaterialsQueryData(client);
      },
    })
  )(Component as ComponentType<WithCreateMaterialMutationProps>);
}
