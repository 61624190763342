import React, { useMemo } from 'react';
import {
  useQuery,
  type QueryClient,
  type UseQueryOptions,
  type QueryFunction,
} from 'react-query';

import { type KeysToCamelCase, type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type Project,
  type Room,
} from 'js/api';

/**
 * GET - /api/v1/eames/projects/:project_id/rooms
 */

// HTTP

export type GetRoomsXhr = Room[];

// @TODO Add param to get only rooms names for use in room select
export interface GetRoomsXhrParams {
  showItems?: boolean;
  forPackingList?: boolean;
}

export interface GetRoomsXhrConfig extends HttpBaseConfig {
  params?: GetRoomsXhrParams | null;
}

export const getRoomsXhr = (
  projectId: Project['id'],
  config: GetRoomsXhrConfig
): Promise<GetRoomsXhr> =>
  http
    .get<GetRoomsXhr>(`/api/v1/eames/projects/${projectId}/rooms`, config)
    .then((res) => res.data);

// Query Key

const GET_ROOMS_QUERY_KEY_NAMESPACE = 'rooms';

type GetRoomsQueryKeyNamespace = typeof GET_ROOMS_QUERY_KEY_NAMESPACE;

export type GetRoomsQueryKey = [
  GetRoomsQueryKeyNamespace,
  Project['id'],
  GetRoomsXhrParams?
];

export const createGetRoomsQueryKey = (
  key: SkipFirst<GetRoomsQueryKey>
): GetRoomsQueryKey => [GET_ROOMS_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getRoomsQueryFn: QueryFunction<GetRoomsXhr, GetRoomsQueryKey> = ({
  queryKey: [_d, projectId, params],
  signal,
}) => getRoomsXhr(projectId, { params, signal });

// create Query Options

export type GetRoomsQueryOptions = UseQueryOptions<
  GetRoomsXhr,
  unknown,
  GetRoomsXhr,
  GetRoomsQueryKey
>;

export interface GetRoomsQueryOptionsProps {
  projectId: Project['id'];
  params?: GetRoomsXhrParams;
  options?: Omit<GetRoomsQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetRoomsQueryOptions = ({
  projectId,
  options,
  params,
}: GetRoomsQueryOptionsProps): GetRoomsQueryOptions => ({
  queryFn: getRoomsQueryFn,
  queryKey: createGetRoomsQueryKey([projectId, params]),
  ...options,
});

// Hook

export interface UseGetRoomsQueryProps {
  projectId: Project['id'];
  options?: GetRoomsQueryProps['options'];
  params?: GetRoomsXhrParams;
}

export const useGetRoomsQuery = ({
  projectId,
  params,
  options,
}: UseGetRoomsQueryProps) => {
  const queryKey = useMemo(
    () => createGetRoomsQueryKey([projectId, params]),
    [projectId]
  );

  return useQuery(queryKey, getRoomsQueryFn, options);
};

// Query

export interface GetRoomsQueryProps
  extends Omit<
    QueryProps<GetRoomsXhr, Error, GetRoomsXhr, GetRoomsQueryKey>,
    'queryFn' | 'queryKey'
  > {
  projectId: Project['id'];
  params?: GetRoomsXhrParams;
}

export type GetRoomsQueryResult = Parameters<GetRoomsQueryProps['children']>[0];

export const GetRoomsQuery = ({
  projectId,
  params,
  ...props
}: GetRoomsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetRoomsQueryKey([projectId, params])}
      queryFn={getRoomsQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetRoomsQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetRoomsQueryKey>
) => {
  await client.invalidateQueries(createGetRoomsQueryKey(keyParams));
};
