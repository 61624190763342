// global style imports
// order is important!
// Please don't change!
import '@reach/dialog/styles.css'; // 3rd party dependency - put this first
import '../styles/globals.css'; // this must go 2nd
import '../styles/colored-icons/colored-icons.scss';
import '../styles/fern-button.scss';

// ----------------------------------------
// non-css imports below
// ----------------------------------------
import { PageTracker } from '@components/_app/page_tracker';
import { SegmentService } from '@services/segment_service';
import { ToasterProvider } from '@shared_components/toaster/toaster';
import { getPageName } from '@utility_functions/getPageName';
import type { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import dynamic from 'next/dynamic';
import { QueryClient, QueryClientProvider } from 'react-query';
import { TwoFactorGuard } from '@components/_app/two_factor_guard';
import BrandLayout from '@shared_components/brandLayout';
import { isEamesRoute } from '@utility_functions/isEamesRoute';
import EamesLayout from '../eames/EamesLayout';

// import { ReactQueryDevtools } from 'react-query/devtools';

export const queryClient = new QueryClient();

const LazyLoadedScripts = dynamic<{}>(
  () =>
    import('@components/_app/lazy_loaded_scripts').then(
      (module) => module.LazyLoadedScripts
    ),
  { ssr: false }
);

function MyApp({ Component, pageProps, router }: AppProps) {
  if (isEamesRoute(router.route)) {
    // @TODO remove this ignores if we find a better way for imports
    // @ts-ignore
    import('../eames/sass/screen.sass');
    // @ts-ignore
    import('normalize.css');
    return (
      <EamesLayout>
        <Component {...pageProps} />
      </EamesLayout>
    );
  }

  const app = (
    <QueryClientProvider client={queryClient}>
      <ToasterProvider>
        <Component {...pageProps} />
        <LazyLoadedScripts />
        <PageTracker />
        <TwoFactorGuard />
      </ToasterProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
  if (!pageProps?.brand) return app;

  return <BrandLayout brand={pageProps.brand}>{app}</BrandLayout>;
}

export default MyApp;

export function reportWebVitals(metric: NextWebVitalsMetric) {
  // https://nextjs.org/docs/advanced-features/measuring-performance
  if (metric.label === 'web-vital') {
    SegmentService.trackAdminEvent(`Web Vital`, {
      ...metric,
      pageName: getPageName(),
    });
  }
}
