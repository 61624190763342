import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';

/**
 * DELETE - /api/v1/eames/items/:id
 */

// HTTP

interface DeleteComponentXhrVariables {
  itemId: number;
}

export const deleteComponentXhr = ({
  itemId,
}: DeleteComponentXhrVariables): Promise<unknown> =>
  http.delete<unknown>(`/api/v1/eames/items/${itemId}`);

// MutationFn

type DeleteComponentMutation = MutationFunction<
  unknown,
  DeleteComponentXhrVariables
>;

export const deleteComponentMutationFn: DeleteComponentMutation = (variables) =>
  deleteComponentXhr(variables);

// With Mutation HOC

const withDeleteComponentMutationPropKey = 'deleteComponentMutation';

export type WithDeleteComponentMutationProps = WithMutationPropsByMutation<
  typeof withDeleteComponentMutationPropKey,
  DeleteComponentMutation
>;

export function withDeleteComponentMutation<
  P extends WithDeleteComponentMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteComponentMutationProps>> {
  return withMutation(
    withDeleteComponentMutationPropKey,
    deleteComponentMutationFn
  )(Component as ComponentType<WithDeleteComponentMutationProps>);
}
