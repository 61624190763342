import React, { useMemo } from 'react';
import { useQuery, type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Project, PROJECT_FIELDS_FOR_SINGLE_PROJECT } from '..';

/**
 * GET - /api/v1/eames/projects/:id
 */

// HTTP

export const getProjectXhr = (
  projectId: number,
  config: HttpBaseConfig = {}
): Promise<Project> =>
  http
    .get<Project>(`/api/v1/eames/projects/${projectId}`, config)
    .then((res) => res.data);

// Query Key

const GET_PROJECT_QUERY_KEY_NAMESPACE = 'getProjectById';

type GetProjectQueryKeyNamespace = typeof GET_PROJECT_QUERY_KEY_NAMESPACE;

export type GetProjectQueryKey = [GetProjectQueryKeyNamespace, Project['id']];

export const createGetProjectQueryKey = (
  key: SkipFirst<GetProjectQueryKey>
): GetProjectQueryKey => [GET_PROJECT_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getProjectQueryFn: QueryFunction<Project, GetProjectQueryKey> = ({
  signal,
  queryKey: [_d, projectId],
}) =>
  getProjectXhr(projectId, {
    signal,
  });

// Query

export interface UseGetProjectQueryProps {
  projectId: number;
  options?: GetProjectQueryProps['options'];
}

export const useGetProjectQuery = ({
  projectId,
  options,
}: UseGetProjectQueryProps) => {
  const queryKey = useMemo(
    () => createGetProjectQueryKey([projectId]),
    [projectId]
  );

  return useQuery(queryKey, getProjectQueryFn, options);
};

export interface GetProjectQueryProps
  extends Omit<
    QueryProps<Project, unknown, Project, GetProjectQueryKey>,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
}

export type GetProjectQueryResult = Parameters<
  GetProjectQueryProps['children']
>[0];

export const GetProjectQuery = ({
  projectId,
  ...props
}: GetProjectQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProjectQueryKey([projectId])}
      queryFn={getProjectQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProjectQueryData = async (
  client: QueryClient,
  projectId: number
) => {
  await client.invalidateQueries(createGetProjectQueryKey([projectId]));
};

export const updateProjectQueryData = (
  client: QueryClient,
  projectId: number,
  data: Project
) => {
  client.setQueryData(createGetProjectQueryKey([projectId]), data);
};
