import React from 'react';
import {
  type QueryFunction,
  type QueryClient,
  type InfiniteData,
} from 'react-query';

import { type SkipFirst, type KeysToCamelCase } from 'js/types';
import {
  http,
  Query,
  InfiniteQuery,
  type QueryProps,
  type HttpBaseConfig,
  type InfiniteQueryProps,
} from 'js/api/';

import { type PriceListRaw } from '../';
import { PRICELISTS_PER_PAGE } from 'js/components/projects/v2/ProjectFurnitureSales/ViewPriceLists/constants';

/**
 * GET -  /api/v1/eames/projects/:projectId/price_lists
 */

// HTTP

export type GetPricesXhrRaw = PriceListRaw[];

export type GetPricesXhr = KeysToCamelCase<GetPricesXhrRaw>;

export interface GetPricesXhrConfig extends HttpBaseConfig {
  params?: GetPricesXhrParams;
}

export interface GetPricesXhrParams {
  page?: number;
  perPage?: number;
  pagy?: boolean;
}

export const getPricesXhr = (
  projectId: number,
  config: GetPricesXhrConfig = {}
): Promise<GetPricesXhr> =>
  http
    .get<GetPricesXhr>(
      `/api/v1/eames/projects/${projectId}/price_lists`,
      config
    )
    .then((res) => res.data);

// Query Key

const GET_PRICES_QUERY_KEY_NAMESPACE = 'prices';

type GetPricesQueryKeyNamespace = typeof GET_PRICES_QUERY_KEY_NAMESPACE;

export type GetPricesQueryKey = [
  GetPricesQueryKeyNamespace,
  number /* project id */,
  GetPricesXhrParams? /* params */
];

export const mapGetPricesQuery = (
  key: SkipFirst<GetPricesQueryKey>
): GetPricesQueryKey => [GET_PRICES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getPricesQueryFn: QueryFunction<
  GetPricesXhr,
  GetPricesQueryKey
> = ({ queryKey: [_d, projectId, params], signal, pageParam }) =>
  getPricesXhr(projectId, { params, signal, ...pageParam });

// Query

export interface GetPricesQueryProps
  extends Omit<
    QueryProps<GetPricesXhr, Error, GetPricesXhr, GetPricesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
  params?: GetPricesXhrParams;
}

export const GetPricesQuery = ({
  projectId,
  params,
  ...props
}: GetPricesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetPricesQuery([projectId, params])}
      queryFn={getPricesQueryFn}
    />
  );
};

// Infinite Query

export interface GetPricesInfiniteQueryProps
  extends Omit<
    InfiniteQueryProps<GetPricesXhr, Error, GetPricesXhr, GetPricesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
  params?: GetPricesXhrParams;
}

export type GetPricesInfiniteQueryResult = Parameters<
  GetPricesInfiniteQueryProps['children']
>[0];

export const GetPricesInfiniteQuery = ({
  projectId,
  params,
  options,
  ...props
}: GetPricesInfiniteQueryProps) => {
  return (
    <InfiniteQuery
      {...props}
      queryKey={mapGetPricesQuery([projectId, params])}
      queryFn={getPricesQueryFn}
      options={{
        getPreviousPageParam: (_p, pages): GetPricesXhrConfig | undefined => {
          const perPage = params?.perPage;
          if (!perPage) return undefined; // Cannot check next page if no params were set up

          const currentPage = pages.length;
          const hasPrevious = currentPage > 1;

          if (!hasPrevious) return undefined;

          const pageParam: GetPricesXhrParams = {
            page: currentPage - 1,
            perPage,
          };

          return { params: pageParam };
        },
        getNextPageParam: (_p, pages): GetPricesXhrConfig | undefined => {
          const perPage = params?.perPage;
          if (!perPage) return undefined; // Cannot check next page if no params were set up

          const hasMore =
            pages.length > 1 &&
            pages[pages.length - 1].length < PRICELISTS_PER_PAGE;

          if (!hasMore) return undefined;

          const pageParam: GetPricesXhrParams = {
            page: pages.length,
            perPage,
          };

          return { params: pageParam };
        },
        ...options,
      }}
    />
  );
};

// Query Cache Helpers

export type PricesQueryProcessorFn<D = unknown> = (
  args: [
    key: GetPricesQueryKey,
    data: InfiniteData<GetPricesXhr> | GetPricesXhr
  ]
) => D;

export function processPricesQueryData<D = unknown>(
  client: QueryClient,
  keyParams: SkipFirst<GetPricesQueryKey>,
  processor: PricesQueryProcessorFn<D>
) {
  const key = mapGetPricesQuery(keyParams);
  const pricesCache = client.getQueriesData<
    InfiniteData<GetPricesXhr> | GetPricesXhr
  >(key);

  return pricesCache.map(([key, data]) =>
    processor([key as GetPricesQueryKey, data])
  );
}

export const invalidatePricesQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetPricesQueryKey>
) => {
  await client.invalidateQueries(mapGetPricesQuery(keyParams));
};
