// export const IDP_PATH_REGEX = /\/n\/items\/[a-zA-Z-].*/g;

export function getProductSlugFromPath(path: string): null | string {
  if (path.indexOf('/n/items/') === -1) {
    return null;
  }

  let cleanedPath: string;

  if (path.indexOf('?') === -1) {
    cleanedPath = path;
  } else {
    cleanedPath = path.split('?')[0];
  }

  const pathParts = cleanedPath.split('/');

  return pathParts[pathParts.length - 1];
}
