import { darken, rgba } from 'polished';

export type Colors = typeof colors;

export type Theme = typeof defaultTheme;

export const colors = {
  soldWhite: '#d4cbe5',
  azure: '#009cff',
  azureHover: darken(0.1, '#009cff'),
  azureLight: '#ecf8fd',
  azureLightHover: darken(0.05, '#ecf8fd'),
  battleshipGrey: '#636c7e',
  beige: '#fbf1f1',
  black30: rgba(0, 0, 0, 0.3),
  brownishGrey: '#686868',
  brownishGreyHover: darken(0.15, '#686868'),
  danger: '#dc3545',
  dangerDark: '#ac2925',
  dark: '#293042',
  dark40: rgba(28, 31, 37, 0.4),
  duckEggBlue: '#ecf8fd',
  duckEggBlueDarken: darken(0.01, '#ecf8fd'),
  darkGreyBlue: '#364052',
  grayLight: '#bbb',
  grayMid: '#999',
  grayMidHover: darken(0.1, '#999'),
  grayXXDark: '#364052',
  grayXLight: '#e0e0e0',
  grayXXLight: '#f5f5f5',
  green: '#5fceae',
  greenBlue: '#00bf97',
  greenLime: '#00cf34',
  lightBlueGrey: '#d5d7e7',
  lightishPurple: '#a95edb',
  lipstickTwo: '#d41623',
  orange: '#ffa500',
  red: '#de3939',
  white: '#fff',
  whiteTwo: '#ececec',
  yellowLight: '#ffd43b',
  yellowXLight: '#ffe792',
  yellowXXLight: '#ffeeb3',
} as const;

const defaultTheme = {
  flexboxgrid: {
    gridSize: 12,
    gutterWidth: 1.25, // rem
    outerMargin: 1.25, // rem
    mediaQuery: 'only screen',
    container: {
      sm: 45, // rem
      md: 58.75, // rem
      lg: 60, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 62, // em
      lg: 75, // em
    },
  },
  colors,
} as const;

export default defaultTheme;
