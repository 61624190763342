import { type KeysToCamelCase } from 'js/types';
import { type RoomProductItemRaw } from 'js/api/projectItems';
import { type TagBase, type User, type UserRaw } from 'js/api';
import { type TimestamptsRaw } from '../helpers';
import { type StyleBaseRaw } from '../styles';
import { PROJECT_TYPES } from 'js/config';
import { BaseCustomerOrderDetails } from '@interfaces/customer_order.interface';

export type AssignedUserRaw = Pick<UserRaw, 'id' | 'full_name'>;
export type AssignedUser = KeysToCamelCase<AssignedUserRaw>;
export type AssignedUserType = keyof Pick<
  Project,
  'designers' | 'salespersons'
>;

export enum ProjectFilter {
  All = 'all',
  Reserved = 'reserved',
  Picked = 'picked',
  OnSite = 'on_site',
  Returned = 'returned',
  Sold = 'sold',
}

export enum ProjectStatus {
  Reserving = 'reserving',
  DeliveryRequested = 'delivery_requested',
  Picking = 'picking',
  OnSite = 'on_site',
  ReturnRequested = 'return_requested',
  Receiving = 'receiving',
  Closed = 'closed',
  WalkThroughScheduled = 'walk_through_scheduled',
  WalkThroughCompleted = 'walk_through_completed',
  Lost = 'lost',
}

export type ProjectType = typeof PROJECT_TYPES[number];

export type Project = KeysToCamelCase<ProjectRaw>;

export type ProjectAdress = KeysToCamelCase<ProjectAdressRaw>;
export type ProjectAdressRaw = {
  id: number | null;
  line_1: string | null;
  line_2: string | null;
  city: string | null;
  state: string | null; // @TODO replace with enum US_STATES
  zip: string | null;
  country: string | null;
  verification_data?: any;
  address_type: string;
};

export interface ProjectRaw {
  id: number;
  old_eames_id: number | null;
  name: string;
  install_date: string;
  end_date: string;
  contract_value: string;
  contract_number: string;
  user_id: number;
  status: string;
  dock_id: number;
  designer_id: number;
  location_id: number;
  items_count: number;
  items_picked: number;
  items_received: number;
  items_total: number;
  designer_status: string;
  designer_updated_at: string;
  designer_completed_at: string;
  total_retail_price: number;
  total_vendor_price: number;
  reservation_start_date: string;
  last_movement_at: string;
  shopify_updated_at: null;
  location_lat: null;
  location_long: null;
  shopify_url: null;
  region: string;
  discount: null;
  moveout_date: string;
  trip_moveout_date: string;
  items_sold: number;
  ua_items: number;
  sqft: string | null;
  staging_term: null;
  initial_staging_fee: null;
  contract_url: null;
  project_type: ProjectType | null;
  salesforce_link: null;
  sqft_staged: null;
  monthly_rental_notes: null;
  description: null;
  influencer_id: null;
  nda: null;
  link_to_nda: null;
  full_name: string;
  given_name: string;
  family_name: string;
  customer_email: string;
  image_rights: boolean;
  project_level: string | null;
  customer_name_id: string;
  lost_at: string;
  reservation: {
    enabled: boolean;
    message: string | null;
  };
  streetview_image: {
    thumbnail_url: string;
  };
  designers: AssignedUserRaw[];
  salespersons: AssignedUserRaw[];

  status_text?: string;
  eames_job_number?: string;
  slack_channel_name?: string;

  address: ProjectAdress | null;
  missing_retail_price: number;
  missing_vendor_price: number;
  monthly_rental_start_date: string | null;
  percent_of_items_sold: number;
  price_persqft: number | null;
  rental_start_date: string | null;
  room_product_items_total: number;
  sold_furniture_margin: number;
  sold_furniture_sold_margin: number;
  sold_furniture_sold_value: number;
  sold_items_total: number;
  sold_retail_price: number | null;
  sold_vendor_price: number | null;
  tags: TagBase[];

  reserved_items_count: number;
  reserved_trips_count: number;
  customer_order: ProjectCustomerOrder;
  market_name: string;
}

export type ProjectWithRPIs = KeysToCamelCase<ProjectWithRPIsRaw>;

interface ProjectWithRPIsRaw extends ProjectRaw {
  room_product_items: RoomProductItemRaw[];
  room_product_items_count: number;
}

export type AllProjectsToMoveProductsOption = Pick<
  Project,
  'id' | 'name' | 'status'
>;
export type AllProjectsOption = Pick<Project, 'id' | 'name'>;

export interface CreateProjectXhrBody
  extends Omit<
    KeysToCamelCase<CreateProjectXhrBodyRaw>,
    'designersIds' | 'salespersonsIds' | 'address'
  > {
  address: Omit<ProjectAdressRaw, 'id'>;
  designersIds?: User['id'][];
  salespersonsIds?: User['id'][];
}

export interface CreateProjectXhrBodyRaw {
  name?: string | null;
  address: ProjectAdressRaw | null;

  install_date: string | null;
  end_date?: string | null;
  contract_value?: string | null;
  given_name?: string | null;
  family_name?: string | null;
  customer_email?: string | null;
  date_posted?: string | null;
  image_rights?: boolean | null;
  initial_staging_fee?: string | null;
  link_to_nda?: string | null;
  make_public?: boolean | null;
  project_level?: string | null; // @TODO replace with enum PROJECT_LEVELS
  project_type?: string | null; // @TODO replace with enum PROJECT_TYPES;
  sqft?: string | null;
  staging_term?: string | null;

  salesforce_link?: string | null;
  slack_channel_name?: string | null;

  designers_ids?: User['id'][];
  salespersons_ids?: User['id'][];
}

export type UpdateProjectXhrBody = Partial<CreateProjectXhrBody> & {
  moveItems?: {
    id: number;
    qty: number;
    roomId: number;
    toRoomId?: number;
    projectId: number;
  }[];
};

export interface ProjectCustomerOrder
  extends Pick<
    BaseCustomerOrderDetails,
    'orderState' | 'totalBillingCycles' | 'customerOrderLineItems'
  > {
  id: number;
  deliveryDate: string;
  userId: number;
}
