import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProjectQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/admin/projects/:id/designer_completed_at
 */

// HTTP

interface UpdateProjectDesignerCompletedAtDateXhrBody {
  designerCompletedAt: string;
}

interface UpdateProjectDesignerCompletedAtDateXhrVariables {
  projectId: number;
  body: UpdateProjectDesignerCompletedAtDateXhrBody;
}

export const updateProjectDesignerCompletedAtDateXhr = ({
  projectId,
  body,
}: UpdateProjectDesignerCompletedAtDateXhrVariables): Promise<unknown> =>
  mockResponse({});
// http.post<unknown>(
//   `/inventory/v1/admin/projects/${projectId}/designer_completed_at`,
//   body
// );

// MutationFn

type UpdateProjectDesignerCompletedAtDateMutation = MutationFunction<
  unknown,
  UpdateProjectDesignerCompletedAtDateXhrVariables
>;

export const updateProjectDesignerCompletedAtDateMutationFn: UpdateProjectDesignerCompletedAtDateMutation =
  (variables) => updateProjectDesignerCompletedAtDateXhr(variables);

// With Mutation HOC

const withUpdateProjectDesignerCompletedAtDateMutationPropKey =
  'updateProjectDesignerCompletedAtDateMutation';

export type WithUpdateProjectDesignerCompletedAtDateMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateProjectDesignerCompletedAtDateMutationPropKey,
    UpdateProjectDesignerCompletedAtDateMutation
  >;

export function withUpdateProjectDesignerCompletedAtDateMutation<
  P extends WithUpdateProjectDesignerCompletedAtDateMutationProps
>(
  Component: ComponentType<P>
): ComponentType<
  Omit<P, keyof WithUpdateProjectDesignerCompletedAtDateMutationProps>
> {
  return withMutation(
    withUpdateProjectDesignerCompletedAtDateMutationPropKey,
    updateProjectDesignerCompletedAtDateMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateProjectQueryData(client, projectId);
      },
    })
  )(
    Component as ComponentType<WithUpdateProjectDesignerCompletedAtDateMutationProps>
  );
}
