import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const Close: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.96967 13.9298C2.67678 14.2227 2.67678 14.6976 2.96967 14.9905C3.26256 15.2834 3.73744 15.2834 4.03033 14.9905L8.98008 10.0407L13.9697 15.0303C14.2626 15.3232 14.7375 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2626 15.0303 13.9697L10.0407 8.98007L14.9905 4.03033C15.2834 3.73744 15.2834 3.26256 14.9905 2.96967C14.6976 2.67678 14.2227 2.67678 13.9298 2.96967L8.98008 7.91941L4.07019 3.00952C3.7773 2.71663 3.30243 2.71662 3.00953 3.00952C2.71664 3.30241 2.71664 3.77729 3.00953 4.07018L7.91942 8.98007L2.96967 13.9298Z"
      />
    </svg>
  );
};
