import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  createGetRoomProductQueryKey,
  createGetRoomQueryKey,
  createGetRoomsQueryKey,
  invalidateGetRoomProductQueryData,
  invalidateGetRoomsQueryData,
  type GetRoomProductXhr,
  type GetRoomsXhr,
  type GetRoomXhr,
  type Project,
  type Room,
  type RoomProduct,
  type WithMutationPropsByMutation,
} from 'js/api';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PATCH - /inventory/v1/room_products/:roomProductId
 */

// HTTP

export interface UpdateRoomProductXhrVariables {
  projectId: Project['id'];
  roomId: Room['id'];
  roomProductId: RoomProduct['id'];
  body: UpdateRoomProductXhrBody;
}

export interface UpdateRoomProductXhrBody {
  quantity: number;
}

export interface UpdateRoomProductXhrResponse {
  data: RoomProduct;
}

export const updateRoomProductXhr = ({
  roomProductId,
  body,
}: UpdateRoomProductXhrVariables): Promise<RoomProduct> => mockResponse({});
// http
//   .patch<UpdateRoomProductXhrResponse>(
//     `/inventory/v1/room_products/${roomProductId}`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateRoomProductMutation = MutationFunction<
  RoomProduct,
  UpdateRoomProductXhrVariables
>;

export const updateRoomProductMutationFn: UpdateRoomProductMutation = (
  variables
) => updateRoomProductXhr(variables);

// Update cache

export const updateRoomProductCacheForUpdateRoomProductMutation = (
  client: QueryClient,
  roomProduct: Awaited<ReturnType<typeof updateRoomProductXhr>>,
  roomProductId: RoomProduct['id']
) => {
  client.setQueriesData<GetRoomProductXhr | undefined>(
    createGetRoomProductQueryKey([roomProductId]),
    (data) => {
      if (!data) return data;

      const updatedData = { ...data };
      updatedData.data = { ...roomProduct };
      return updatedData;
    }
  );
};

export const updateRoomCacheForUpdateRoomProductMutation = (
  client: QueryClient,
  updatedRoomProduct: Awaited<ReturnType<typeof updateRoomProductXhr>>,
  roomProductId: RoomProduct['id'],
  roomId: Room['id'],
  projectId: Project['id']
) => {
  // @TODO rework this logic
  // client.setQueriesData<GetRoomXhr | undefined>(
  //   createGetRoomQueryKey([roomId, projectId, true]),
  //   (data) => {
  //     if (!data) return data;
  //     const roomProducts = data.roomProducts;
  //     const updatedQty = updatedRoomProduct.quantity;
  //     const updatedData = { ...data };
  //     // remove or replace roomProduct
  //     updatedData.roomProducts = updatedQty
  //       ? roomProducts.map((roomProduct) =>
  //           roomProduct.id === roomProductId ? updatedRoomProduct : roomProduct
  //         )
  //       : roomProducts.filter(
  //           (roomProduct) => roomProduct.id !== roomProductId
  //         );
  //     return updatedData;
  //   }
  // );
};

export const updateRoomsCacheForUpdateRoomProductMutation = (
  client: QueryClient,
  updatedRoomProduct: Awaited<ReturnType<typeof updateRoomProductXhr>>,
  roomProductId: RoomProduct['id'],
  roomId: Room['id'],
  projectId: Project['id']
) => {
  // @TODO Discuss is need to update single room?
  // client.setQueriesData<GetRoomsXhr | undefined>(
  //   createGetRoomsQueryKey([projectId, undefined]),
  //   (data) => {
  //     const roomIndex = data?.findIndex((room) => room.id === roomId);
  //     if (!data || roomIndex === -1 || roomIndex == null) return data;
  //     const roomProducts = data[roomIndex].roomProducts;
  //     const updatedQty = updatedRoomProduct.quantity;
  //     const updatedData = [...data];
  //     // remove or replace roomProduct
  //     updatedData[roomIndex].roomProducts = updatedQty
  //       ? roomProducts.map((roomProduct) =>
  //           roomProduct.id === roomProductId ? updatedRoomProduct : roomProduct
  //         )
  //       : roomProducts.filter(
  //           (roomProduct) => roomProduct.id !== roomProductId
  //         );
  //     return updatedData;
  //   }
  // );
};

// With Mutation HOC

const withUpdateRoomProductMutationPropKey = 'updateRoomProductMutation';

export type WithUpdateRoomProductMutationProps = WithMutationPropsByMutation<
  typeof withUpdateRoomProductMutationPropKey,
  UpdateRoomProductMutation
>;

export function withUpdateRoomProductMutation<
  P extends WithUpdateRoomProductMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateRoomProductMutationProps>> {
  return withMutation(
    withUpdateRoomProductMutationPropKey,
    updateRoomProductMutationFn,
    (client) => ({
      onSuccess: async (roomProduct, { projectId, roomId, roomProductId }) => {
        if (roomProduct.quantity)
          updateRoomProductCacheForUpdateRoomProductMutation(
            client,
            roomProduct,
            roomProductId
          );
        else await invalidateGetRoomProductQueryData(client, [roomProductId]);

        updateRoomCacheForUpdateRoomProductMutation(
          client,
          roomProduct,
          roomProductId,
          roomId,
          projectId
        );
        updateRoomsCacheForUpdateRoomProductMutation(
          client,
          roomProduct,
          roomProductId,
          roomId,
          projectId
        );
        // @TODO Invalidate because unkonwn filter server logic
        await invalidateGetRoomsQueryData(client, [
          projectId,
          // { onlyEcommEligible: true },
        ]);
      },
    })
  )(Component as ComponentType<WithUpdateRoomProductMutationProps>);
}
