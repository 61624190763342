import React, { createContext, useState, useCallback, useContext } from 'react';

export type Payload = Record<string, unknown>;

type Popup = Payload | boolean;

export interface PopupContextValues {
  popups: Record<string, Popup>;
  showPopup: (key: string, payload?: Payload) => void;
  hidePopup: (key: string) => void;
}

const PopupContext = createContext<PopupContextValues>({
  popups: {},
  showPopup: () => undefined,
  hidePopup: () => undefined,
});

export interface PopupProviderProps {
  children: React.ReactNode;
}

export const PopupProvider = ({ children }: PopupProviderProps) => {
  const [popups, setPopups] = useState<Record<string, Popup>>({});

  const showPopup = useCallback((key: string, payload?: Payload) => {
    setPopups((prevPopups) => ({ ...prevPopups, [key]: payload ?? true }));
  }, []);

  const hidePopup = useCallback((key: string) => {
    setPopups((prevPopups) => ({ ...prevPopups, [key]: false }));
  }, []);

  return (
    <PopupContext.Provider value={{ popups, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  );
};

export const usePopups = () => useContext(PopupContext);

export const PopupConsumer = PopupContext.Consumer;
