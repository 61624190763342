import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  type ProductType,
  type ProductTypeStatus,
  invalidateProductTypesQueryData,
} from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/product_types/:id
 */

// HTTP

export interface UpdateProductTypeXhrVariables {
  productTypeId: number;
  body: UpdateProductTypeXhrBody;
}

export interface UpdateProductTypeXhrBody {
  name?: string;
  status?: ProductTypeStatus;
}

export interface UpdateProductTypeXhrResponse {
  data: ProductType;
}

export const updateProductTypeXhr = ({
  productTypeId,
  body,
}: UpdateProductTypeXhrVariables): Promise<ProductType> => mockResponse({});
// http
//   .put<UpdateProductTypeXhrResponse>(
//     `/inventory/v1/product_types/${productTypeId}`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateProductTypeMutation = MutationFunction<
  ProductType,
  UpdateProductTypeXhrVariables
>;

export const updateProductTypeMutationFn: UpdateProductTypeMutation = (
  variables
) => updateProductTypeXhr(variables);

// With Mutation HOC

const withUpdateProductTypeMutationPropKey = 'updateProductTypeMutation';

export type WithUpdateProductTypeMutationProps = WithMutationPropsByMutation<
  typeof withUpdateProductTypeMutationPropKey,
  UpdateProductTypeMutation
>;

export function withUpdateProductTypeMutation<
  P extends WithUpdateProductTypeMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProductTypeMutationProps>> {
  return withMutation(
    withUpdateProductTypeMutationPropKey,
    updateProductTypeMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductTypesQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateProductTypeMutationProps>);
}
