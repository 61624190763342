import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateStylesQueryData } from '../';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/styles
 */

// HTTP

export interface CreateStyleXhrBody {
  name: string;
}

export const createStyleXhr = (body: CreateStyleXhrBody): Promise<unknown> =>
  mockResponse({});
// http.post<unknown>(`/inventory/v1/styles`, body);

// MutationFn

type CreateStyleMutation = MutationFunction<unknown, CreateStyleXhrBody>;

export const createStyleMutationFn: CreateStyleMutation = (body) =>
  createStyleXhr(body);

// With Mutation HOC

const withCreateStyleMutationPropKey = 'createStyleMutation';

export type WithCreateStyleMutationProps = WithMutationPropsByMutation<
  typeof withCreateStyleMutationPropKey,
  CreateStyleMutation
>;

export function withCreateStyleMutation<P extends WithCreateStyleMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateStyleMutationProps>> {
  return withMutation(
    withCreateStyleMutationPropKey,
    createStyleMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateStylesQueryData(client);
      },
    })
  )(Component as ComponentType<WithCreateStyleMutationProps>);
}
