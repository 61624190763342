import { type KeysToCamelCase } from 'js/types';

import {
  type TimestamptsRaw,
  type ProductRaw,
  type RoomRaw,
  type Room,
  type StyleRaw,
  type UserRaw,
} from 'js/api';

export type Image = KeysToCamelCase<ImageRaw>;

export type ProjectImageType = 'team' | 'pro';
export enum ImageTypes {
  Hero = 'hero',
  Detail = 'detail',
  InProject = 'in_project',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
}
export type ImageType = `${ImageTypes}`;

export interface ImageRaw {
  id: number;
  source_url: string;
  md5: string;
  s3_bucket: string;
  key: string;
  normal_url: string;
  thumbnail_url: string;
  image_type?: ImageType | null;
  url?: string | null;
}

export type ImageBase = KeysToCamelCase<ImageBaseRaw>;

export type ImageBaseRaw = Pick<ImageRaw, 'image_type' | 'url'>;

export type ProductForProjectImageRaw = Pick<
  ProductRaw,
  'id' | 'name' | 'image'
>;

export type ProductForProjectImage = KeysToCamelCase<ProductForProjectImageRaw>;

export interface ProjectImage
  extends KeysToCamelCase<Omit<ProjectImageRaw, 'assigned_rooms'>> {
  assignedRooms: Room[];
}

export interface ProjectImageRaw extends TimestamptsRaw {
  id: number;
  url: string;
  image_type: ProjectImageType;
  public_photo: boolean;
  project_id: number;
  project_name: string;
  project_location_city: string;
  posted: boolean;
  current_user_rating: ImageRatingRaw;
  average_rating: number;
  image_ratings_count: number;
  ig_handle: string;
  project_styles: StyleRaw[];
  assigned_products: ProductForProjectImageRaw[];
  assigned_rooms: RoomRaw[];
}

export interface SingleProjectImageRaw
  extends Pick<
    ProjectImageRaw,
    | 'assigned_rooms'
    | 'average_rating'
    | 'current_user_rating'
    | 'id'
    | 'image_ratings_count'
    | 'image_ratings_count'
    | 'image_type'
    | 'posted'
    | 'url'
    | 'created_at'
  > {
  assignet_products: ProductForProjectImageRaw[];
}

export interface SingleProjectImage
  extends KeysToCamelCase<Omit<SingleProjectImageRaw, 'assigned_rooms'>> {
  assignedRooms: Room[];
}
// Image Rating

export type ImageRating = KeysToCamelCase<ImageRatingRaw>;

export interface ImageRatingRaw extends TimestamptsRaw {
  id: number;
  points: number;
  user_id: number;
  filestack_image_id: number;
}

export type CreateUserImageRating = KeysToCamelCase<CreateUserImageRatingRaw>;

export interface CreateUserImageRatingRaw {
  average_rating: number;
  image_rating: ImageRatingRaw;
  image_ratings_count: number;
}

export interface CreateUserImageRatingXhr {
  data: CreateUserImageRating;
}

export interface MaterialImageRaw
  extends Pick<ImageRaw, 'key' | 'normal_url' | 'thumbnail_url'> {
  small_url: string;
}

export type MaterialImage = KeysToCamelCase<MaterialImageRaw>;
