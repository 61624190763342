import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  createGetRoomsQueryKey,
  type Project,
  type Room,
  type RoomTypeForUpdate,
  type WithMutationPropsByMutation,
  type GetRoomsQueryKey,
  type GetRoomsXhr,
} from 'js/api';
import { type SkipFirst } from 'js/types';

/**
 * POST - /api/v1/eames/projects/:project_id/rooms
 */

// HTTP

interface CreateRoomXhrVariables {
  projectId: Project['id'];
  body: CreateRoomXhrBody;
}

export interface CreateRoomXhrBody {
  name: string;
  roomType: RoomTypeForUpdate | '';
  order: number;
}

export type CreateRoomXhrResponse = Room;

export const createRoomXhr = ({
  projectId,
  body,
}: CreateRoomXhrVariables): Promise<Room> =>
  http
    .post<CreateRoomXhrResponse>(
      `/api/v1/eames/projects/${projectId}/rooms`,
      body
    )
    .then((res) => res.data);

// MutationFn

type CreateRoomMutation = MutationFunction<Room, CreateRoomXhrVariables>;

export const createRoomMutationFn: CreateRoomMutation = (variables) =>
  createRoomXhr(variables);

// Update cache

export const updateCacheForCreateRoomMutation = (
  client: QueryClient,
  updatedRoom: Awaited<ReturnType<typeof createRoomXhr>>,
  keyParams: SkipFirst<GetRoomsQueryKey>
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      if (!data) return data;
      const updatedData = [...data];
      updatedData.push({ ...updatedRoom });
      return updatedData;
    }
  );
};

// With Mutation HOC

const withCreateRoomMutationPropKey = 'createRoomMutation';

export type WithCreateRoomMutationProps = WithMutationPropsByMutation<
  typeof withCreateRoomMutationPropKey,
  CreateRoomMutation
>;

export function withCreateRoomMutation<P extends WithCreateRoomMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateRoomMutationProps>> {
  return withMutation(
    withCreateRoomMutationPropKey,
    createRoomMutationFn,
    (client) => ({
      onSuccess: (newRoom, { projectId }) => {
        updateCacheForCreateRoomMutation(client, newRoom, [projectId]);
      },
    })
  )(Component as ComponentType<WithCreateRoomMutationProps>);
}
