export const PRICE_LISTS_COLUMNS = [
  'ID',
  'Name',
  'Created At',
  'Created By',
  'Updated At',
  'Updated By',
  'Actions',
];

export const PRICELISTS_PER_PAGE = 10;
