import React, { useMemo } from 'react';
import { type UseQueryOptions, type QueryFunction } from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Markets } from '..';

/**
 * GET - /api/v1/eames/market
 */

// HTTP

export const getMarketsXhr = (config: HttpBaseConfig = {}): Promise<Markets> =>
  http.get<Markets>(`/api/v1/eames/market`, config).then((res) => res.data);

// Query Key

const GET_REGIONS_QUERY_KEY_NAMESPACE = 'markets';

type GetMarketsQueryKeyNamespace = typeof GET_REGIONS_QUERY_KEY_NAMESPACE;

export type GetMarketsQueryKey = [GetMarketsQueryKeyNamespace];

export const createGetMarketsQueryKey = (): GetMarketsQueryKey => [
  GET_REGIONS_QUERY_KEY_NAMESPACE,
];

// QueryFn

export const getMarketsQueryFn: QueryFunction<Markets, GetMarketsQueryKey> = ({
  signal,
}) => getMarketsXhr({ signal });

// Query Options

export type GetMarketsQueryOptions = UseQueryOptions<
  Markets,
  unknown,
  Markets,
  GetMarketsQueryKey
>;

// Query

export type GetMarketsQueryProps = Omit<
  QueryProps<Markets, unknown, Markets, GetMarketsQueryKey>,
  'queryFn' | 'queryKey'
>;

export type GetMarketsQueryRenderFn = GetMarketsQueryProps['children'];

export type GetMarketsQueryResult = Parameters<GetMarketsQueryRenderFn>[0];

export const GetMarketsQuery = (props: GetMarketsQueryProps) => {
  const queryKey = useMemo(createGetMarketsQueryKey, []);
  return <Query {...props} queryKey={queryKey} queryFn={getMarketsQueryFn} />;
};
