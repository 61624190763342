import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProjectQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/projects/:id/remove_move_out_date
 */

// HTTP

interface RemoveProjectMoveOutDateXhrVariables {
  projectId: number;
}

export const removeProjectMoveOutDateXhr = ({
  projectId,
}: RemoveProjectMoveOutDateXhrVariables): Promise<unknown> => mockResponse({});
// http.put<unknown>(`/inventory/v1/projects/${projectId}/remove_move_out_date`);

// MutationFn

type RemoveProjectMoveOutDateMutation = MutationFunction<
  unknown,
  RemoveProjectMoveOutDateXhrVariables
>;

export const removeProjectMoveOutDateMutationFn: RemoveProjectMoveOutDateMutation =
  (variables) => removeProjectMoveOutDateXhr(variables);

// With Mutation HOC

const withRemoveProjectMoveOutDateMutationPropKey =
  'removeProjectMoveOutDateMutation';

export type WithRemoveProjectMoveOutDateMutationProps =
  WithMutationPropsByMutation<
    typeof withRemoveProjectMoveOutDateMutationPropKey,
    RemoveProjectMoveOutDateMutation
  >;

export function withRemoveProjectMoveOutDateMutation<
  P extends WithRemoveProjectMoveOutDateMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithRemoveProjectMoveOutDateMutationProps>> {
  return withMutation(
    withRemoveProjectMoveOutDateMutationPropKey,
    removeProjectMoveOutDateMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateProjectQueryData(client, projectId);
      },
    })
  )(Component as ComponentType<WithRemoveProjectMoveOutDateMutationProps>);
}
