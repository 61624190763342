import React from 'react';
import styled, { type ThemeProps } from 'styled-components';

import Button from 'js/components/partial/Button';
import { type Theme } from 'js/theme';

import { debounce, handleStopPropagation, toClassNameObj } from 'js/utils';

const ButtonStyled = styled(Button)`
  background: none;
  border: 0;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayMid};
  height: 38px;
  padding: 0;
  width: 35px;

  &:hover {
    background: none;
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayMidHover};
  }
`;

const InputStyled = styled.input`
  border-color: ${(props: ThemeProps<Theme>) => props.theme.colors.whiteTwo};
  border-style: solid;
  border-width: 0 1px;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.dark};
  flex-grow: 1;
  height: 38px;
  padding: 0;
  text-align: center;
  outline: 0;
  width: 1px;
`;

const InputGroup = styled.div`
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  border: 1px solid ${(props: ThemeProps<Theme>) => props.theme.colors.whiteTwo};
  border-radius: 3px;
  display: flex;
  flex-wrap: nowrap;
  height: 40px;
`;

export type QuantityInputChangeHandler = (value?: number) => void;

interface Props {
  onChange: QuantityInputChangeHandler;
  className?: string;
  min?: number;
  max?: number;
  value?: number;
  disabled?: boolean;
}

interface State {
  value?: number;
}

export default class QuantityInput extends React.Component<Props, State> {
  private readonly handleValue: (value?: number) => void = debounce(
    this.props.onChange
  );

  override state: State = {
    value: 0,
  };

  override componentDidMount() {
    this.setState({ value: this.props.value });
  }

  override componentDidUpdate(prevProps: Props) {
    const { value } = this.props;
    if (value !== prevProps.value && value !== this.state.value) {
      this.setState({ value });
    }
  }

  private setSafeValue(value: number) {
    const { min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY } =
      this.props;

    value = Math.max(min, value);
    value = Math.min(max, value);

    if (value !== this.state.value) {
      this.setState({ value });
      this.handleValue(value);
    }

    return value;
  }

  private handleIncrement = () => {
    this.setSafeValue((this.state.value ?? 0) + 1);
  };

  private handleDecrement = () => {
    this.setSafeValue((this.state.value ?? 0) - 1);
  };

  private handleInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === '') {
      this.setState({ value: undefined });
      this.handleValue(undefined);
      return;
    }
    const candidate = Number.parseInt(e.target.value);
    if (!isNaN(candidate)) this.setSafeValue(candidate);
  };

  override render() {
    const { disabled } = this.props;
    return (
      <InputGroup
        onClick={handleStopPropagation}
        {...toClassNameObj(this.props.className ?? '')}
      >
        <ButtonStyled onClick={this.handleDecrement} disabled={disabled}>
          -
        </ButtonStyled>
        <InputStyled
          type="number"
          pattern="\d*"
          value={this.state.value ?? ''}
          onChange={this.handleInput}
          disabled={disabled}
        />
        <ButtonStyled onClick={this.handleIncrement} disabled={disabled}>
          +
        </ButtonStyled>
      </InputGroup>
    );
  }
}
