import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function SpinnerCircle({ color = '#ddd', ...props }: Props) {
  return (
    <svg
      className="icon-spinner-circle"
      viewBox="0 0 10 10"
      height={50}
      width={50}
      {...props}
    >
      <path
        className="color"
        fill={color}
        d="M8.5,1.5C7.6,0.5,6.3,0,5,0S2.4,0.5,1.5,1.5C0.5,2.4,0,3.7,0,5s0.5,2.6,1.5,3.5C2.4,9.5,3.7,10,5,10s2.6-0.5,3.5-1.5
			C9.5,7.6,10,6.3,10,5C10,3.7,9.5,2.4,8.5,1.5L8.5,1.5z M8.2,1.8c0.9,0.9,1.3,2,1.3,3.2h-1c0-1.9-1.6-3.5-3.5-3.5
			c-0.1,0-0.2,0-0.3,0v-1c0.1,0,0.2,0,0.3,0C6.2,0.5,7.4,0.9,8.2,1.8L8.2,1.8z M5,8.1C3.3,8.1,1.9,6.7,1.9,5c0-1.7,1.4-3.1,3.1-3.1
			S8.1,3.3,8.1,5S6.7,8.1,5,8.1L5,8.1z M5,8.1"
      />
    </svg>
  );
}

export default SpinnerCircle;
