import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  invalidateCurrentUserQueryData,
  type UpdateUserBody,
  type User,
} from '..';

/**
 * PUT - /api/v1/eames/user/update
 */

// HTTP

export interface UpdateUserXhrVariables {
  body: UpdateUserBody;
}

export const updateUserXhr = ({
  body,
}: UpdateUserXhrVariables): Promise<User> =>
  http.put<User>(`/api/v1/eames/users/update`, body).then((res) => res.data);

// MutationFn

type UpdateUserMutation = MutationFunction<User, UpdateUserXhrVariables>;

export const updateUserMutationFn: UpdateUserMutation = (variables) =>
  updateUserXhr(variables);

// With Mutation HOC

const withUpdateUserMutationPropKey = 'updateUserMutation';

export type WithUpdateUserMutationProps = WithMutationPropsByMutation<
  typeof withUpdateUserMutationPropKey,
  UpdateUserMutation
>;

export function withUpdateUserMutation<P extends WithUpdateUserMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateUserMutationProps>> {
  return withMutation(
    withUpdateUserMutationPropKey,
    updateUserMutationFn,
    (client) => ({
      onSuccess: async (_user, { body }) => {
        //@TOOD Investigate what queries depend on marketId to invalidate only them
        await invalidateCurrentUserQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateUserMutationProps>);
}
