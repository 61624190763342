import { type ComponentType } from 'react';
import {
  QueryClient,
  type InfiniteData,
  type MutationFunction,
} from 'react-query';

import {
  http,
  withMutation,
  invalidateGetRoomsQueryData,
  createGetRoomQueryKey,
  createGetRoomsQueryKey,
  createGetProductsQueryKey,
  type Project,
  type Product,
  type Room,
  type RoomProduct,
  type WithMutationPropsByMutation,
  type GetRoomsXhr,
  type GetRoomXhr,
  type GetProductsXhr,
} from 'js/api';
import { mockResponse } from 'js/api/mock/utils';

// @TODO Rewmove - it replased by room update
/**
 * POST - /inventory/v1/rooms/:roomId/products
 */

// HTTP

interface CreateRoomProductXhrVariables {
  projectId: Project['id'];
  roomId: Room['id'];
  body: CreateRoomProductXhrBody;
}

export interface CreateRoomProductXhrBody {
  productId: Product['id'];
  quantity: number;
}
export interface CreateRoomProductXhrResponse {
  data: RoomProduct;
}

export const createRoomProductXhr = ({
  roomId,
  body,
}: CreateRoomProductXhrVariables): Promise<RoomProduct> => mockResponse({});
// http
//   .post<CreateRoomProductXhrResponse>(
//     `/inventory/v1/rooms/${roomId}/products`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type CreateRoomProductMutation = MutationFunction<
  RoomProduct,
  CreateRoomProductXhrVariables
>;

export const createRoomProductMutationFn: CreateRoomProductMutation = (
  variables
) => createRoomProductXhr(variables);

// Update cache

export const updateCacheForCreateRoomProductMutation = (
  client: QueryClient,
  newRoomProduct: Awaited<ReturnType<typeof createRoomProductXhr>>,
  roomId: Room['id'],
  projectId: Project['id']
) => {
  // Update room cache
  // client.setQueriesData<GetRoomXhr | undefined>(
  //   createGetRoomQueryKey([roomId, projectId, true]),
  //   (data) => {
  //     if (!data) return data;
  //     const index = data.roomProducts.findIndex(
  //       (roomProduct) => roomProduct.id === newRoomProduct.id
  //     );
  //     const updatedData = { ...data };
  //     if (index === -1 || index == null)
  //       updatedData.roomProducts.push(newRoomProduct);
  //     else updatedData.roomProducts[index] = { ...newRoomProduct };
  //     return updatedData;
  //   }
  // );
  // Update rooms cache
  // client.setQueriesData<GetRoomsXhr | undefined>(
  //   createGetRoomsQueryKey([projectId, undefined]),
  //   (data) => {
  //     const roomIndex = data?.findIndex((room) => room.id === roomId);
  //     if (!data || roomIndex === -1 || roomIndex == null) return data;
  //     const index = data[roomIndex].roomProducts.findIndex(
  //       (roomProduct) => roomProduct.id === newRoomProduct.id
  //     );
  //     const updatedData = [...data];
  //     if (index === -1 || index == null)
  //       updatedData[roomIndex].roomProducts.push(newRoomProduct);
  //     else updatedData[roomIndex].roomProducts[index] = { ...newRoomProduct };
  //     return updatedData;
  //   }
  // );
  // Update search results
  // client.setQueriesData<InfiniteData<GetProductsXhr> | undefined>(
  //   createGetProductsQueryKey([projectId]),
  //   (data) => {
  //     if (!data || !data?.pages) return data;
  //     const updatedPages = data.pages.map((page) => {
  //       const updatedPageData = page.data.map((product) =>
  //         product.id === newRoomProduct.product.id
  //           ? newRoomProduct.product
  //           : product
  //       );
  //       return { ...page, data: updatedPageData };
  //     });
  //     return { ...data, pages: updatedPages };
  //   }
  // );
};

// With Mutation HOC

const withCreateRoomProductMutationPropKey = 'createRoomProductMutation';

export type WithCreateRoomProductMutationProps = WithMutationPropsByMutation<
  typeof withCreateRoomProductMutationPropKey,
  CreateRoomProductMutation
>;

export function withCreateRoomProductMutation<
  P extends WithCreateRoomProductMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateRoomProductMutationProps>> {
  return withMutation(
    withCreateRoomProductMutationPropKey,
    createRoomProductMutationFn,
    (client) => ({
      onSuccess: async (newRoomProduct, { roomId, projectId }) => {
        updateCacheForCreateRoomProductMutation(
          client,
          newRoomProduct,
          roomId,
          projectId
        );
        // @TODO Check what else need to invalidate
        // @TODO Invalidate because unkonwn filter server logic
        await invalidateGetRoomsQueryData(client, [
          projectId,
          // { onlyEcommEligible: true },
        ]);
      },
    })
  )(Component as ComponentType<WithCreateRoomProductMutationProps>);
}
