import React from 'react';
import {
  type QueryClient,
  type UseQueryOptions,
  type QueryFunction,
} from 'react-query';

import { http, Query, type QueryProps } from 'js/api/';

import { type Material } from '..';
import { mockResponse } from 'js/api/mock/utils';
import { materialsMock } from 'js/api/mock/materialsMock';

/**
 * GET - /inventory/v1/materials
 */

// HTTP

export interface MaterialsData {
  materials: Material[];
  finishes: Material[];
}

export interface GetProductMaterialsXhr {
  data: MaterialsData;
}

export const getProductMaterialsXhr = (): Promise<MaterialsData> =>
  mockResponse(materialsMock);
// http
//   .get<GetProductMaterialsXhr>(`/inventory/v1/materials`)
//   .then((res) => res.data.data);

// Query Key

const GET_PRODUCT_MATERIALS_QUERY_KEY_NAMESPACE = 'getProductMaterials';

type GetProductMaterialsQueryKeyNamespace =
  typeof GET_PRODUCT_MATERIALS_QUERY_KEY_NAMESPACE;

export type GetProductMaterialsQueryKey = [
  GetProductMaterialsQueryKeyNamespace
];

export const createGetProductMaterialsQueryKey =
  (): GetProductMaterialsQueryKey => [
    GET_PRODUCT_MATERIALS_QUERY_KEY_NAMESPACE,
  ];

// QueryFn

type GetProductMaterialsQueryFn = QueryFunction<
  MaterialsData,
  GetProductMaterialsQueryKey
>;

export const getProductMaterialsQueryFn: GetProductMaterialsQueryFn = () =>
  getProductMaterialsXhr();

// create Query Options

export type GetProductMaterialsQueryOptions = UseQueryOptions<
  MaterialsData,
  unknown,
  MaterialsData,
  GetProductMaterialsQueryKey
>;

export interface GetProductMaterialsQueryOptionsProps {
  options?: Omit<GetProductMaterialsQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetProductMaterialsQueryOptions = ({
  options,
}: GetProductMaterialsQueryOptionsProps): GetProductMaterialsQueryOptions => ({
  queryFn: getProductMaterialsQueryFn,
  queryKey: createGetProductMaterialsQueryKey(),
  ...options,
});

// Query

export type GetProductMaterialsQueryProps = Omit<
  QueryProps<
    MaterialsData,
    unknown,
    MaterialsData,
    GetProductMaterialsQueryKey
  >,
  'queryFn' | 'queryKey'
>;

export type GetProductMaterialsQueryRenderFn =
  GetProductMaterialsQueryProps['children'];

export type GetProductMaterialsQueryResult =
  Parameters<GetProductMaterialsQueryRenderFn>[0];

export const GetProductMaterialsQuery = ({
  ...props
}: GetProductMaterialsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProductMaterialsQueryKey()}
      queryFn={getProductMaterialsQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProductMaterialsQueryData = async (
  client: QueryClient
) => {
  await client.invalidateQueries(GET_PRODUCT_MATERIALS_QUERY_KEY_NAMESPACE);
};
