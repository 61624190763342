import { ItemType } from '..';

import { type Option } from 'js/components/partial/Forms/Select';

export const mapItemTypeToOption = (itemType: ItemType): Option<number> => {
  return {
    value: itemType.id,
    label: itemType.name,
  };
};
