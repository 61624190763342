import React from 'react';
import {
  type QueryClient,
  type UseQueryOptions,
  type QueryFunction,
} from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type Room,
  type Project,
} from 'js/api';

/**
 * GET - /api/v1/eames/projects/:projectId/rooms/:roomId
 */

// HTTP

export interface GetRoomXhrParams {
  show_items?: boolean;
}

export interface GetRoomOptionsXhrConfig extends HttpBaseConfig {
  params?: GetRoomXhrParams;
}

export type GetRoomXhr = Room;

export const getRoomXhr = (
  roomId: Room['id'],
  projectId: Project['id'],
  config: GetRoomOptionsXhrConfig
): Promise<GetRoomXhr> =>
  http
    .get<GetRoomXhr>(
      `/api/v1/eames/projects/${projectId}/rooms/${roomId}`,
      config
    )
    .then((res) => res.data);

// Query Key

const GET_ROOM_QUERY_KEY_NAMESPACE = 'room';

type GetRoomQueryKeyNamespace = typeof GET_ROOM_QUERY_KEY_NAMESPACE;

export type GetRoomQueryKey = [
  GetRoomQueryKeyNamespace,
  Room['id'],
  Project['id'],
  boolean // withItems
];

export const createGetRoomQueryKey = (
  key: SkipFirst<GetRoomQueryKey>
): GetRoomQueryKey => [GET_ROOM_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getRoomQueryFn: QueryFunction<GetRoomXhr, GetRoomQueryKey> = ({
  queryKey: [_d, roomId, projectId, withItems],
  signal,
}) => {
  return getRoomXhr(roomId, projectId, {
    signal,
    ...(withItems ? { params: { show_items: withItems } } : {}),
  });
};
// create Query Options

export type GetRoomQueryOptions = UseQueryOptions<
  GetRoomXhr,
  unknown,
  GetRoomXhr,
  GetRoomQueryKey
>;

export interface GetRoomQueryOptionsProps {
  projectId: Project['id'];
  roomId: Room['id'];
  withItems?: boolean;
  options?: Omit<GetRoomQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetRoomQueryOptions = ({
  roomId,
  projectId,
  withItems = false,
  options,
}: GetRoomQueryOptionsProps): GetRoomQueryOptions => ({
  queryFn: getRoomQueryFn,
  queryKey: createGetRoomQueryKey([roomId, projectId, withItems]),
  ...options,
});

// Query

export interface GetRoomQueryProps
  extends Omit<
    QueryProps<GetRoomXhr, Error, GetRoomXhr, GetRoomQueryKey>,
    'queryFn' | 'queryKey'
  > {
  roomId: Room['id'];
  projectId: Project['id'];
  withItems?: boolean;
}

export type GetRoomQueryResult = Parameters<GetRoomQueryProps['children']>[0];

export const GetRoomQuery = ({
  roomId,
  projectId,
  withItems = false,
  ...props
}: GetRoomQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetRoomQueryKey([roomId, projectId, withItems])}
      queryFn={getRoomQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetRoomQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetRoomQueryKey>
) => {
  await client.invalidateQueries(createGetRoomQueryKey(keyParams));
};
