import React, { useMemo } from 'react';
import { useQuery, type QueryClient, type QueryFunction } from 'react-query';

import { type SkipFirst, type ArrayItem, type KeysToCamelCase } from 'js/types';
import {
  http,
  Query,
  InfiniteQuery,
  type QueryProps,
  type InfiniteQueryProps,
  type HttpBaseConfig,
  type SortQueryParams,
  type SortParams,
  mapSortObjectToQueryParam,
} from 'js/api';

import { type ProjectRaw, Project, ProjectStatus } from '..';

/**
 * GET - /api/v1/eames/projects
 */

// HTTP

const PROJECT_FIELDS = [
  'id',
  'old_eames_id',
  'streetview_image',
  'name',
  'designers',
  'salespersons',
  'full_name',
  'designer_status',
  'designer_updated_at',
  'designer_completed_at',
  'reservation_start_date',
  'install_date',
  'end_date',
  'status',
  'moveout_date',
  'project_type',
  'lost_at',
] as const;

export type ProjectData = KeysToCamelCase<ProjectDataRaw>;

export type ProjectDataRaw = Pick<ProjectRaw, ArrayItem<typeof PROJECT_FIELDS>>;

export type GetProjectsXhr = KeysToCamelCase<GetProjectsXhrRaw>;

export interface GetProjectsXhrRaw {
  data: ProjectDataRaw[];
  total_count: number;
  total_pages: number;
}

export interface GetProjectsXhrConfig extends HttpBaseConfig {
  params?: GetProjectsXhrParams;
}

export enum MovementTypes {
  In = 'in',
  Out = 'out',
}

export interface GetProjectsXhrParams extends Partial<SortQueryParams> {
  only?: readonly string[];
  page?: number;
  perPage?: number;
  q?: string;
  projectType?: Project['projectType'];
  status?: string | string[];
}

export enum FILTER_STATUS {
  ALL = 'all',
  RESERVING = 'reserving',
  PICKING = 'picking',
  ON_SITE = 'on-site',
  LOST = 'lost',
  ACTIVE = 'active',
  COMPLETED = 'completed',
  ARCHIVED = 'archived',
}

export type ProjectsSortKeys = keyof ProjectRaw;

export type ProjectsSortParams = SortParams<ProjectsSortKeys>;

export interface ProjectsFilters
  extends Pick<GetProjectsXhrParams, 'page' | 'perPage' | 'q' | 'projectType'> {
  status: FILTER_STATUS;
  sort?: ProjectsSortParams;
}

export const FILTERS = {
  ADMIN: [
    FILTER_STATUS.ALL,
    FILTER_STATUS.RESERVING,
    FILTER_STATUS.PICKING,
    FILTER_STATUS.ON_SITE,
    FILTER_STATUS.LOST,
    FILTER_STATUS.ARCHIVED,
  ],
  DESIGNER: [FILTER_STATUS.ACTIVE, FILTER_STATUS.COMPLETED],
};

// @TODO Suggest rewok to see relation to role (role -> role's filter.status -> list of filters)
export const API_FILTERS: Record<
  FILTER_STATUS,
  Partial<GetProjectsXhrParams>
> = {
  [FILTER_STATUS.ALL]: {},
  [FILTER_STATUS.RESERVING]: { status: ProjectStatus.Reserving },
  [FILTER_STATUS.PICKING]: { status: ProjectStatus.Picking },
  [FILTER_STATUS.ON_SITE]: { status: ProjectStatus.OnSite },
  [FILTER_STATUS.ACTIVE]: {
    status: [ProjectStatus.Reserving, ProjectStatus.Picking],
  },
  [FILTER_STATUS.COMPLETED]: {
    status: [ProjectStatus.Closed, ProjectStatus.OnSite, ProjectStatus.Lost],
  },
  [FILTER_STATUS.LOST]: { status: ProjectStatus.Lost },
  [FILTER_STATUS.ARCHIVED]: { status: ProjectStatus.Closed },
};

const mapFilterToXhrParams = ({
  status,
  sort,
  ...filters
}: ProjectsFilters) => {
  const params: GetProjectsXhrParams = {
    ...filters,
    ...API_FILTERS[status],
    ...(sort ? mapSortObjectToQueryParam(sort, { withDecamelize: true }) : {}),
  };

  return params;
};

export const getProjectsXhr = (
  config: GetProjectsXhrConfig
): Promise<GetProjectsXhr> =>
  http
    .get<GetProjectsXhr>(`/api/v1/eames/projects`, config)
    .then((res) => res.data);

// Query Key

const GET_PROJECTS_QUERY_KEY_NAMESPACE = 'projects';

type GetProjectsQueryKeyNamespace = typeof GET_PROJECTS_QUERY_KEY_NAMESPACE;

export type GetProjectsQueryKey = [
  GetProjectsQueryKeyNamespace,
  ProjectsFilters? /* params */
];

export const createGetProjectsQuery = (
  key: SkipFirst<GetProjectsQueryKey>
): GetProjectsQueryKey => [GET_PROJECTS_QUERY_KEY_NAMESPACE, ...key];

const mapQueryKeyToXhrParams = ([
  _n,
  params,
]: GetProjectsQueryKey): GetProjectsXhrParams => {
  return params ? { ...mapFilterToXhrParams(params) } : {};
};

// QueryFn

export const getProjectsQueryFn: QueryFunction<
  GetProjectsXhr,
  GetProjectsQueryKey
> = ({ signal, queryKey, pageParam }) => {
  return getProjectsXhr({
    signal,
    params: { ...mapQueryKeyToXhrParams(queryKey), ...pageParam },
  });
};

// useQuery

export const useGetProjectsQuery = (
  key: SkipFirst<GetProjectsQueryKey>,
  options?: GetProjectsQueryProps['options']
) => {
  const queryKey = useMemo(() => createGetProjectsQuery(key), [key]);
  return useQuery(queryKey, getProjectsQueryFn, options);
};

// Query

export interface GetProjectsQueryProps
  extends Omit<
    QueryProps<GetProjectsXhr, Error, GetProjectsXhr, GetProjectsQueryKey>,
    'queryFn' | 'queryKey'
  > {
  filters?: ProjectsFilters;
}

export type GetProjectsQueryRenderFn = GetProjectsQueryProps['children'];

export type GetProjectsQueryResult = Parameters<GetProjectsQueryRenderFn>[0];

export const GetProjectsQuery = ({
  filters,
  ...props
}: GetProjectsQueryProps) => {
  const queryKey = useMemo(() => createGetProjectsQuery([filters]), [filters]);

  return <Query {...props} queryKey={queryKey} queryFn={getProjectsQueryFn} />;
};

// Infinite Query

export interface GetProjectsInfiniteQueryProps
  extends Omit<
    InfiniteQueryProps<
      GetProjectsXhr,
      Error,
      GetProjectsXhr,
      GetProjectsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  filters?: ProjectsFilters;
}

export type GetProjectsInfiniteQueryRenderFn =
  GetProjectsInfiniteQueryProps['children'];

export type GetProjectsInfiniteQueryResult =
  Parameters<GetProjectsInfiniteQueryRenderFn>[0];

export const GetProjectsInfiniteQuery = ({
  filters,
  options,
  ...props
}: GetProjectsInfiniteQueryProps) => {
  return (
    <InfiniteQuery
      {...props}
      queryKey={createGetProjectsQuery([filters])}
      queryFn={getProjectsQueryFn}
      options={{
        getNextPageParam: (
          lastPage,
          pages
        ): GetProjectsXhrParams | undefined => {
          const perPage = filters?.perPage;

          if (!perPage) return undefined;

          const currentPage = pages.length;
          const hasMore = lastPage.data.length >= perPage;

          if (!hasMore || currentPage === pages[0].totalPages) return undefined;

          const pageParam: GetProjectsXhrParams = {
            page: currentPage + 1,
            perPage,
          };

          return pageParam;
        },
        ...options,
      }}
    />
  );
};

// Query Cache Helpers

export const invalidateGetProjectsInfiniteQueryData = async (
  client: QueryClient,
  key: SkipFirst<GetProjectsQueryKey>
) => {
  await client.invalidateQueries(createGetProjectsQuery(key));
};
