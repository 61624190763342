import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  type Collection,
  type CollectionStatus,
  invalidateCollectionsQueryData,
} from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/collections/:id
 */

// HTTP

export interface UpdateCollectionXhrVariables {
  collectionId: number;
  body: UpdateCollectionXhrBody;
}

export interface UpdateCollectionXhrBody {
  name?: string;
  status?: CollectionStatus;
}

export interface UpdateCollectionXhrResponse {
  data: Collection;
}

export const updateCollectionXhr = ({
  collectionId,
  body,
}: UpdateCollectionXhrVariables): Promise<Collection> => mockResponse({});
// http
//   .put<UpdateCollectionXhrResponse>(
//     `/inventory/v1/collections/${collectionId}`,
//     body
//   )
//   .then((res) => res.data.data);

// MutationFn

type UpdateCollectionMutation = MutationFunction<
  Collection,
  UpdateCollectionXhrVariables
>;

export const updateCollectionMutationFn: UpdateCollectionMutation = (
  variables
) => updateCollectionXhr(variables);

// With Mutation HOC

const withUpdateCollectionMutationPropKey = 'updateCollectionMutation';

export type WithUpdateCollectionMutationProps = WithMutationPropsByMutation<
  typeof withUpdateCollectionMutationPropKey,
  UpdateCollectionMutation
>;

export function withUpdateCollectionMutation<
  P extends WithUpdateCollectionMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateCollectionMutationProps>> {
  return withMutation(
    withUpdateCollectionMutationPropKey,
    updateCollectionMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateCollectionsQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateCollectionMutationProps>);
}
