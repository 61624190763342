import React, { useMemo } from 'react';
import {
  DefaultOptions,
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import { logout, ServerErrorRecord } from 'js/utils';

import { HttpException } from './';
import { useAppStatusContext } from 'js/components/AppStatus/AppStatusProvider';

export interface XhrError {
  description: string;
  error: ServerErrorRecord;
  endpoint?: string;
  status?: number;
}

export interface OperationMeta {
  silentOnError?: boolean;
  description?: string;
}

type QueryErrorHandle = Exclude<QueryCache['config']['onError'], undefined>;

type MutationErrorHandle = Exclude<
  MutationCache['config']['onError'],
  undefined
>;

const cacheTime = 1000 * 60 * 5; // 5 minutes

const REACT_QUERY_DEFAULT_OPTIONS: DefaultOptions = {
  queries: {
    cacheTime,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    retry: false,
  },
};

interface Props {
  children: React.ReactNode;
}

export function QueryProvider({ children }: Props) {
  const { pushError } = useAppStatusContext();

  const handleQueryError: QueryErrorHandle = (err: unknown, query) => {
    handleOperationError(err, query.meta);
  };

  const handleMutationError: MutationErrorHandle = (
    err: unknown,
    _variables,
    _context,
    mutation
  ) => {
    handleOperationError(err, mutation.meta);
  };

  const handleOperationError = (err: unknown, meta?: OperationMeta) => {
    // @TODO should still fire an error even if it's not an http exception
    // Fix it once the error toast flow is not related to redux xhr reducer
    if (err instanceof HttpException) {
      const { errors = [], resoponse, url } = err;
      const status = resoponse?.status;
      const errorMessage = errors[0];

      if (status && [401, 403].includes(status)) {
        // @TODO reset react query cache
        logout();
        return;
      }

      if (!meta?.silentOnError) {
        const description = 'Api Error';
        if (meta?.description) description.concat(` ${meta.description}`);
        pushError({
          description,
          endpoint: url,
          error: errorMessage,
          status,
        });
      }
    }
  };

  const queryClient = useMemo(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: handleQueryError,
        }),
        mutationCache: new MutationCache({
          onError: handleMutationError,
        }),
        defaultOptions: REACT_QUERY_DEFAULT_OPTIONS,
      }),
    []
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}
