import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProjectQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/projects/:id/update_lost_at
 */

// HTTP

interface UpdateProjectLostDateXhrParams {
  lostAt: string;
}

interface UpdateProjectLostDateXhrVariables {
  projectId: number;
  params: UpdateProjectLostDateXhrParams;
}

export const updateProjectLostDateXhr = ({
  projectId,
  params,
}: UpdateProjectLostDateXhrVariables): Promise<unknown> => mockResponse({});
// http.put<unknown>(
//   `/inventory/v1/projects/${projectId}/update_lost_at`,
//   null,
//   {
//     params,
//   }
// );

// MutationFn

type UpdateProjectLostDateMutation = MutationFunction<
  unknown,
  UpdateProjectLostDateXhrVariables
>;

export const updateProjectLostDateMutationFn: UpdateProjectLostDateMutation = (
  variables
) => updateProjectLostDateXhr(variables);

// With Mutation HOC

const withUpdateProjectLostDateMutationPropKey =
  'updateProjectLostDateMutation';

export type WithUpdateProjectLostDateMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateProjectLostDateMutationPropKey,
    UpdateProjectLostDateMutation
  >;

export function withUpdateProjectLostDateMutation<
  P extends WithUpdateProjectLostDateMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectLostDateMutationProps>> {
  return withMutation(
    withUpdateProjectLostDateMutationPropKey,
    updateProjectLostDateMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateProjectQueryData(client, projectId);
      },
    })
  )(Component as ComponentType<WithUpdateProjectLostDateMutationProps>);
}
