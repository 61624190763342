import React, { useState, createContext, useContext, useCallback } from 'react';
import { XhrError } from 'js/api';

interface AppStatusContextValues {
  errors: XhrError[];
  pushError: (error: XhrError) => void;
  resetErrors: () => void;
}

const AppStatusContext = createContext<AppStatusContextValues>({
  errors: [],
  pushError: (_error) => undefined,
  resetErrors: () => undefined,
});

interface Props {
  children: React.ReactNode;
}

export function AppStatusProvider({ children }: Props) {
  const [errors, setErrors] = useState<XhrError[]>([]);

  const pushError = useCallback((error: XhrError) => {
    setErrors((prev) => [...prev, error]);
  }, []);

  const resetErrors = useCallback(() => {
    setErrors([]);
  }, []);

  return (
    <AppStatusContext.Provider
      value={{
        errors,
        pushError,
        resetErrors,
      }}
    >
      {children}
    </AppStatusContext.Provider>
  );
}

export const useAppStatusContext = () => useContext(AppStatusContext);
