import { SegmentService } from '@services/segment_service';
import classNamesBind from 'classnames/bind';
import React, { FunctionComponent } from 'react';
import styles from './icon_text_button.module.css';

const classNames = classNamesBind.bind(styles);

export const IconTextButton: FunctionComponent<{
  id?: string;
  className?: string;
  icon: React.ReactNode;
  onClick: () => void;
  segmentEventContext: string;
  segmentData?: any;
  showTextOnMobile?: boolean;
  title: string;
}> = ({
  children,
  id = '',
  className = '',
  icon,
  onClick,
  segmentEventContext,
  segmentData,
  showTextOnMobile,
  title,
}) => {
  function _onClick() {
    SegmentService.trackEngagementEvent(`${segmentEventContext} Icon Clicked`, {
      ...segmentData,
    });
    onClick();
  }

  return (
    <button
      onClick={_onClick}
      id={id}
      className={classNames({
        'icon-text-button': true,
        [className]: true,
        'show-on-mobile': showTextOnMobile,
      })}
      title={title}
    >
      {icon}
      <span className={styles['icon-text-button--text']}>{children}</span>
    </button>
  );
};

IconTextButton.defaultProps = { className: '', title: '' };

export const IconTextLink: FunctionComponent<{
  id?: string;
  className?: string;
  icon: React.ReactNode;
  href: string;
  segmentEventContext: string;
  segmentData?: any;
  showTextOnMobile?: boolean;
  title: string;
  linkProps?: React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >;
}> = ({
  children,
  id = '',
  className = '',
  icon,
  href,
  segmentEventContext,
  segmentData,
  showTextOnMobile,
  title,
  linkProps,
}) => {
  function _onClick() {
    SegmentService.trackEngagementEvent(`${segmentEventContext} Icon Clicked`, {
      ...segmentData,
    });
  }

  return (
    <a
      id={id}
      href={href}
      onClick={_onClick}
      className={classNames({
        'icon-text-button': true,
        [className]: true,
        'show-on-mobile': showTextOnMobile,
      })}
      title={title}
      {...linkProps}
    >
      {icon}{' '}
      <span className={styles['icon-text-button--text']}>{children}</span>
    </a>
  );
};

IconTextLink.defaultProps = { className: '' };
