import { SessionData, SessionService } from '@services/session_service';
import { useQuery } from 'react-query';

export const SessionDataKey = ['Session Data'];

export function useSessionData() {
  const sessionDataQuery = useQuery<SessionData, Error>(
    SessionDataKey,
    SessionService.fetchSessionData,
    {
      staleTime: 1000 * 60 * 5, // every five minutes
    }
  );
  return sessionDataQuery;
}
