import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  RoomProductItemStatus,
  type WithMutationPropsByMutation,
} from 'js/api';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/items/bulk_move
 */

// HTTP

export interface BulkMoveProjectItemsXhrParams {
  projectId: number;
  projectIdForLocation?: number;
  status: RoomProductItemStatus | null;
  deliveryDate: string | null;
}

export interface BulkMoveProjectItemsXhrVariables {
  body: BulkMoveProjectItemsXhrParams;
}

export const bulkMoveProjectItemsXhr = ({
  body,
}: BulkMoveProjectItemsXhrVariables): Promise<unknown> => mockResponse({});
// http.post<unknown>(`/inventory/v1/items/bulk_move`, body);

// MutationFn

type BulkMoveProjectItemsMutation = MutationFunction<
  unknown,
  BulkMoveProjectItemsXhrVariables
>;

export const bulkMoveProjectItemsMutationFn: BulkMoveProjectItemsMutation = (
  variables
) => bulkMoveProjectItemsXhr(variables);

// With Mutation HOC

const withBulkMoveProjectItemsMutationPropKey = 'bulkMoveProjectItemsMutation';

export type WithBulkMoveProjectItemsMutationProps = WithMutationPropsByMutation<
  typeof withBulkMoveProjectItemsMutationPropKey,
  BulkMoveProjectItemsMutation
>;

export function withBulkMoveProjectItemsMutation<
  P extends WithBulkMoveProjectItemsMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithBulkMoveProjectItemsMutationProps>> {
  return withMutation(
    withBulkMoveProjectItemsMutationPropKey,
    bulkMoveProjectItemsMutationFn
  )(Component as ComponentType<WithBulkMoveProjectItemsMutationProps>);
}
