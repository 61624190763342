import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProductTypesQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - /inventory/v1/product_types/:id
 */

// HTTP

export interface DeleteProductTypeXhrVariables {
  productTypeId: number;
}

export const deleteProductTypeXhr = ({
  productTypeId,
}: DeleteProductTypeXhrVariables): Promise<unknown> => mockResponse({});
// http.delete<unknown>(`/inventory/v1/product_types/${productTypeId}`);

// MutationFn

type DeleteProductTypeMutation = MutationFunction<
  unknown,
  DeleteProductTypeXhrVariables
>;

export const deleteProductTypeMutationFn: DeleteProductTypeMutation = (
  variables
) => deleteProductTypeXhr(variables);

// With Mutation HOC

const withDeleteProductTypeMutationPropKey = 'deleteProductTypeMutation';

export type WithDeleteProductTypeMutationProps = WithMutationPropsByMutation<
  typeof withDeleteProductTypeMutationPropKey,
  DeleteProductTypeMutation
>;

export function withDeleteProductTypeMutation<
  P extends WithDeleteProductTypeMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteProductTypeMutationProps>> {
  return withMutation(
    withDeleteProductTypeMutationPropKey,
    deleteProductTypeMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductTypesQueryData(client);
      },
    })
  )(Component as ComponentType<WithDeleteProductTypeMutationProps>);
}
