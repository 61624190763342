import React from 'react';
import { type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { ProjectFilter, type ProjectWithRPIs } from 'js/api/projects';
import { mockResponse } from 'js/api/mock/utils';
import { newPriceInputMock } from 'js/api/mock/newPriceInputMock';

/**
 * GET - /inventory/v1/projects/:project_id/prices/new
 */

// HTTP

export interface GetNewPriceInputXhr {
  project: ProjectWithRPIs;
}

export interface GetNewPriceInputXhrConfig extends HttpBaseConfig {
  params?: GetNewPriceInputXhrParams;
}

export interface GetNewPriceInputXhrParams {
  itemsPage?: number;
  itemsPerPage?: number;
  filterBy?: ProjectFilter;
}

export const getNewPriceInputXhr = (
  projectId: number,
  config: GetNewPriceInputXhrConfig = {}
): Promise<GetNewPriceInputXhr> => mockResponse(newPriceInputMock);
// http
//   .get<GetNewPriceInputXhr>(
//     `/inventory/v1/projects/${projectId}/prices/new`,
//     config
//   )
//   .then((res) => res.data);

const DEFAULT_PRODUCT_ITEMS_PER_PAGE = 30;

export const getNewPriceInputXhrRecursive = async (
  projectId: number,
  config: GetNewPriceInputXhrConfig = {}
): Promise<GetNewPriceInputXhr> => {
  const params: GetNewPriceInputXhrParams = {
    itemsPage: 1,
    itemsPerPage: DEFAULT_PRODUCT_ITEMS_PER_PAGE,
    ...config.params,
  };

  const res = await getNewPriceInputXhr(projectId, { ...config, params });

  if (
    !res.project.roomProductItems.length ||
    res.project.roomProductItems.length < DEFAULT_PRODUCT_ITEMS_PER_PAGE
  )
    return res;

  const nextPage = await getNewPriceInputXhrRecursive(projectId, {
    ...config,
    params: {
      ...params,
      itemsPage: (params.itemsPage ?? 1) + 1,
    },
  });

  const projectWithItems: ProjectWithRPIs = {
    ...res.project,
    roomProductItems: [
      ...res.project.roomProductItems,
      ...nextPage.project.roomProductItems,
    ],
  };

  return {
    ...res,
    project: projectWithItems,
  };
};

const GET_NEW_PRICE_INPUT_QUERY_KEY_NAMESPACE = 'price/new';

// Query Key

type GetNewPriceInputQueryKeyNamespace =
  typeof GET_NEW_PRICE_INPUT_QUERY_KEY_NAMESPACE;

export type GetNewPriceInputQueryKey = [
  GetNewPriceInputQueryKeyNamespace,
  number /* project id */,
  GetNewPriceInputXhrParams? /* params */
];

export const mapGetNewPriceInputQuery = (
  key: SkipFirst<GetNewPriceInputQueryKey>
): GetNewPriceInputQueryKey => [
  GET_NEW_PRICE_INPUT_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getNewPriceInputQueryFn: QueryFunction<
  GetNewPriceInputXhr,
  GetNewPriceInputQueryKey
> = ({ queryKey: [_d, projectId, params], signal, pageParam }) =>
  getNewPriceInputXhr(projectId, { params, signal, ...pageParam });

export const getNewPriceInputRecursiveQueryFn: QueryFunction<
  GetNewPriceInputXhr,
  GetNewPriceInputQueryKey
> = ({ queryKey: [_d, projectId, params], signal, pageParam }) =>
  getNewPriceInputXhrRecursive(projectId, { params, signal, ...pageParam });

// Query

export interface GetNewPriceInputQueryProps
  extends Omit<
    QueryProps<
      GetNewPriceInputXhr,
      Error,
      GetNewPriceInputXhr,
      GetNewPriceInputQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
  recursive?: boolean;
  params?: GetNewPriceInputXhrParams;
}

export type GetNewPriceInputQueryResult = Parameters<
  GetNewPriceInputQueryProps['children']
>[0];

export const GetNewPriceInputQuery = ({
  projectId,
  recursive,
  params,
  ...props
}: GetNewPriceInputQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetNewPriceInputQuery([projectId, params])}
      queryFn={
        recursive ? getNewPriceInputRecursiveQueryFn : getNewPriceInputQueryFn
      }
    />
  );
};

// Query Cache Helpers

export const invalidateNewPriceInputQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetNewPriceInputQueryKey>
) => {
  const key = mapGetNewPriceInputQuery(keyParams);
  await client.invalidateQueries(key);
};
