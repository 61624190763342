import React from 'react';
import { type Moment } from 'moment';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { FormGroup, type Props as FormGroupProps } from './FormGroup';
import { DatePickerWrapper } from '.';

interface Props
  extends Omit<FormGroupProps, 'onChange' | 'value'>,
    Omit<ReactDatePickerProps, 'id' | 'value' | 'title'> {
  value: Moment | null;
  placeholder?: string;
}

export function DateFormGroup({
  id,
  invalid,
  title,
  value,
  required = false,
  placeholder,
  ...datePickerProps
}: Props) {
  return (
    <FormGroup id={id} title={title} invalid={invalid} required={required}>
      <DatePickerWrapper>
        <DatePicker
          id={id + 'Picker'}
          selected={value?.toDate()}
          isClearable={!required}
          placeholderText={placeholder}
          {...datePickerProps}
        />
      </DatePickerWrapper>
    </FormGroup>
  );
}
