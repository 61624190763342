import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProductMaterialsQueryData, type MaterialId } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - /inventory/v1/materials/:id
 */

// HTTP

export interface DeleteMaterialXhrVariables {
  materialId: MaterialId;
}

export const deleteMaterialXhr = ({
  materialId,
}: DeleteMaterialXhrVariables): Promise<unknown> => mockResponse({});
// http.delete<unknown>(`/inventory/v1/materials/${materialId}`);

// MutationFn

type DeleteMaterialMutation = MutationFunction<
  unknown,
  DeleteMaterialXhrVariables
>;

export const deleteMaterialMutationFn: DeleteMaterialMutation = (variables) =>
  deleteMaterialXhr(variables);

// With Mutation HOC

const withDeleteMaterialMutationPropKey = 'deleteMaterialMutation';

export type WithDeleteMaterialMutationProps = WithMutationPropsByMutation<
  typeof withDeleteMaterialMutationPropKey,
  DeleteMaterialMutation
>;

export function withDeleteMaterialMutation<
  P extends WithDeleteMaterialMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteMaterialMutationProps>> {
  return withMutation(
    withDeleteMaterialMutationPropKey,
    deleteMaterialMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductMaterialsQueryData(client);
      },
    })
  )(Component as ComponentType<WithDeleteMaterialMutationProps>);
}
