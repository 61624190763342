import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  createGetRoomsQueryKey,
  GetRoomsQueryKey,
  GetRoomsXhr,
  http,
  withMutation,
  type Project,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  invalidateGetProjectNotesQueryData,
  type ProjectNote,
  type EamesNoteImagesCreateParams,
} from '../';
import { SkipFirst } from 'js/types';

/**
 * POST - /api/v1/eames/eames_notes
 */

// HTTP

export type CreateProjectNoteBody = {
  body: string;
  imageParams?: EamesNoteImagesCreateParams;
};

export interface CreateProjectNoteXhrVariables {
  body: CreateProjectNoteBody;
  projectId: Project['id'];
  roomId?: number;
  itemId?: number;
  progressCallBack?: (progress: number) => void;
}

export const createProjectNoteXhr = ({
  body,
  projectId,
  roomId,
  itemId,
  progressCallBack,
}: CreateProjectNoteXhrVariables): Promise<ProjectNote> => {
  return http
    .post<ProjectNote>(
      `/api/v1/eames/eames_notes`,
      {
        projectId: projectId,
        ...body,
        ...(roomId ? { roomId } : {}),
        ...(itemId ? { itemId } : {}),
      },
      {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (progressCallBack) {
            const progress = (progressEvent.loaded / progressEvent.total) * 50;
            progressCallBack(progress);
          }
        },
      }
    )
    .then((res) => res.data);
};

// MutationFn

type CreateProjectNoteMutation = MutationFunction<
  ProjectNote,
  CreateProjectNoteXhrVariables
>;

export const createProjectNoteMutationFn: CreateProjectNoteMutation = (
  variables
) => createProjectNoteXhr(variables);

// With Mutation HOC

const withCreateProjectNoteMutationPropKey = 'createProjectNoteMutation';

export type WithCreateProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withCreateProjectNoteMutationPropKey,
  CreateProjectNoteMutation
>;

export function withCreateProjectNoteMutation<
  P extends WithCreateProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateProjectNoteMutationProps>> {
  return withMutation(
    withCreateProjectNoteMutationPropKey,
    createProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (result, { projectId, roomId, itemId }) => {
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
        if (itemId != undefined && roomId != undefined) {
          await updateCacheForCreateItemNoteMutation(
            client,
            result,
            [projectId, { showItems: true }],
            roomId,
            itemId
          );
        }
      },
    })
  )(Component as ComponentType<WithCreateProjectNoteMutationProps>);
}

export const updateCacheForCreateItemNoteMutation = (
  client: QueryClient,
  createdNote: ProjectNote,
  keyParams: SkipFirst<GetRoomsQueryKey>,
  roomId: number,
  itemId: number
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      const index = data?.findIndex((room) => room.id === roomId);
      if (!data || index === -1 || index == null) return data;

      const indexItem = data[index].items.findIndex(
        (item) => item.id === itemId
      );
      if (indexItem === -1 || indexItem == null) return data;

      const updatedData = [...data];
      const updatedRoomItem = { ...updatedData[index].items[indexItem] };
      updatedRoomItem.eamesNote = createdNote;
      updatedData[index].items[indexItem] = updatedRoomItem;
      return updatedData;
    }
  );
};
