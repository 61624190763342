export const projectItemsTrackingMock = {
  items: [
    {
      id: 576932,
      item_name: 'TEST NIKITA L3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153109,
      room_product_id: 634618,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023664',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576931,
      item_name: 'TEST NIKITA L3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153108,
      room_product_id: 634618,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023664',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576930,
      item_name: 'TEST NIKITA L3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153107,
      room_product_id: 634618,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023664',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576929,
      item_name: 'TEST NIKITA L3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153106,
      room_product_id: 634618,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023664',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576927,
      item_name: 'TEST NIKITA L3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153104,
      room_product_id: 634618,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023664',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576906,
      item_name: '111144480980',
      status: 'reserved',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153096,
      room_product_id: 634603,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023657',
      delivery_dateselected: '2',
      delivery_date: '2022/06/20',
      pick_date: '2022/06/17',
    },
    {
      id: 576907,
      item_name: '12222777',
      status: 'reserved',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153097,
      room_product_id: 634604,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023658',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576908,
      item_name: '12222777',
      status: 'in_transit_out',
      location: null,
      cycles: 0,
      item_id: 153098,
      room_product_id: 634604,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023658',
      delivery_dateselected: 'Extended Visit',
      delivery_date: 'Extended Visit',
      pick_date: '2022/06/17',
    },
    {
      id: 576755,
      item_name: 'test 10112',
      status: 'never_picked',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152945,
      room_product_id: 634567,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023623',
      delivery_dateselected: 'Anytime',
      delivery_date: 'Anytime',
      pick_date: '2022/06/17',
    },
    {
      id: 576910,
      item_name: '22change name',
      status: 'ready_to_pick',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153100,
      room_product_id: 634605,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023659',
      delivery_dateselected: '3',
      delivery_date: '2022/06/21',
      pick_date: '2022/06/17',
    },
    {
      id: 576770,
      item_name: 'test 2-new2',
      status: 'ready_to_pick',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152960,
      room_product_id: 634582,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023638',
      delivery_dateselected: '3',
      delivery_date: '2022/06/21',
      pick_date: '2022/06/17',
    },
    {
      id: 576909,
      item_name: '22change name',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 153099,
      room_product_id: 634605,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023659',
      delivery_dateselected: '3',
      delivery_date: '2022/06/21',
      pick_date: '2022/06/17',
    },
    {
      id: 576767,
      item_name: 'test ',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152957,
      room_product_id: 634579,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023635',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576766,
      item_name: 'test 89894',
      status: 'returned',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152956,
      room_product_id: 634578,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023634',
      delivery_dateselected: '2',
      delivery_date: '2022/06/20',
      pick_date: '2022/06/17',
    },
    {
      id: 576765,
      item_name: 'test 19',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152955,
      room_product_id: 634577,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023633',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576764,
      item_name: 'test 18',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152954,
      room_product_id: 634576,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023632',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576763,
      item_name: 'test17',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152953,
      room_product_id: 634575,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023631',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576761,
      item_name: 'test 15',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152951,
      room_product_id: 634573,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023629',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576760,
      item_name: 'test 14',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152950,
      room_product_id: 634572,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023628',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576759,
      item_name: 'test 13',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152949,
      room_product_id: 634571,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023627',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576758,
      item_name: 'test 12',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152948,
      room_product_id: 634570,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023626',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576757,
      item_name: 'test 11',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152947,
      room_product_id: 634569,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023625',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576756,
      item_name: 'test 10',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152946,
      room_product_id: 634568,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023624',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576754,
      item_name: 'test 8',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152944,
      room_product_id: 634566,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023622',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576753,
      item_name: 'test 7 ',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152943,
      room_product_id: 634565,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023621',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576752,
      item_name: 'test 6 ',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152942,
      room_product_id: 634564,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023620',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576751,
      item_name: 'test 5 ',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152941,
      room_product_id: 634563,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023619',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576750,
      item_name: 'test 4',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152940,
      room_product_id: 634562,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023618',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576749,
      item_name: 'test 3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152939,
      room_product_id: 634561,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023617',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576748,
      item_name: 'test 3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152938,
      room_product_id: 634561,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023617',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576747,
      item_name: 'test 3',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152937,
      room_product_id: 634561,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023617',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576746,
      item_name: 'test 2',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152936,
      room_product_id: 634560,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023616',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576745,
      item_name: 'test 1',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152935,
      room_product_id: 634559,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023615',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576742,
      item_name: 'TEST 993',
      status: 'investigate',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152932,
      room_product_id: 634558,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023613',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576743,
      item_name: 'TEST 993',
      status: 'investigate',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152933,
      room_product_id: 634558,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023613',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576741,
      item_name: 'TEST 993',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152931,
      room_product_id: 634558,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023613',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
    {
      id: 576740,
      item_name: 'TEST 993',
      status: 'on_site',
      location: 'Jenny Longacre',
      cycles: 0,
      item_id: 152930,
      room_product_id: 634558,
      thumbnail_url:
        'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/',
      sku: 'HH-023613',
      delivery_dateselected: '1',
      delivery_date: '2022/06/19',
      pick_date: '2022/06/17',
    },
  ],
  current_user_can_update: true,
  delivery_day_count: {
    Anytime: 1,
    'Extended Visit': 1,
    '1 day': 30,
    '2 day': 2,
    '3 day': 3,
    '1 day date': '2022-06-18',
    '2 day date': '2022-06-19',
    '3 day date': '2022-06-20',
  },
  current_user_can_delete: true,
  current_user_can_move_product: true,
};
