import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateStylesQueryData } from '../';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - /inventory/v1/styles/:id
 */

// HTTP

export interface DeleteStyleXhrVariables {
  styleId: number;
}

export const deleteStyleXhr = ({
  styleId,
}: DeleteStyleXhrVariables): Promise<unknown> => mockResponse({});
// http.delete<unknown>(`/inventory/v1/styles/${styleId}`);

// MutationFn

type DeleteStyleMutation = MutationFunction<unknown, DeleteStyleXhrVariables>;

export const deleteStyleMutationFn: DeleteStyleMutation = (variables) =>
  deleteStyleXhr(variables);

// With Mutation HOC

const withDeleteStyleMutationPropKey = 'deleteStyleMutation';

export type WithDeleteStyleMutationProps = WithMutationPropsByMutation<
  typeof withDeleteStyleMutationPropKey,
  DeleteStyleMutation
>;

export function withDeleteStyleMutation<P extends WithDeleteStyleMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteStyleMutationProps>> {
  return withMutation(
    withDeleteStyleMutationPropKey,
    deleteStyleMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateStylesQueryData(client);
      },
    })
  )(Component as ComponentType<WithDeleteStyleMutationProps>);
}
