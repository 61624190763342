import styled, { css, keyframes, type ThemeProps } from 'styled-components';

import { type Theme } from 'js/theme';
import IconCross from 'js/icons/Cross';

const loadingAnim = keyframes`
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }
  
  to {
    left: 100%;
  }
`;

interface ErrorsPopupStyledProps {
  open?: boolean;
}

export const ErrorsPopupStyled = styled.div<ErrorsPopupStyledProps>`
  position: fixed;
  background-color: ${(props: ThemeProps<Theme>) => props.theme.colors.danger};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  cursor: pointer;
  left: calc(50% - 250px);
  opacity: 0;
  padding: 30px 20px 20px;
  top: -100px;
  transition: all 0.5s ease;
  width: 500px;
  z-index: 1002;

  ${(props) =>
    props.open
      ? css`
          opacity: 1;
          top: 60px;
        `
      : ''};
`;

export const ErrorsUnderlayStyled = styled.div`
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1001;
`;

// @TODO should be taken from the IconCross component
interface IconCloseStyledProps {
  color?: string;
}

export const IconCloseStyled = styled(IconCross)<IconCloseStyledProps>`
  position: absolute;
  right: 15px;
  top: 15px;
`;

interface LoadingBarStyledProps {
  isLoading?: boolean;
}

export const LoadingBarStyled = styled.div<LoadingBarStyledProps>`
  position: fixed;
  background-color: transparent;
  height: 2px;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  z-index: 1000;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};

    ${(props) =>
      props.isLoading
        ? css`
            animation: ${loadingAnim} 2s linear infinite;
          `
        : ''};
  }
`;

export const ErrorTitle = styled.h3`
  margin: 0;

  &::after {
    background: rgba(255, 255, 255, 0.5);
    content: '';
    display: block;
    height: 1px;
    margin: 10px 0;
  }
`;

export const ErrorParagraph = styled.p`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
`;
