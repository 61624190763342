import React from 'react';
import { QueryClient, UseQueryOptions, type QueryFunction } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type Collection } from '../';
import { collectionsMock } from 'js/api/mock/collectionsMock';
import { mockResponse } from 'js/api/mock/utils';

/**
 * GET - /inventory/v1/collections
 */

// HTTP

export interface GetCollectionsXhr {
  data: Collection[];
}

export interface GetCollectionsXhrParams {
  onlyActive?: boolean;
}

export interface GetCollectionsXhrConfig extends HttpBaseConfig {
  params?: GetCollectionsXhrParams;
}

export const getCollectionsXhr = (
  config: GetCollectionsXhrConfig = {}
): Promise<Collection[]> => mockResponse<Collection[]>(collectionsMock);
// http
// .get<GetCollectionsXhr>(`/inventory/v1/collections`, config)
// .then((res) => res.data.data);

// Query Key

const GET_COLLECTIONS_QUERY_KEY_NAMESPACE = 'getCollections';

type GetCollectionsQueryKeyNamespace =
  typeof GET_COLLECTIONS_QUERY_KEY_NAMESPACE;

export type GetCollectionsQueryKey = [
  GetCollectionsQueryKeyNamespace,
  GetCollectionsXhrParams? /* params */
];

export const createGetCollectionsQueryKey = (
  key: SkipFirst<GetCollectionsQueryKey>
): GetCollectionsQueryKey => [GET_COLLECTIONS_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetCollectionsQueryFn = QueryFunction<
  Collection[],
  GetCollectionsQueryKey
>;

export const getCollectionsQueryFn: GetCollectionsQueryFn = ({
  queryKey: [_d, params],
  signal,
}) => getCollectionsXhr({ params, signal });

// create Query Options

export type GetCollectionsQueryOptions = UseQueryOptions<
  Collection[],
  unknown,
  Collection[],
  GetCollectionsQueryKey
>;

export interface GetCollectionsQueryOptionsProps {
  params?: GetCollectionsXhrParams;
  options?: Omit<GetCollectionsQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetCollectionsQueryOptions = ({
  params,
  options,
}: GetCollectionsQueryOptionsProps): GetCollectionsQueryOptions => ({
  queryFn: getCollectionsQueryFn,
  queryKey: createGetCollectionsQueryKey([params]),
  ...options,
});

// Query

export interface GetCollectionsQueryProps
  extends Omit<
    QueryProps<Collection[], unknown, Collection[], GetCollectionsQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetCollectionsXhrParams;
}

export type GetCollectionsQueryRenderFn = GetCollectionsQueryProps['children'];

export type GetCollectionsQueryResult =
  Parameters<GetCollectionsQueryRenderFn>[0];

export const GetCollectionsQuery = ({
  params,
  ...props
}: GetCollectionsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetCollectionsQueryKey([params])}
      queryFn={getCollectionsQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateCollectionsQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_COLLECTIONS_QUERY_KEY_NAMESPACE);
};
