import { BrandParams } from '@utility_functions/getBrandProps';
import getConfig from 'next/config';
import Head from 'next/head';
import { ReactNode } from 'react';

const { publicRuntimeConfig } = getConfig();
const baseUrl = publicRuntimeConfig.baseUrl;

export default function BrandLayout({
  children,
  brand,
}: {
  children: ReactNode;
  brand: BrandParams;
}) {
  const title = `${brand.brandTitle}: Furniture rental for an easier and more sustainable home.`;
  const description = `Rent, rent-to-own, or buy furniture and décor from brands you love.`;

  return (
    <>
      <Head>
        {/* icon */}
        <link
          rel="icon"
          href={`/next_assets/brands/${brand.name}/favicon.ico`}
        />

        {/* seo title */}
        <title key="title">{title}</title>
        <meta property="og:title" key="og:title" content={title} />
        <meta name="company-brand" content={brand.name} />
        {/* seo description */}
        <meta name="description" key="description" content={description} />
        <meta
          property="og:description"
          key="og:description"
          content={description}
        />

        {/* seo image */}
        <meta
          property="og:image"
          key="og:image"
          content={`${baseUrl}/next_assets/ogimage.jpg`}
        />

        {/* seo keywords */}
        <meta
          name="keywords"
          key="keywords"
          content={`${brand.brandTitle}, ${brand.domain}, rental furniture,subscription furniture,furniture lease,convenient,inspirational,home,homemade,home made,home service,moving,furniture,decor,accessories,sofa,bed,couch,lamp,chair,dining table,table,rug,desk,shelving,bookshelf,bench,loveseat,dresser,nightstand,sectional,mattress,bar stool`}
        />

        {/* preloading fonts for optimization */}
        <link
          rel="preload"
          crossOrigin="anonymous"
          as="font"
          type="font/woff"
          href="/next_assets/fonts/founders-grotesk-web-regular.woff"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          as="font"
          type="font/woff"
          href="/next_assets/fonts/GT-Walsheim-Regular.woff"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          as="font"
          type="font/woff"
          href="/next_assets/fonts/GT-Walsheim-Medium.woff"
        />
        <link
          rel="preload"
          crossOrigin="anonymous"
          as="font"
          type="font/woff"
          href="/next_assets/fonts/raw_soul-webfont.woff"
        />

        {/* misc meta tags */}
        <meta name="theme-color" content="#c8ae8f" />
        <meta
          name="p:domain_verify"
          content="e8ac2fc626681ba61eaf86c7061942f0"
        />
        <meta
          name="twitter:site"
          key="twitter:site"
          content={`@${brand.twitter}`}
        />
        <meta
          name="twitter:creator"
          key="twitter:creator"
          content={`@${brand.twitter}`}
        />
      </Head>
      <div>{children}</div>
    </>
  );
}
