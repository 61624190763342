import React from 'react';

import { toClassNames } from 'js/utils';

interface Props {
  className?: string;
  color?: string;
}

function CheckIcon({ className = '', color = '#c69d6e' }: Props) {
  return (
    <svg
      className={toClassNames('icon', className)}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="16.986px"
      height="12.986px"
      viewBox="0 0 16.986 12.986"
      enableBackground="new 0 0 16.986 12.986"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
          d="M16.986,1.399L5.132,12.986L0,7.982l1.428-1.399l3.702,3.61 L15.558,0L16.986,1.399z"
        />
      </g>
    </svg>
  );
}

export default CheckIcon;
