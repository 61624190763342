export const vestaProduct = {
  id: 23572,
  customer_name: null,
  customer_description: null,
  retail_price: 0,
  images: ['https://cdn.filestackcontent.com/z5WSEv2ReWo4lhCSQNTr'],
  shopify_new_variant_id: null,
  shopify_stock_variant_id: null,
  name: 'TEST 2',
  shopify_product_id: null,
  vendor_price: null,
  manual_retail_price: false,
  height: 0,
  width: 0,
  length: null,
  depth: 0,
  is_liked: null,
  styles: [],
  customer_name_id: 'test-2',
  sku: 'HH-023572',
  color: '',
  material: '',
  audit_tag: 'Audit',
  l1_category: null,
  l2_category: null,
  l3_category: null,
  l4_category: null,
  materials: [],
};
