import React, { useCallback, type ComponentType } from 'react';

import { useGetProjectQuery, type Project } from '..';

export interface WithProjectOuterProps {
  projectId: Project['id'];
}

export interface WithProjectInnerProps {
  project?: Project;
  isProjectLoading: boolean;
  isProjectError: boolean;
  refetchProject: () => Promise<void>;
  shouldProjectRefetch?: boolean;
}

export type WithProjectProps = WithProjectOuterProps & WithProjectInnerProps;

export interface WithProjectConfig {
  refetchOnMount?: boolean;
  keepPreviousData?: boolean;
  shouldProjectRefetchFunc?: (project?: Project) => boolean;
}

const defaultConfig: WithProjectConfig = {
  refetchOnMount: false,
  keepPreviousData: true,
};

/**
 * HOC that wraps a component and provides a projectId, project and helpers as a props.
 */
export const withProject = <
  P extends Partial<WithProjectProps> = WithProjectProps
>(
  Component: ComponentType<P>,
  config?: WithProjectConfig
) => {
  const ComponentWithProjectProps = (
    props: Omit<P, keyof WithProjectInnerProps> & WithProjectOuterProps
  ) => {
    const { projectId } = props;
    const { refetchOnMount, keepPreviousData, shouldProjectRefetchFunc } = {
      ...defaultConfig,
      ...config,
    };

    const { data, isLoading, isRefetching, isError, refetch } =
      useGetProjectQuery({
        projectId: Number(projectId),
        options: { enabled: !!projectId, refetchOnMount, keepPreviousData },
      });

    const refetchProject = useCallback(async () => {
      await refetch();
    }, [refetch]);

    // Do not render component without projectId
    if (!projectId) return null;

    const shouldProjectRefetch =
      shouldProjectRefetchFunc && shouldProjectRefetchFunc(data);

    const componentProps = {
      ...props,
      projectId,
      project: data,
      isProjectLoading: isLoading || isRefetching,
      isProjectError: isError,
      refetchProject,
      shouldProjectRefetch,
    } as P & WithProjectProps;

    return <Component {...componentProps} />;
  };

  return ComponentWithProjectProps;
};

/**
 * HOC that provides optionaly wraps a component with withProject HOC if projectId prop was passed.
 * Important: adding/removing the projectId prop dynamically will call umount/mount rather than rerender.
 */
export const withProjectOptional = <
  P extends Partial<WithProjectProps> = Partial<WithProjectProps>
>(
  Component: ComponentType<P>
) => {
  const ComponentWithProject = withProject(Component);

  return function CompnentWithProjectOptional(
    props: Omit<P, keyof WithProjectInnerProps>
  ) {
    const { projectId } = props;

    if (!projectId) {
      return <Component {...(props as P)} />;
    }

    const componentWithProjectProps = {
      ...props,
      projectId,
    } as P & WithProjectOuterProps;

    return <ComponentWithProject {...componentWithProjectProps} />;
  };
};
