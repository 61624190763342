import React, { useMemo } from 'react';
import {
  type UseQueryOptions,
  type QueryFunction,
  type QueryClient,
  useQuery,
} from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type User } from '..';

/**
 * GET - /api/v1/eames/users/current_user
 */

// HTTP

export const getCurrentUserXhr = (config: HttpBaseConfig = {}): Promise<User> =>
  http.get(`/api/v1/eames/users/current_user`, config).then((res) => res.data);

// Query Key
const GET_CURRENT_USER_QUERY_KEY_NAMESPACE = 'get_current_user_profile';

type GetCurrentUserQueryKeyNamespace =
  typeof GET_CURRENT_USER_QUERY_KEY_NAMESPACE;

export type GetCurrentUserQueryKey = [GetCurrentUserQueryKeyNamespace];

export const createGetCurrentUserQueryKey = (): GetCurrentUserQueryKey => [
  GET_CURRENT_USER_QUERY_KEY_NAMESPACE,
];

// QueryFn

export const getCurrentUserQueryFn: QueryFunction<
  User,
  GetCurrentUserQueryKey
> = ({ signal }) => getCurrentUserXhr({ signal });

// Query Options

export type GetCurrentUserQueryOptions = UseQueryOptions<
  User,
  unknown,
  User,
  GetCurrentUserQueryKey
>;

// Query

export type GetCurrentUserQueryProps = Omit<
  QueryProps<User, unknown, User, GetCurrentUserQueryKey>,
  'queryFn' | 'queryKey'
>;

export type GetCurrentUserQueryRenderFn = GetCurrentUserQueryProps['children'];

export type GetCurrentUserQueryResult =
  Parameters<GetCurrentUserQueryRenderFn>[0];

export interface UseGetCurrentUserQueryProps {
  options?: GetCurrentUserQueryProps['options'];
}

export const useGetCurrentUserQuery = ({
  options,
}: UseGetCurrentUserQueryProps) => {
  const queryKey = useMemo(() => createGetCurrentUserQueryKey(), []);

  return useQuery(queryKey, getCurrentUserQueryFn, options);
};

export const GetCurrentUserQuery = (props: GetCurrentUserQueryProps) => {
  const queryKey = useMemo(createGetCurrentUserQueryKey, []);
  return (
    <Query {...props} queryKey={queryKey} queryFn={getCurrentUserQueryFn} />
  );
};

// Query Cache Helpers

export const invalidateCurrentUserQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_CURRENT_USER_QUERY_KEY_NAMESPACE);
};
