import React from 'react';
import { QueryClient, type QueryFunction } from 'react-query';

import { KeysToCamelCase, type SkipFirst } from 'js/types';
import {
  http,
  Query,
  InfiniteQuery,
  type QueryProps,
  type HttpBaseConfig,
  type InfiniteQueryProps,
} from 'js/api/';

import {
  EamesSearchFacets,
  type GetProductsXhrParams,
  type InventoryProduct,
} from '../models';
import { type QueryParams } from '@services/item_service';

/**
 * GET - /api/v2/public/search
 */

// HTTP

export interface GetProductsXhr {
  queryParams: KeysToCamelCase<QueryParams>;
  items: InventoryProduct[];
  facets: EamesSearchFacets;
}

export interface GetProductsXhrConfig extends HttpBaseConfig {
  params?: GetProductsXhrParams;
}

export const getProductsXhr = (
  config: GetProductsXhrConfig
): Promise<GetProductsXhr> => {
  const endpoint = '/api/v2/public/search';
  return http
    .get<GetProductsXhr>(endpoint, {
      ...config,
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((res) => res.data);
};

const GET_PRODUCTS_QUERY_KEY_NAMESPACE = 'getProducts';

// Query Key

type GetProductsQueryKeyNamespace = typeof GET_PRODUCTS_QUERY_KEY_NAMESPACE;

export type GetProductsQueryKey = [
  GetProductsQueryKeyNamespace,
  GetProductsXhrParams? /* params */
];

export const createGetProductsQueryKey = (
  key: SkipFirst<GetProductsQueryKey>
): GetProductsQueryKey => [GET_PRODUCTS_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetProductsQueryFnType = QueryFunction<
  GetProductsXhr,
  GetProductsQueryKey
>;

export const getProductsQueryFn: GetProductsQueryFnType = ({
  queryKey: [_d, params],
  signal,
  pageParam,
}) => {
  return getProductsXhr({ params: { ...params, ...pageParam }, signal });
};

// Query

export interface GetProductsQueryProps
  extends Omit<
    QueryProps<GetProductsXhr, Error, GetProductsXhr, GetProductsQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetProductsXhrParams;
}

export const GetProductsQuery = ({
  params,
  ...props
}: GetProductsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProductsQueryKey([params])}
      queryFn={getProductsQueryFn}
    />
  );
};

// Infinite Query

export interface GetProductsInfiniteQueryProps
  extends Omit<
    InfiniteQueryProps<
      GetProductsXhr,
      Error,
      GetProductsXhr,
      GetProductsQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params?: GetProductsXhrParams;
}

export type GetProductsInfiniteQueryResult = Parameters<
  GetProductsInfiniteQueryProps['children']
>[0];

export const GetProductsInfiniteQuery = ({
  params,
  options,
  ...props
}: GetProductsInfiniteQueryProps) => {
  return (
    <InfiniteQuery
      {...props}
      queryKey={createGetProductsQueryKey([params])}
      queryFn={getProductsQueryFn}
      options={{
        getNextPageParam: (_p, pages) => {
          const limit = params?.limit;
          const totalCount: number | undefined =
            pages[0]?.queryParams.pageCount;
          if (!limit || !totalCount) return undefined; // Cannot check next page if no params were set up

          const page = pages.length;
          const hasMore = totalCount > page;
          if (!hasMore) return undefined;

          const pageParam = {
            limit,
            page: page + 1,
          };
          return pageParam;
        },
        ...options,
      }}
    />
  );
};

export const invalidateProductsQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetProductsQueryKey>
) => {
  await client.invalidateQueries(createGetProductsQueryKey(keyParams));
};
