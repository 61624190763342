import React from 'react';

import { toClassNames } from 'js/utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  className?: string;
  color?: string;
}

function ChevronDown({ color = '#999', className = '', ...props }: Props) {
  return (
    <svg
      className={toClassNames('icon', 'icon-chevron-down', className)}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      {...props}
    >
      <path
        className="color"
        fill={color}
        d="M5,5.4l-3-3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l3.7,3.7C4.5,7.7,4.7,7.8,5,7.8c0.3,0,0.5-0.1,0.7-0.3l3.7-3.7c0.4-0.4,0.4-1,0-1.4C9,2.1,8.3,2.1,8,2.5L5,5.4L5,5.4z"
      />
    </svg>
  );
}

export default ChevronDown;
