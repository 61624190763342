import React from 'react';
import { useIsFetching, useIsMutating } from 'react-query';

import theme from 'js/theme';
import { useAppStatusContext } from './AppStatusProvider';
import { XhrError } from 'js/api';

import {
  ErrorsPopupStyled,
  ErrorsUnderlayStyled,
  ErrorTitle,
  IconCloseStyled,
  LoadingBarStyled,
  ErrorParagraph,
} from './styled';

interface ErrorMessagesProps {
  errors: XhrError[];
  onClose: () => void;
}

function ErrorMessages({ errors, onClose }: ErrorMessagesProps) {
  const isOpen = errors.length > 0;

  const messages = errors.map((err, i) => {
    let message = `${err.description}: ${err.error?.toString()}`;
    if (err.status) message += ` (${err.status})`;
    return (
      <ErrorParagraph key={i}>
        {message}
        {err.endpoint && (
          <>
            <br /> Endpoint: {err.endpoint}
          </>
        )}
      </ErrorParagraph>
    );
  });

  return (
    <div>
      <ErrorsPopupStyled open={isOpen} onClick={onClose}>
        <IconCloseStyled color={theme.colors.white} />
        <ErrorTitle>Oops!</ErrorTitle>
        {messages}
      </ErrorsPopupStyled>
      {isOpen && (
        <ErrorsUnderlayStyled
          className="app-status-underlay"
          onClick={onClose}
        />
      )}
    </div>
  );
}

const LoadingBar = () => {
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  const isLoading = !!isFetching || !!isMutating;

  return <LoadingBarStyled isLoading={isLoading} />;
};

export default function AppStatus() {
  const { errors, resetErrors } = useAppStatusContext();

  return (
    <div>
      <LoadingBar />
      <ErrorMessages errors={errors} onClose={resetErrors} />
    </div>
  );
}
