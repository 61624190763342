import React, { useMemo } from 'react';
import { useQuery, type QueryFunction, type QueryClient } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { type ProjectItemsByStatus } from '..';
import { mockResponse } from 'js/api/mock/utils';
import { projectItemsByStatusMock } from 'js/api/mock/projectItemsByStatusMock';

/**
 * GET - /api/v1/eames/projects/:id/order_trip_line_items
 */

// HTTP

export interface ProjectItemsByStatusXhr {
  data: ProjectItemsByStatus;
}

export const getProjectItemsByStatusXhr = (
  projectId: number,
  config: HttpBaseConfig = {}
): Promise<ProjectItemsByStatus> =>
  http
    .get<ProjectItemsByStatusXhr>(
      `/api/v1/eames/projects/${projectId}/order_trip_line_items`,
      config
    )
    .then((res) => res.data.data);

// Query Key

const GET_PROJECT_ITEMS_BY_STATUS_QUERY_KEY_NAMESPACE =
  'getProjectItemsByStatusById';

type GetProjectItemsByStatusQueryKeyNamespace =
  typeof GET_PROJECT_ITEMS_BY_STATUS_QUERY_KEY_NAMESPACE;

export type GetProjectItemsByStatusQueryKey = [
  GetProjectItemsByStatusQueryKeyNamespace,
  number /* project id */
];

export const createGetProjectItemsByStatusQueryKey = (
  key: SkipFirst<GetProjectItemsByStatusQueryKey>
): GetProjectItemsByStatusQueryKey => [
  GET_PROJECT_ITEMS_BY_STATUS_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getProjectItemsByStatusQueryFn: QueryFunction<
  ProjectItemsByStatus,
  GetProjectItemsByStatusQueryKey
> = ({ signal, queryKey: [_d, projectId] }) =>
  getProjectItemsByStatusXhr(projectId, {
    signal,
  });

// Query

export interface UseGetProjectItemsByStatusQueryProps {
  projectId: number;
  options?: GetProjectItemsByStatusQueryProps['options'];
}

export const useGetProjectItemsByStatusQuery = ({
  projectId,
  options,
}: UseGetProjectItemsByStatusQueryProps) => {
  const queryKey = useMemo(
    () => createGetProjectItemsByStatusQueryKey([projectId]),
    [projectId]
  );

  return useQuery(queryKey, getProjectItemsByStatusQueryFn, options);
};

export interface GetProjectItemsByStatusQueryProps
  extends Omit<
    QueryProps<
      ProjectItemsByStatus,
      unknown,
      ProjectItemsByStatus,
      GetProjectItemsByStatusQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
}

export type GetProjectItemsByStatusQueryResult = Parameters<
  GetProjectItemsByStatusQueryProps['children']
>[0];

export const GetProjectItemsByStatusQuery = ({
  projectId,
  ...props
}: GetProjectItemsByStatusQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProjectItemsByStatusQueryKey([projectId])}
      queryFn={getProjectItemsByStatusQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProjectItemsByStatusQueryData = async (
  client: QueryClient,
  projectId: number
) => {
  await client.invalidateQueries(
    createGetProjectItemsByStatusQueryKey([projectId])
  );
};
