import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';
import { type SkipFirst } from 'js/types';

import {
  http,
  withMutation,
  createGetRoomsQueryKey,
  type WithMutationPropsByMutation,
  type Room,
  type Project,
  type GetRoomsQueryKey,
  type GetRoomsXhr,
} from 'js/api';

/**
 * DELETE - /api/v1/eames/projects/:project_id/rooms/:roomId
 */

// HTTP

interface DeleteRoomXhrVariables {
  roomId: Room['id'];
  projectId: Project['id'];
}

export const deleteRoomXhr = ({
  roomId,
  projectId,
}: DeleteRoomXhrVariables): Promise<unknown> =>
  http.delete<unknown>(`/api/v1/eames/projects/${projectId}/rooms/${roomId}`);

// MutationFn

type DeleteRoomMutation = MutationFunction<unknown, DeleteRoomXhrVariables>;

export const deleteRoomMutationFn: DeleteRoomMutation = (variables) =>
  deleteRoomXhr(variables);

// Update cache

export const updateCacheForDeleteRoomMutation = (
  client: QueryClient,
  roomId: Room['id'],
  keyParams: SkipFirst<GetRoomsQueryKey>
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      const index = data?.findIndex((room) => room.id === roomId);
      if (!data || index === -1 || index == null) return data;

      const updatedData = [...data];
      updatedData.splice(index, 1);
      return updatedData;
    }
  );
};

// With Mutation HOC

const withDeleteRoomMutationPropKey = 'deleteRoomMutation';

export type WithDeleteRoomMutationProps = WithMutationPropsByMutation<
  typeof withDeleteRoomMutationPropKey,
  DeleteRoomMutation
>;

export function withDeleteRoomMutation<P extends WithDeleteRoomMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteRoomMutationProps>> {
  return withMutation(
    withDeleteRoomMutationPropKey,
    deleteRoomMutationFn,
    (client) => ({
      onSuccess: (_data, { projectId, roomId }) => {
        updateCacheForDeleteRoomMutation(client, roomId, [projectId]);
      },
    })
  )(Component as ComponentType<WithDeleteRoomMutationProps>);
}
