import React, { type ComponentType } from 'react';
import {
  QueryClient,
  useMutation,
  useQueryClient,
  type MutationFunction,
  type UseMutationOptions,
  type UseMutationResult,
} from 'react-query';

// Props types

export type WithMutationPropsByMutation<
  K extends string,
  M,
  TError = unknown,
  TContext = unknown
> = M extends MutationFunction<infer TData, infer TVariables>
  ? WithMutationProps<K, TData, TError, TVariables, TContext>
  : never;

export type WithMutationProps<
  K extends string,
  TData = unknown,
  TError = unknown,
  TVariables = unknown,
  TContext = unknown
> = {
  [key in K]: UseMutationResult<TData, TError, TVariables, TContext>;
};

// Options types

export type MapMutationOptions<TData, TError, TVariables, TContext> = (
  client: QueryClient
) => Omit<
  UseMutationOptions<TData, TError, TVariables, TContext>,
  'mutationFn'
>;

export type WithMutationOptions<TData, TError, TVariables, TContext> =
  | MapMutationOptions<TData, TError, TVariables, TContext>
  | Omit<UseMutationOptions<TData, TError, TVariables, TContext>, 'mutationFn'>;

export type MutationOptionsOfMutation<
  M,
  TError = unknown,
  TContext = unknown
> = M extends MutationFunction<infer TData, infer TVariables>
  ? WithMutationOptions<TData, TError, TVariables, TContext>
  : never;

/**
 * HOC that wraps a component and provides a mutation prop.
 */
export function withMutation<
  K extends string,
  TData,
  TError,
  TVariables,
  TContext
>(
  prop: K,
  mutationFn: MutationFunction<TData, TVariables>,
  options?:
    | MapMutationOptions<TData, TError, TVariables, TContext>
    | Omit<
        UseMutationOptions<TData, TError, TVariables, TContext>,
        'mutationFn'
      >
) {
  return <
    P extends Partial<WithMutationProps<K, TData, TError, TVariables, TContext>>
  >(
    Component: ComponentType<P>
  ) => {
    function ComponentWithMutation(props: Omit<P, keyof WithMutationProps<K>>) {
      const client = useQueryClient();
      const mappedOptions =
        typeof options === 'function' ? options(client) : options;
      const mutationResult = useMutation(mutationFn, mappedOptions);
      const componentProps = {
        ...props,
        [prop]: mutationResult,
      } as P & WithMutationProps<K, TData, TError, TVariables, TContext>;

      return <Component {...componentProps} />;
    }

    return ComponentWithMutation;
  };
}
