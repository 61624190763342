import { type KeysToCamelCase } from 'js/types';
import { RoomItem, RoomItemRaw } from '../items';

export enum RoomType {
  Outdoor = 'Outdoor',
  Bathroom = 'Bathroom',
  Bedroom = 'Bedroom',
  Dining = 'Dining',
  Recreation = 'Recreation',
  KitchenAndDining = 'Kitchen & Dining',
  Living = 'Living',
  Entry = 'Entry',
  Office = 'Office',
  DoNotUse = 'Do not use',
  Unassigned = 'Unassigned',
}

export type RoomTypeForUpdate = `${RoomType}`;

export type RoomSelectType = typeof SELECT_ALL_ROOMS | `${RoomType}`;
export const SELECT_ALL_ROOMS = 'All';

export interface Room extends Omit<KeysToCamelCase<RoomRaw>, 'items'> {
  items: RoomItem[];
}

export interface RoomRaw {
  id: number;
  name: string;
  room_type: RoomType;
  items: RoomItemRaw[];
  items_count: number;
}

export interface RoomsMenuOption extends Pick<Room, 'name'> {
  roomType: RoomSelectType;
  label: string;
  value: Room['id'];
}

export type COLIRoomRaw = Pick<RoomRaw, 'id' | 'name' | 'room_type'>;
export type COLIRoom = KeysToCamelCase<COLIRoomRaw>;
