import { type ComponentType } from 'react';
import {
  type MutationFunction,
  useMutation,
  useQueryClient,
} from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  CustomerOrderLineItem,
  ItemState,
} from '@interfaces/customer_order_line_item.interface';

/**
 * PUT - /api/v1/eames/projects/:id/customer_order_line_items/:id
 */

// HTTP

export interface UpdateCustomerOrderLineItemXhrParams {
  itemState?: ItemState;
}

export interface UpdateCustomerOrderLineItemXhrVariables {
  id: number;
  projectId: number;
  params: UpdateCustomerOrderLineItemXhrParams;
}

export const updateCustomerOrderLineItemXhr = (
  id: number,
  projectId: number,
  params: UpdateCustomerOrderLineItemXhrParams
): Promise<CustomerOrderLineItem> =>
  http
    .put<CustomerOrderLineItem>(
      `/api/v1/eames/projects/${projectId}/customer_order_line_items/${id}`,
      params
    )
    .then((res) => res.data);

// MutationFn

type UpdateCustomerOrderLineItemMutation = MutationFunction<
  CustomerOrderLineItem,
  UpdateCustomerOrderLineItemXhrVariables
>;

export const updateCustomerOrderLineItemMutationFn: UpdateCustomerOrderLineItemMutation =
  ({ id, projectId, params }) =>
    updateCustomerOrderLineItemXhr(id, projectId, params);

// Hook
export const useUpdateCustomerOrderLineItemMutation = () => {
  const client = useQueryClient();

  return useMutation(updateCustomerOrderLineItemMutationFn);
};

// With Mutation HOC

const withUpdateCustomerOrderLineItemMutationPropKey =
  'updateCustomerOrderLineItemMutation';

export type WithUpdateCustomerOrderLineItemMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateCustomerOrderLineItemMutationPropKey,
    UpdateCustomerOrderLineItemMutation
  >;

export function withUpdateCustomerOrderLineItemMutation<
  P extends WithUpdateCustomerOrderLineItemMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateCustomerOrderLineItemMutationProps>> {
  return withMutation(
    withUpdateCustomerOrderLineItemMutationPropKey,
    updateCustomerOrderLineItemMutationFn
  )(Component as ComponentType<WithUpdateCustomerOrderLineItemMutationProps>);
}
