import axios, { type AxiosResponse } from 'axios';

import { type ServerErrorRecord } from 'js/utils';

// @TODO Refactoring this with reconcile server response errors refacoring
export type FailedResponse = AxiosResponse<{
  error?: string;
  errors?: string[];
}>;

export class HttpException {
  public readonly originalError: unknown;
  public readonly resoponse?: FailedResponse;
  public readonly errors?: ServerErrorRecord[];
  public readonly url?: string;

  constructor(err: unknown) {
    this.originalError = err;

    if (axios.isAxiosError(err)) {
      this.resoponse = (err.response ?? {}) as FailedResponse;
      const data = this.resoponse.data;
      this.errors = data.error ? [data.error] : data.errors;
      this.url = err.config.url;
    }
  }
}
