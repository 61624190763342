import { Tag, type StyleMultiSelectOption, type TagBase } from '..';

export const mapTagsToSelectOptions = (
  tags: Tag[] | TagBase[]
): StyleMultiSelectOption[] => {
  return tags?.map((tag) => ({
    value: tag.id,
    label: tag?.displayValue ?? tag.value,
  }));
};
