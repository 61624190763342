import React, { type FormEventHandler } from 'react';
import Router from 'next/router';
import { camelizeKeys } from 'humps';

import { Form, InputStyled, ButtonStyled, AlertStyled } from './styled';

import { handleInputChange, setCSRFToLocalStorage } from 'js/utils';
import { API_URL, APP_NAME, STORED_HEADERS } from 'js/config';

import { track, TRACKED_EVENTS } from 'js/utils/analytics';
import { Brand } from '@utility_functions/getBrandProps';

interface State {
  error: string;
  form: AuthForm;
}

interface AuthForm {
  email: string;
  password: string;
}

// @TODO Update this type when user entity from BE be define
export interface EamesUser {
  id: number;
  email: string;
  brand: Brand;
  fullName: string;
  givenName: string;
  familyName: string;
  warehouseUserId: number;
  role: string;
  stripeCustomerId: null | any;
  accountableId: null | any;
  accountableType: null | any;
  market_id: number;
  provider: string;
  uid: string;
  promoCodeId: null | any;
  zipSubmitted: boolean;
  segmentIdentified: boolean;
  pendingEmail: null | any;
  isLegacy: Boolean;
  zipCode: null | any;
  authyId: null | any;
  lastSignInWithAuthy: null | string;
  authyEnabled: boolean;
  acsApplicantId: null | any;
  latestCreditReportId: null | any;
  passwordUpdatedAt: string;
  anonymousId: string;
  otpSecret: null | any;
}

export default class LoginForm extends React.Component<unknown, State> {
  private readonly handleInputChange = handleInputChange.bind(this);

  override state: State = {
    error: '',
    form: { email: '', password: '' },
  };

  private async loginUser() {
    const { form } = this.state;

    const response = await fetch(`/api/v1/eames/signin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Application': APP_NAME,
        environment: API_URL,
      },
      body: JSON.stringify(form),
    });

    if (response.status >= 500) {
      this.setState({
        error: `Error ${response.status} ${response.statusText}`,
      });
      return;
    }

    STORED_HEADERS.forEach(
      (header) =>
        response.headers.get(header) &&
        localStorage.setItem(header, response.headers.get(header) as string)
    );

    const json = camelizeKeys((await response.json()) as object) as {
      csrf: string;
    };

    track(TRACKED_EVENTS.LOGGED_IN, {
      emailAddress: form.email,
      success: !!json.csrf,
    });

    if (json?.csrf) {
      setCSRFToLocalStorage(json.csrf);
      if (window.location.pathname === '/login') {
        window.location.href = '/projects';
      } else {
        window.location.reload();
      }
    } else {
      this.setState({
        error: 'Invalid username and/or password',
      });
    }
  }

  private handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    this.setState({
      error: ``,
    });
    await this.loginUser();
  };

  override render() {
    const { form, error } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        {error && <AlertStyled messages={[error]} />}
        <Form>
          <InputStyled
            name="email"
            type="email"
            placeholder="Email"
            value={form.email}
            onChange={this.handleInputChange}
          />
          <InputStyled
            name="password"
            type="password"
            placeholder="Password"
            onChange={this.handleInputChange}
          />
        </Form>
        <ButtonStyled
          cta
          type="submit"
          disabled={!form.email || !form.password}
        >
          Login
        </ButtonStyled>
      </form>
    );
  }
}
