import {
  RoomType,
  type Image,
  type Note,
  type Product,
  type Room,
  type ProductLCategory,
  type ProductType,
  type DeliveryDayCounters,
} from 'js/api';
import { IRoomProductItemRaw } from '../rpi/models/model';

import { CamelCase, type KeysToCamelCase } from 'js/types';
import { TimestamptsRaw } from 'js/api/helpers';

export enum DeliveryDayCounter {
  DayOne = '1Day',
  DayTwo = '2Day',
  DayThee = '3Day',
  DayFour = '4Day',
  DayFive = '5Day',
  ExtendedVisit = 'extendedVisit',
  Anytime = 'anytime',
}

export enum DeliveryDay {
  DayOne = '1',
  DayTwo = '2',
  DayThee = '3',
  DayFour = '4',
  DayFive = '5',
  ExtendedVisit = 'Extended Visit',
  Anytime = 'Anytime',
}

export enum RoomProductItemStatus {
  ReadyToPick = 'ready_to_pick',
  NeverPicked = 'never_picked',
  InTransitOut = 'in_transit_out',
  OnSite = 'on_site',
  ReturnRequested = 'return_requested',
  InTransitIn = 'in_transit_in',
  Lost = 'lost',
  Sold = 'sold',
  Damaged = 'damaged',
  Returned = 'returned',
  Reserved = 'reserved',
  DeliveryRequested = 'delivery_requested',
  Investigate = 'investigate',
}

export type RoomProductItemStatusKey = CamelCase<RoomProductItemStatus>;

export enum RoomProductItemStatusCode {
  ReadyToPick = 1,
  NeverPicked = 2,
  InTransitOut = 3,
  OnSite = 4,
  ReturnRequested = 5,
  InTransitIn = 6,
  Lost = 7,
  Sold = 8,
  Damaged = 9,
  Returned = 10,
  Reserved = 11,
  DeliveryRequested = 12,
  Investigate = 13,
}

export enum ItemStatus {
  Active = 'active',
  Sold = 'sold',
  Lost = 'lost',
  Damaged = 'damaged',
  Investigate = 'investigate',
  Deleted = 'deleted',
}

export type RoomProductItem = KeysToCamelCase<RoomProductItemRaw>;

export interface RoomProductItemRaw {
  id: number;
  item_id: number;
  item_name: string;
  sku: string;
  room_name: string;
  room_product_id: number;
  status: RoomProductItemStatus;
  status_updated_at?: string;
  thumbnail_url: string;
  customer_name: string;
  creator_id?: number | null;
  ad_hoc_picked_at?: string | null;
  retail_price: string;
}

export type ProjectItemWithNotes = KeysToCamelCase<ProjectItemWithNotesRaw>;

export interface ProjectItemWithNotesRaw {
  delivery_dateselected: DeliveryDay;
  delivery_day: string;
  item_id: number;
  item_status: RoomProductItemStatus;
  // @TODO check is it needed
  notes: Note[];
  product_name: string;
  product_sku: string;
  room_id: number;
  room_name: string;
  room_product_item_id: number;
  room_type: RoomType;
  thumbnail_url: string | null;
}

export type GroupedItem = KeysToCamelCase<GroupedItemRaw>;

export interface GroupedItemRaw extends TimestamptsRaw {
  id: RoomProductItem['id'];
  sku: Product['sku'];
  item_id: RoomProductItem['itemId'];
  status: RoomProductItemStatus;
  status_updated_at: RoomProductItem['statusUpdatedAt'];
  delivery_date: string;
  delivery_dateselected: DeliveryDay;
  product_id: Product['id'];
  product_types: ProductType[];
  products_shoppable: boolean;
  ecomm_eligible: boolean;
  l1_category: ProductLCategory[] | null;
  l2_category: ProductLCategory[] | null;
  l3_category: ProductLCategory[] | null;
  room_id: Room['id'];
  room_name: string;
}

export interface TrackingProjectItem
  extends Pick<
    GroupedItem,
    | 'id'
    | 'productId'
    | 'itemId'
    | 'sku'
    | 'roomId'
    | 'deliveryDate'
    | 'deliveryDateselected'
    | 'ecommEligible'
    | 'productTypes'
  > {
  imageUrl: Image['thumbnailUrl'];
  name: Product['name'] | '[Unknown]';
  // TODO Replace to types from grouped
  updatedAt: RoomProductItem['statusUpdatedAt'];
  shoppable: GroupedItem['productsShoppable'];
  itemStatusInfo: RoomProductItemStatus;
}

export type TrackingProjectItems = Partial<{
  [key in TrackingStatus]: TrackingProjectItem[];
}>;

export type TrackingStatus = Extract<
  RoomProductItemStatusKey,
  'reserved' | 'inTransitOut' | 'onSite' | 'returned' | 'sold'
>;

// getProjectItemsByStatusXhr

export type GroupedItems = {
  [key in TrackingStatus]?: GroupedItem[];
};

export type ProjectItemsProducts = {
  [key: Product['id']]: Product;
};

export interface ProjectItemsByStatusItem {
  orderTripId: number;
  otlis: any[];
}

export type ProjectItemsByStatus = {
  [key: string]: ProjectItemsByStatusItem;
};

// getProjectItemsTrackingXhr

export type ProjectItemTracking = KeysToCamelCase<ProjectItemTrackingRaw>;

export type ProjectItemTrackingRaw = Pick<
  IRoomProductItemRaw,
  | 'id'
  | 'sku'
  | 'item_id'
  | 'item_name'
  | 'status'
  | 'location'
  | 'cycles'
  | 'room_product_id'
  | 'pick_date'
  | 'delivery_date'
  | 'delivery_dateselected'
  | 'thumbnail_url'
>;

export interface ProjectItemsTracking {
  currentUserCanMoveProduct: boolean;
  currentUserCanUpdate: boolean;
  currentUserCanDelete: boolean;
  deliveryDayCount: DeliveryDayCounters;
  items: ProjectItemTracking[];
}

// getProjectDeliveryPlanningXhr

export type DeliveryPlaningItemRaw = Pick<
  IRoomProductItemRaw,
  | 'id'
  | 'sku'
  | 'item_id'
  | 'item_name'
  | 'status'
  | 'location'
  | 'pick_date'
  | 'delivery_date'
  | 'delivery_dateselected'
  | 'thumbnail_url'
>;

export type DeliveryPlaningItem = KeysToCamelCase<DeliveryPlaningItemRaw>;

export interface ProjectDeliveryPlaning {
  currentUserCanUpdate: boolean;
  currentUserCanUpdateDeliveryDate: boolean;
  deliveryDayCount: DeliveryDayCounters;
  items: DeliveryPlaningItem[];
}
