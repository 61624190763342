import React from 'react';
import { SegmentService } from '../services/segment_service';

interface Props {
  onSwipeLeft: () => void;
  onSwipeRight: () => void;
  segmentContext?: string;
}

interface State {
  initialX: number | null;
}

export class SwipeEvents extends React.Component<Props, State> {
  THRESHOLD = 50;

  constructor(props: Props) {
    super(props);
    this.state = {
      initialX: null,
    };
  }

  handleTouchStart = (e: any) => {
    this.setState({ initialX: e.touches[0].pageX });
  };

  handleTouchEnd = (e: any) => {
    this.determineSwipeDirection(e.changedTouches[0].pageX);
  };

  handleMouseDown = (e: any) => {
    this.setState({ initialX: e.screenX });
  };

  handleMouseUp = (e: any) => {
    this.determineSwipeDirection(e.screenX);
  };

  determineSwipeDirection = (finalX: number) => {
    const delta = finalX - (this.state.initialX || 0);
    if (Math.abs(delta) < this.THRESHOLD) {
      return; // do nothing
    } else if (delta < 0) {
      this.props.segmentContext &&
        SegmentService.trackEngagementEvent(
          `${this.props.segmentContext} Swiped Left`,
          {}
        );
      this.props.onSwipeLeft();
    } else {
      this.props.segmentContext &&
        SegmentService.trackEngagementEvent(
          `${this.props.segmentContext} Swiped Right`,
          {}
        );
      this.props.onSwipeRight();
    }
  };

  render() {
    return (
      <div
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        {this.props.children}
      </div>
    );
  }
}
