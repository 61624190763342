import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { RoomProductItemStatusCode } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - inventory/v1/items/update_item
 */

// HTTP

export interface UpdateProjectItemsAllItem {
  id: number;
  rpiId: number;
  name?: string;
  status?: RoomProductItemStatusCode;
  vendorPrice?: string;
  retailPrice?: number;
  itemRetailPrice?: number;
  soldPrice?: number | null;
  projectIdForLocation?: number;
  deliveryDateselected?: string;
}

export interface UpdateProjectItemsAllXhrParams {
  items: UpdateProjectItemsAllItem[];
}

interface UpdateProjectItemsAllXhrVariables {
  params: UpdateProjectItemsAllXhrParams;
}

export const updateProjectItemsAllXhr = ({
  params,
}: UpdateProjectItemsAllXhrVariables): Promise<unknown> => mockResponse({});
// http.put<unknown>(`inventory/v1/items/update_item`, params);

// MutationFn

type UpdateProjectItemsAllMutation = MutationFunction<
  unknown,
  UpdateProjectItemsAllXhrVariables
>;

export const updateProjectItemsAllMutationFn: UpdateProjectItemsAllMutation = (
  variables
) => updateProjectItemsAllXhr(variables);

// With Mutation HOC

const withUpdateProjectItemsAllMutationPropKey =
  'updateProjectItemsAllMutation';

export type WithUpdateProjectItemsAllMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateProjectItemsAllMutationPropKey,
    UpdateProjectItemsAllMutation
  >;

export function withUpdateProjectItemsAllMutation<
  P extends WithUpdateProjectItemsAllMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectItemsAllMutationProps>> {
  return withMutation(
    withUpdateProjectItemsAllMutationPropKey,
    updateProjectItemsAllMutationFn
  )(Component as ComponentType<WithUpdateProjectItemsAllMutationProps>);
}
