import React from 'react';
import { type QueryFunction, type UseQueryOptions } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type LCategoryBase } from '../';
import { mockResponse } from 'js/api/mock/utils';
import { lCategoriesMock } from 'js/api/mock/lCategoriesMock';

/**
 * GET - /inventory/v1/l1_categories | l2_categories | l3_categories | l4_categories
 */

// HTTP

export enum GetLCategoryType {
  L1 = 'l1_categories',
  L2 = 'l2_categories',
  L3 = 'l3_categories',
  L4 = 'l4_categories',
}
export interface GetLCategoriesXhr {
  data: LCategoryBase[];
}

export interface GetLCategoriesXhrConfig extends HttpBaseConfig {
  params?: GetLCategoriesXhrParams;
}

export interface GetLCategoriesXhrParams {
  parentId?: number;
}

export const getLCategoriesXhr = (
  categoryType: GetLCategoryType,
  config: GetLCategoriesXhrConfig = {}
): Promise<LCategoryBase[]> =>
  mockResponse<LCategoryBase[]>(lCategoriesMock[categoryType]);
// http
//   .get<GetLCategoriesXhr>(`/inventory/v1/${categoryType}`, config)
//   .then((res) => res.data.data);

// Query Key
// @TODO Fix name
const GET_PRICES_QUERY_KEY_NAMESPACE = 'lCategories';

type GetLCategoriesQueryKeyNamespace = typeof GET_PRICES_QUERY_KEY_NAMESPACE;

export type GetLCategoriesQueryKey = [
  GetLCategoriesQueryKeyNamespace,
  GetLCategoryType,
  GetLCategoriesXhrParams? /* params */
];

export const createGetLCategoriesQueryKey = (
  key: SkipFirst<GetLCategoriesQueryKey>
): GetLCategoriesQueryKey => [GET_PRICES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getLCategoriesQueryFn: QueryFunction<
  LCategoryBase[],
  GetLCategoriesQueryKey
> = ({ queryKey: [_d, categoryType, params], signal }) =>
  getLCategoriesXhr(categoryType, { params, signal });

// create Query Options

export type GetLCategoryQueryOptions = UseQueryOptions<
  LCategoryBase[],
  unknown,
  LCategoryBase[],
  GetLCategoriesQueryKey
>;

export interface GetLCategoriesQueryOptionsProps {
  categoryType: GetLCategoryType;
  options?: Omit<GetLCategoryQueryOptions, 'queryFn' | 'queryKey'>;
  params?: GetLCategoriesXhrParams;
}

export const createGetLCategoriesQueryOptions = ({
  categoryType,
  options,
  params,
}: GetLCategoriesQueryOptionsProps): GetLCategoryQueryOptions => ({
  queryFn: getLCategoriesQueryFn,
  queryKey: createGetLCategoriesQueryKey([categoryType, params]),
  ...options,
});

// Query

export interface GetLCategoriesQueryProps
  extends Omit<
    QueryProps<
      LCategoryBase[],
      unknown,
      LCategoryBase[],
      GetLCategoriesQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  categoryType: GetLCategoryType;
  params?: GetLCategoriesXhrParams;
}

export type GetLCategoriesQueryRenderFn = GetLCategoriesQueryProps['children'];

export type GetLCategoriesQueryResult =
  Parameters<GetLCategoriesQueryRenderFn>[0];

export const GetLCategoriesQuery = ({
  categoryType,
  params,
  ...props
}: GetLCategoriesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetLCategoriesQueryKey([categoryType, params])}
      queryFn={getLCategoriesQueryFn}
    />
  );
};
