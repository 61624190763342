import { type ComponentType } from 'react';
import { type MutationFunction, useMutation } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';

/**
 * POST - /api/v1/eames/projects/:id/order_trips
 */

// HTTP

export interface CreateTripXhrVariables {
  projectId: number;
}

export const createTripXhr = (projectId: number): Promise<unknown> =>
  http.post<unknown>(`/api/v1/eames/projects/${projectId}/order_trips`);

// MutationFn

type CreateTripMutation = MutationFunction<unknown, CreateTripXhrVariables>;

export const createTripMutationFn: CreateTripMutation = ({ projectId }) =>
  createTripXhr(projectId);

// Hook
export const useCreateTripMutation = () => {
  return useMutation(createTripMutationFn);
};

// With Mutation HOC

const withCreateTripMutationPropKey = 'createTripMutation';

export type WithCreateTripMutationProps = WithMutationPropsByMutation<
  typeof withCreateTripMutationPropKey,
  CreateTripMutation
>;

export function withCreateTripMutation<P extends WithCreateTripMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateTripMutationProps>> {
  return withMutation(
    withCreateTripMutationPropKey,
    createTripMutationFn
  )(Component as ComponentType<WithCreateTripMutationProps>);
}
