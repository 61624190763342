export const categoriesMock = [
  {
    id: 91,
    name: 'Shag Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 41,
    name: 'Wool Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 130,
    name: 'Ladders',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 38,
    name: 'Cowhide Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 20,
    name: 'Sofa Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 178,
    name: 'Shuffleboard',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 23,
    name: 'Coffee Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 24,
    name: 'Side Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 27,
    name: 'Consoles',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 28,
    name: 'Dining Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 29,
    name: 'Table Bases',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 30,
    name: 'Bar Carts',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 32,
    name: 'Paintings',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 33,
    name: 'Drawings',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 42,
    name: 'Silk Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 43,
    name: 'Moroccan Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 40,
    name: 'Table Tops',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 220,
    name: 'Punching Bags',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 129,
      name: 'Health and Fitness',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 44,
    name: 'Pedestals',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 45,
    name: 'Bookcases',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 46,
    name: 'Night Stands',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 47,
    name: 'Desks',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 49,
    name: 'Dressers',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 53,
    name: 'Prints',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 125,
    name: 'Indoor Fitness machine',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 50,
    name: 'Outdoor',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 221,
      name: 'Fire Table',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 223,
    name: 'Sports Equipment',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 224,
    name: 'Massage Table',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 129,
      name: 'Health and Fitness',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 226,
    name: 'Card Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 227,
    name: 'Plant Stand',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 81,
    name: 'Miscellaneous Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 228,
    name: 'Framed Fabric',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 229,
    name: 'Console',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 129,
    name: 'Health and Fitness',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 217,
    name: 'Plants',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 221,
    name: 'Fire Table',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 131,
    name: 'Outdoor Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 84,
    name: 'Needs Verifications',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 169,
    name: 'Ballet Bar',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 71,
    name: 'Credenzas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 79,
    name: 'Cribs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 64,
    name: 'Cabinets',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 230,
    name: 'Plate Wall Decor ',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 82,
    name: 'Shadow Boxes',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 231,
    name: 'Warehouse Equipment',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 123,
    name: 'Jute',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 218,
    name: 'Weight Equipment',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 219,
    name: 'Workout Equipment',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 129,
      name: 'Health and Fitness',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 89,
    name: 'Tray Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 232,
    name: 'Harness',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 126,
    name: 'Special Props',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 62,
    name: 'Shelves',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 133,
    name: 'Media Console',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 77,
    name: 'Cal King Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 76,
    name: 'Eastern King Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 74,
    name: 'Full Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 75,
    name: 'Queen Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 52,
    name: 'Photography',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 25,
      name: 'Art',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 78,
    name: 'Twin Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 72,
      name: 'Beds',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 264,
    name: 'Coral',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 134,
    name: 'Storage Unit',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 95,
    name: 'Outdoor Counter Stools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 96,
    name: 'Outdoor Dining Chairs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 97,
    name: 'Outdoor Lounge Chairs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 98,
    name: 'Outdoor Benches',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 99,
    name: 'Outdoor Stools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 100,
    name: 'Outdoor Chaise',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 101,
    name: 'Outdoor Modular Sofas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 102,
    name: 'Outdoor Sofas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 103,
    name: 'Outdoor Ottomans',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 104,
    name: 'Outdoor Showers',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 105,
    name: 'Outdoor Cabanas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 106,
    name: 'Outdoor Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 107,
    name: 'Outdoor Bar Stools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 108,
    name: 'Outdoor Bar Carts',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 109,
    name: 'Outdoor Tray Table',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 110,
    name: 'Outdoor Coffee Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 112,
    name: 'Outdoor Side Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 168,
    name: 'Turkish Rugs ',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 135,
    name: 'Candy Machine',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 87,
    name: 'Foosball Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 85,
    name: 'Pool Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 128,
    name: 'Stone Vessels',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 86,
    name: 'Table Tennis Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 173,
    name: 'Daybed',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 181,
    name: 'BBQ',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 88,
    name: 'Chess Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 182,
    name: 'Curtain Panel',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 15,
    name: 'Decanters',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 170,
    name: 'Surf Boards',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 180,
    name: 'Umbrella',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 14,
    name: 'Vases',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 113,
    name: 'Outdoor Dining Tables',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 13,
    name: 'Pillows',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 121,
    name: 'Soft Goods',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 22,
    name: 'Backrests',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 179,
    name: 'Pending',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 92,
    name: 'Small Mirrors',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 172,
    name: 'Wallpaper',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 26,
    name: 'Large Mirrors',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 122,
      name: 'Mirrors',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 111,
    name: 'Outdoor Large Mirror',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 122,
      name: 'Mirrors',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 83,
    name: 'Small Arts',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 6,
    name: 'Dining Chairs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 8,
    name: 'Modular Sofas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 9,
    name: 'Ottomans',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 21,
    name: 'Sofas',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 34,
    name: 'Stools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 63,
    name: 'Chaise Lounges',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 65,
    name: 'Counterstools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 59,
    name: 'Benches',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 18,
    name: 'Lounge Chairs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 80,
    name: 'Office Chairs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 67,
    name: 'Fitness Machines',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 129,
      name: 'Health and Fitness',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 36,
    name: 'Bookends',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 119,
    name: 'Trees',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 217,
      name: 'Plants',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 225,
    name: 'Fire Table',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 94,
      name: 'Outdoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 122,
    name: 'Mirrors',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 94,
    name: 'Outdoor Furniture',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 25,
    name: 'Art',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 233,
    name: 'Lanyards/Tethers',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 37,
    name: 'Jute',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 124,
      name: 'Rugs',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 72,
    name: 'Beds',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 124,
    name: 'Rugs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 7,
    name: 'Barstools',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 19,
      name: 'Indoor Furniture',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 19,
    name: 'Indoor Furniture',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 127,
    name: 'Musical Instruments',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 69,
    name: 'Pianos',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 132,
    name: 'Televisions',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 114,
      name: 'Special Props',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 114,
    name: 'Special Props',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 16,
    name: 'Bowls',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 120,
    name: 'Artificial Flowers',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 174,
    name: 'Baskets',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 55,
    name: 'Blankets',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 17,
    name: 'Containers',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 70,
    name: 'Drums',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 171,
    name: 'Duvet Cover',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 11,
    name: 'Floor Lamps',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 60,
    name: 'Florals',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 31,
    name: 'Lanterns',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 35,
    name: 'Objects',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 56,
    name: 'Pillows',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 177,
    name: 'Planters',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 57,
    name: 'Pots',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 54,
    name: 'Poufs',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 39,
    name: 'Sculptures',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 12,
    name: 'Table Lamps',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 176,
    name: 'Trays',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 93,
    name: 'Books',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 175,
    name: 'Candle Holders',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 90,
    name: 'Needs Verifications',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 216,
    name: 'Accessories',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 10,
    name: 'Floor Lamp',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: {
      id: 216,
      name: 'Accessories',
      min_quantity: 1,
      increment_quantity_by: 1,
      parent: null,
    },
  },
  {
    id: 265,
    name: 'Extra Items',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
  {
    id: 266,
    name: 'Test Category 1',
    min_quantity: 1,
    increment_quantity_by: 1,
    parent: null,
  },
];
