import React, { type PropsWithChildren } from 'react';
import styled, { css, type ThemeProps } from 'styled-components';

import Button, {
  type Props as ButtonProps,
} from 'js/components/partial/Button';
import { type Theme } from 'js/theme';

const RadioInput = styled.span`
  display: block;
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  border: 1px solid ${(props: ThemeProps<Theme>) => props.theme.colors.whiteTwo};
  border-radius: 50%;
  height: 14px;
  margin: 3px 9px 0 0;
  position: relative;
  width: 14px;
  min-width: 14px;
`;

interface ButtonStyledProps {
  checked?: boolean;
}

const ButtonStyled = styled(Button)<ButtonStyledProps>`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.brownishGrey};
  white-space: normal;

  &:hover {
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.brownishGrey};
  }

  &:disabled {
    cursor: auto;
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayLight};
  }

  ${(props) =>
    'checked' in props && props.checked
      ? css`
          color: ${(props: ThemeProps<Theme>) => props.theme.colors.dark};
          font-weight: 500;

          ${RadioInput} {
            border-color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.azure};

            &::before {
              background: ${(props: ThemeProps<Theme>) =>
                props.theme.colors.azure};
              border-radius: 50%;
              content: '';
              height: 8px;
              left: 50%;
              margin: -4px 0 0 -4px;
              position: absolute;
              top: 50%;
              width: 8px;
            }
          }
        `
      : ''};
`;

const Container = styled.div`
  display: flex;
`;

const Label = styled.span`
  display: block;
  text-align: left;
  max-width: 90%;
`;

type RadioButtonProps = Pick<
  ButtonProps,
  'className' | 'onClick' | 'disabled'
> &
  ButtonStyledProps;

export default function RadioButton({
  children,
  onClick,
  checked = false,
  disabled,
  className = '',
}: PropsWithChildren<RadioButtonProps>) {
  return (
    <ButtonStyled
      className={className}
      onClick={onClick}
      checked={checked}
      disabled={disabled}
      link
    >
      <Container>
        <RadioInput />
        <Label>{children}</Label>
      </Container>
    </ButtonStyled>
  );
}
