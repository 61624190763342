import styled, { type ThemeProps } from 'styled-components';
import { type Theme } from 'js/theme';

export const Container = styled.div`
  align-items: center;
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  top: 0;
  z-index: 1000;

  @media (min-width: ${(props: ThemeProps<Theme>) =>
      props.theme.flexboxgrid.breakpoints.md}em) {
    display: none;
  }
`;

export const Inner = styled.div`
  max-width: 300px;
`;

export const Title = styled.h3`
  font-size: 16px;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
`;
