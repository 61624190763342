import { Analytics } from '@segment/analytics-next';
import getConfig from 'next/config';
import { SentryService } from '@services/sentry_service';
import { SessionData } from '@services/session_service';
import { setupGtmGlobalVars } from './setupGtmGlobalVars';

const { publicRuntimeConfig } = getConfig();

const writeKey = publicRuntimeConfig.segmentWriteKey;

let analytics: Analytics | undefined;

export const mockAnalytics = {
  track: function (eventName: any, properties: any) {
    console.log('Tracking: ', { eventName, properties });
    return Promise.resolve({});
  },
  page: function () {
    console.log('Track Page', arguments);
  },
  identify: function () {
    console.log('Identifying', arguments);
  },
} as Analytics;

// ----------------------------------------
// Initialize
// ----------------------------------------
let initializePromise: Promise<string | void> | null = null;
let canInitialize = false;
let storedSessionData: SessionData | null = null;

function initialize(): Promise<any> | void {
  // remember that we can initialize segment
  canInitialize = true;
  // try to initializeSessionData if it exists
  if (storedSessionData) {
    return initializeSessionData(storedSessionData);
  }
}

function initializeSessionData(
  sessionData: SessionData
): Promise<string | void> {
  // if we cant initialize segment do nothing yet
  if (!canInitialize) {
    storedSessionData = sessionData;
    return Promise.resolve();
  }

  if (initializePromise) {
    return initializePromise;
  }

  setupGtmGlobalVars(sessionData);
  setupTrackingAttributes(sessionData);

  initializePromise = importSegment()
    .then((response) => {
      analytics = response;
      identifyUser(sessionData);
      fireMissedEvents();
    })
    .catch(SentryService.catchPromise('SegmentService > initialize'));

  return initializePromise;
}

function importSegment(): Promise<Analytics> {
  // if you want to disable segment on localhost, create a .env.local file and add this line:
  // NEXT_PUBLIC_DEV_DISABLE_SENTRY_SEGMENT=true
  if (process.env.NEXT_PUBLIC_DEV_DISABLE_SENTRY_SEGMENT === 'true') {
    analytics = mockAnalytics;
    return Promise.resolve(mockAnalytics);
  }

  return import('@segment/analytics-next')
    .then((module) => {
      return module.AnalyticsBrowser.load({
        writeKey,
      });
    })
    .then((response) => response[0]);
}

// ----------------------------------------
// Identify and tracking attributes
// ----------------------------------------
interface TrackingAttributes {
  // required for gtm
  group: 'gtm';
  'event-category': 'ecommerce' | 'engagement';

  // required for our tracking purposes
  tracking_mode: string;
  product_catalog_id: number;
  market_id: number | string;
  market_name: string;
  market_inferred: boolean;

  location_city?: string;
  location_country?: string;
  location_postal_code?: string;
  location_region?: string;

  [key: string]: any;
}
const missedTrackEvents: [string, Partial<TrackingAttributes>][] = [];
const missedPageEvents: [][] = [];

export let trackingAttributes: null | TrackingAttributes = null;
export function _resetTrackingAttributesForTesting() {
  analytics = undefined;
  trackingAttributes = null;
  initializePromise = null;
  storedSessionData = null;
  canInitialize = false;
}
function setupTrackingAttributes(sessionData: SessionData) {
  const { currentMarket, trackingMode, segmentLocation } = sessionData;

  trackingAttributes = {
    group: 'gtm',
    'event-category': 'engagement',
    tracking_mode: trackingMode
      ? trackingMode.consumerTrackingMode
        ? 'consumer'
        : 'admin'
      : 'consumer',
    product_catalog_id: currentMarket.facebookCatalogId,
    market_id: currentMarket.id,
    market_name: currentMarket.name,
    market_inferred: currentMarket.marketInferred,
  };
  if (segmentLocation) {
    trackingAttributes.location_region = segmentLocation.region;
    trackingAttributes.location_country = segmentLocation.country;
    trackingAttributes.location_city = segmentLocation.city;
    trackingAttributes.location_postal_code = segmentLocation.postal_code;
  }
}

function fireMissedEvents() {
  missedTrackEvents.forEach((event) => _trackEvent(event[0], event[1]));
  missedPageEvents.forEach(() => trackPage());

  missedTrackEvents.splice(0);
  missedPageEvents.splice(0);
}

function _identifyUser(sessionData: SessionData) {
  const { currentUser } = sessionData;

  var options = {};

  if (currentUser) {
    analytics?.identify(
      currentUser.id.toString(),
      { email: currentUser.email },
      options
    );
  }
}

/**
 * Identify User and set up all the tracking attributes necessary for the track and page events.
 *
 * Segment charges us per unique user so we want to make sure the anonymousId and currentUser id is set BEFORE sending any other segment events.
 * @param sessionData
 */
function identifyUser(sessionData: SessionData) {
  setupTrackingAttributes(sessionData);
  _identifyUser(sessionData);
}

function trackPage() {
  if (!trackingAttributes || !analytics) {
    missedPageEvents.push([]);
    return;
  }
  analytics.page(trackingAttributes);
}

function _trackEvent(action: string, data: Partial<TrackingAttributes>) {
  if (!trackingAttributes || !analytics) {
    missedTrackEvents.push([action, data]);
    return;
  }
  analytics
    .track(action, { ...trackingAttributes, ...data })
    .catch(SentryService.catchPromise('Segment Service > _trackEvent'));
}

/**
 * Use this to track events related to UI/UX.
 */
function trackEngagementEvent(action: string, data: object) {
  _trackEvent(action, {
    'event-category': 'engagement',
    ...data,
  });
}

function trackAdminEvent(action: string, data: object) {
  _trackEvent(action, {
    'event-category': 'engagement',
    ...data,
    tracking_mode: 'admin',
  });
}

/**
 * Use this to track non-critical errors.
 * @param pageContext This should tell us where the error came from. For example: The Navbar Search Bar or the IDP Widget A, etc.
 * @param error just pass the error object
 */
function trackError(pageContext: string, error: any) {
  _trackEvent('Frontend Error', {
    group: 'gtm',
    'event-category': 'engagement',
    pageContext,
    error: JSON.stringify(error),
  });
}

/**
 * Use this to track events related to the checkout funnel. Like Add to Cart and Proceed to Checkout
 */
function trackEcommerceEvent(action: string, data: object) {
  _trackEvent(action, {
    'event-category': 'ecommerce',
    ...data,
  });
}

export const SegmentService = {
  trackEngagementEvent,
  trackEcommerceEvent,
  trackAdminEvent,
  trackPage,
  initialize,
  initializeSessionData,
  identifyUser,
  trackError,
};
