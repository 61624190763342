import { forwardRequestHeaders } from '@services/forwardRequestHeaders';
import { HttpService } from '@services/http_service';
import getConfig from 'next/config';
import { SentryService } from './sentry_service';

const { publicRuntimeConfig } = getConfig();

/**
 * This sends a request to our server, which in turn sends a segment event from our server.
 *
 * Segment events sent from our server are much more reliable because many browsers block trackers like Segment. This method was created to track the main funnel users go through our site. An example funnel includes the following events:
 *
 * Website Visited
 * Website Clicked or Scrolled (helps with "bounce rate")
 * Product Added
 * Order Completed
 *
 * https://fernish.quip.com/dFnOAIHH5Udl/Website-Tracking-and-Bounce-Rate
 *
 * !Important: When testing this we want to make sure that the anonymous id of the various events above are all the same for the same session. That's the whole point of tracking a user's journey through the funnel on the server-side.
 *
 * !One way to ensure anonymous id is correct is to make sure sessionData is set BEFORE calling trackEvent. This ensures that the rails server had a chance to set a sessionId to the browser's cookie.
 *
 * TODO: maybe we can abstract this away and create a queue for trackEvents that are called before sessionData is set.
 */
function trackEvent(
  event: string,
  properties: object,
  headers?: typeof forwardRequestHeaders
) {
  if (process.env.NEXT_PUBLIC_DEV_MOCK_API_CALLS === 'true') {
    return Promise.resolve({});
  }

  return HttpService.post(
    // headers are present if this is a backend event.
    // server-side requests need a full url
    (headers ? publicRuntimeConfig.backendBaseUrl : '') +
      '/api/v1/public/webstats',
    {
      event,
      properties,
    },
    headers
  ).catch(SentryService.catchPromise('server-side trackEvent'));
}

export const ServerSideSegmentService = {
  trackEvent,
};
