import React from 'react';
import { type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { type ProjectItemWithNotes } from '..';
import { mockResponse } from 'js/api/mock/utils';
import { projectItemsForPackingListMock } from 'js/api/mock/projectItemsForPackingListMock';

/**
 * GET - /inventory/v1/items/items_for_packing_list
 */

// HTTP

export interface ProjectItemsForPackingListParams {
  projectId: number;
  perPage: number;
  page: number;
  deliveryDateselected?: string;
}

export interface ProjectItemsForPackingListConfig extends HttpBaseConfig {
  params: ProjectItemsForPackingListParams;
}

export interface ProjectItemsForPackingList {
  items: ProjectItemWithNotes[];
  totalCount: number;
}

export const getProjectItemsForPackingListXhr = (
  config: ProjectItemsForPackingListConfig
): Promise<ProjectItemsForPackingList> =>
  mockResponse(projectItemsForPackingListMock);
// http
//   .get<ProjectItemsForPackingList>(
//     `/inventory/v1/items/items_for_packing_list`,
//     config
//   )
//   .then((res) => res.data);

export const getProjectItemsForPackingListXhrRecursive = async (
  config: ProjectItemsForPackingListConfig
): Promise<ProjectItemsForPackingList> => {
  const { params } = config;

  const res = await getProjectItemsForPackingListXhr(config);

  const hasMore = res.totalCount > params.page * params.perPage;

  if (!hasMore) return res;

  const nextPage = await getProjectItemsForPackingListXhrRecursive({
    ...config,
    params: { ...params, page: params.page + 1 },
  });

  return {
    ...res,
    items: [...res.items, ...nextPage.items],
  };
};

// Query Key

const GET_PROJECT_ITEMS_FOR_PACKING_LIST_QUERY_KEY_NAMESPACE =
  'getProjectItemsForPackingList';

type GetProjectItemsForPackingListQueryKeyNamespace =
  typeof GET_PROJECT_ITEMS_FOR_PACKING_LIST_QUERY_KEY_NAMESPACE;

export type GetProjectItemsForPackingListQueryKey = [
  GetProjectItemsForPackingListQueryKeyNamespace,
  ProjectItemsForPackingListParams
];

export const createGetProjectItemsForPackingListQueryKey = (
  key: SkipFirst<GetProjectItemsForPackingListQueryKey>
): GetProjectItemsForPackingListQueryKey => [
  GET_PROJECT_ITEMS_FOR_PACKING_LIST_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getProjectItemsForPackingListQueryFn: QueryFunction<
  ProjectItemsForPackingList,
  GetProjectItemsForPackingListQueryKey
> = ({ queryKey: [_d, params], signal }) =>
  getProjectItemsForPackingListXhrRecursive({ signal, params });

// Query

export interface GetProjectItemsForPackingListQueryProps
  extends Omit<
    QueryProps<
      ProjectItemsForPackingList,
      unknown,
      ProjectItemsForPackingList,
      GetProjectItemsForPackingListQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params: ProjectItemsForPackingListParams;
}

export type GetProjectItemsForPackingListQueryResult = Parameters<
  GetProjectItemsForPackingListQueryProps['children']
>[0];

export const GetProjectItemsForPackingListQuery = ({
  params,
  ...props
}: GetProjectItemsForPackingListQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProjectItemsForPackingListQueryKey([params])}
      queryFn={getProjectItemsForPackingListQueryFn}
    />
  );
};
