import { type ComponentType } from 'react';
import { type SkipFirst } from 'js/types';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  createGetRoomsQueryKey,
  type Project,
  type Room,
  type RoomTypeForUpdate,
  type WithMutationPropsByMutation,
  type GetRoomsQueryKey,
  type GetRoomsXhr,
  invalidateGetRoomsQueryData,
} from 'js/api';
import { Item } from '@interfaces/item.interface';

/**
 * PUT - /api/v1/eames/projects/:project_id/rooms/:id
 */

// HTTP

export interface UpdateRoomXhrVariables {
  roomId: Room['id'];
  projectId: Project['id'];
  body: UpdateRoomXhrBody;
}

export interface UpdateRoomXhrBody extends Partial<Pick<Room, 'name'>> {
  // @TODO is project id is needed
  projectId?: Project['id'];
  roomType?: RoomTypeForUpdate;
  itemAssign?: {
    id: Item['id'];
    quantity: number;
  };
  itemUpdate?: {
    id: Item['id'];
    quantity: number;
  };
  moveItems?: {
    id: Item['id'];
    quantity: number;
    roomId: number;
  }[];
}

export type UpdateRoomXhrResponse = Room;

export const updateRoomXhr = ({
  projectId,
  roomId,
  body,
}: UpdateRoomXhrVariables): Promise<Room> =>
  http
    .put<UpdateRoomXhrResponse>(
      `/api/v1/eames/projects/${projectId}/rooms/${roomId}`,
      body
    )
    .then((res) => res.data);

// MutationFn

type UpdateRoomMutation = MutationFunction<Room, UpdateRoomXhrVariables>;

export const updateRoomMutationFn: UpdateRoomMutation = (variables) =>
  updateRoomXhr(variables);

// Update cache
// @TODO Update cahe of Items according to add new one
export const updateCacheForUpdateRoomMutation = (
  client: QueryClient,
  updatedRoom: Awaited<ReturnType<typeof updateRoomXhr>>,
  keyParams: SkipFirst<GetRoomsQueryKey>
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      const index = data?.findIndex((room) => room.id === updatedRoom.id);
      if (!data || index === -1 || index == null) return data;

      const updatedData = [...data];
      updatedData[index] = { ...updatedRoom };
      return updatedData;
    }
  );
};

// With Mutation HOC

const withUpdateRoomMutationPropKey = 'updateRoomMutation';

export type WithUpdateRoomMutationProps = WithMutationPropsByMutation<
  typeof withUpdateRoomMutationPropKey,
  UpdateRoomMutation
>;

export function withUpdateRoomMutation<P extends WithUpdateRoomMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateRoomMutationProps>> {
  return withMutation(
    withUpdateRoomMutationPropKey,
    updateRoomMutationFn,
    (client) => ({
      onSuccess: async (updatedRoom, { projectId }) => {
        updateCacheForUpdateRoomMutation(client, updatedRoom, [projectId]);
        await invalidateGetRoomsQueryData(client, [projectId]);
      },
    })
  )(Component as ComponentType<WithUpdateRoomMutationProps>);
}
