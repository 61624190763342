export const projectItemsForPackingListMock = {
  success: true,
  items: [
    {
      item_id: 152960,
      delivery_dateselected: '3',
      delivery_day: '2022/06/21',
      room_id: 23538,
      room_name: 'Unassigned',
      room_type: 'Unassigned',
      product_name: 'test 2-new2',
      product_sku: 'HH-023638',
      room_product_item_id: 576770,
      item_status: 'ready_to_pick',
      thumbnail_url: null,
    },
    {
      item_id: 153099,
      delivery_dateselected: '3',
      delivery_day: '2022/06/21',
      room_id: 23538,
      room_name: 'Unassigned',
      room_type: 'Unassigned',
      product_name: '22change name',
      product_sku: 'HH-023659',
      room_product_item_id: 576909,
      item_status: 'on_site',
      thumbnail_url: null,
    },
    {
      item_id: 153100,
      delivery_dateselected: '3',
      delivery_day: '2022/06/21',
      room_id: 23538,
      room_name: 'Unassigned',
      room_type: 'Unassigned',
      product_name: '22change name',
      product_sku: 'HH-023659',
      room_product_item_id: 576910,
      item_status: 'ready_to_pick',
      thumbnail_url: null,
    },
  ],
  total_count: 3,
};
