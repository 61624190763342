import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  createGetRoomsQueryKey,
  GetRoomsQueryKey,
  GetRoomsXhr,
  http,
  withMutation,
  type Project,
  type WithMutationPropsByMutation,
} from 'js/api/';
import { invalidateGetProjectNotesQueryData } from '../';
import { SkipFirst } from 'js/types';

/**
 * DELETE - /api/v1/eames/eames_notes/:id
 */

// HTTP

interface DeleteProjectNoteXhrVariables {
  noteId: number;
  projectId: Project['id'];
}

export const deleteProjectNoteXhr = ({
  noteId,
  projectId,
}: DeleteProjectNoteXhrVariables): Promise<never> =>
  http
    .delete<never>(`/api/v1/eames/eames_notes/${noteId}`, {
      params: {
        projectId,
      },
    })
    .then((res) => res.data);

// MutationFn

type DeleteProjectNoteMutation = MutationFunction<
  never,
  DeleteProjectNoteXhrVariables
>;

export const deleteProjectNoteMutationFn: DeleteProjectNoteMutation = (
  variables
) => deleteProjectNoteXhr(variables);

// With Mutation HOC

const withDeleteProjectNoteMutationPropKey = 'deleteProjectNoteMutation';

export type WithDeleteProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withDeleteProjectNoteMutationPropKey,
  DeleteProjectNoteMutation
>;

export function withDeleteProjectNoteMutation<
  P extends WithDeleteProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteProjectNoteMutationProps>> {
  return withMutation(
    withDeleteProjectNoteMutationPropKey,
    deleteProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId, noteId }) => {
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
        await updateCacheForDeleteItemNoteMutation(client, noteId, [
          projectId,
          { showItems: true },
        ]);
      },
    })
  )(Component as ComponentType<WithDeleteProjectNoteMutationProps>);
}

export const updateCacheForDeleteItemNoteMutation = (
  client: QueryClient,
  noteId: number,
  keyParams: SkipFirst<GetRoomsQueryKey>
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      let indexItem: number = -1;
      const index = data?.findIndex((room) => {
        indexItem = room.items.findIndex(
          (item) => item.eamesNote?.id === noteId
        );
        if (indexItem !== -1) return true;
        return false;
      });

      if (!data || index === -1 || index == null || indexItem === -1)
        return data;

      const updatedData = [...data];
      const updatedRoomItem = { ...updatedData[index].items[indexItem] };
      updatedRoomItem.eamesNote = null;
      updatedData[index].items[indexItem] = updatedRoomItem;
      return updatedData;
    }
  );
};
