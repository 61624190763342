/* ----- SELECTOR HELPERS ----- */

import { type Room, RoomType } from '.';
import { RoomItem } from '../items';
import { type Option } from 'js/components/partial/Forms/Select';
import { type Note } from '../notes';

export type NormailezedRooms = Record<Room['id'], Room>;
export interface RoomItemWithRoomName extends RoomItem {
  roomId: number;
  roomName: string;
  roomType: RoomType;
}

/**
 * Extract item list room data, add room meta into each item element
 *
 * @param {Object} room - Room data structure
 * @returns {Array}
 */
function getRoomProducts(room: Room): RoomItemWithRoomName[] {
  if (!room?.items) return [];
  return room.items.map((item) => ({
    ...item,
    roomId: room.id,
    roomName: room.name,
    roomType: room.roomType,
  }));
}

/* ----- SELECTORS ----- */

/**
 * Return item list (reservations) for the currently 'selectedRoomId' (or all rooms)
 */
export const roomItemsSelector = ({
  all,
  selectedRoomId,
}: {
  all: NormailezedRooms;
  selectedRoomId?: Room['id'];
}) => {
  return selectedRoomId
    ? (all[selectedRoomId] && getRoomProducts(all[selectedRoomId])) || []
    : Object.values(all).reduce<ReturnType<typeof getRoomProducts>>(
        (accum, room) => accum.concat(getRoomProducts(room)),
        []
      );
};

/**
 * Get  'Unassigned' room's id
 */
export const unassignedRoomIdSelector = (
  rooms: NormailezedRooms
): Room['id'] => {
  return (
    Object.values(rooms).find((room) => room.roomType === RoomType.Unassigned)
      ?.id || 0
  );
};

/**
 * Return all items ( reservations ) from all rooms
 */
export const allItemsSelector = (all: NormailezedRooms) =>
  Object.values(all).reduce<Record<RoomItem['id'], RoomItemWithRoomName>>(
    (accum, room) => {
      room.items.forEach((item) => {
        accum[item.id] = {
          ...item,
          roomId: room.id,
          roomName: room.name,
          roomType: room.roomType,
        };
      });
      return accum;
    },
    {}
  );

/**
 * Return an options list for the quantity dropdown in MoveProductModal, all room items
 */
export const itemsQtyOptionsSelector = (
  item: RoomItemWithRoomName
): Option<number>[] =>
  Array(item.quantityInRoom || 0)
    .fill(true)
    .reduce(
      (accum: Option<number>[], _, i) =>
        accum.concat({ label: String(i + 1), value: i + 1 }),
      [{ label: 'Select Quantity', value: 0 }]
    );
