import { SessionData } from '@services/session_service';

/**
 * This needs to be called BEFORE segment is initialized because our Google Tag Manager configuration expects various global variables to be set before it initializes on our page.
 */
export function setupGtmGlobalVars(sessionData: SessionData) {
  setupTrackingMode(sessionData);
  setupCurrentUser(sessionData);
  setupCurrentMarketId(sessionData);
  setupFacebookExternalId(sessionData);
}

function setupTrackingMode(sessionData: SessionData) {
  let trackingMode: string;
  if (
    !sessionData.trackingMode ||
    sessionData.trackingMode.consumerTrackingMode
  ) {
    trackingMode = 'consumer';
  } else {
    trackingMode = '';
  }

  (window as any).tracking_mode = trackingMode;
}

function setupCurrentUser(sessionData: SessionData) {
  if (sessionData.currentUser) {
    (window as any).user_id = sessionData.currentUser.id;
  }
}

function setupCurrentMarketId(sessionData: SessionData) {
  (window as any).current_market_id = sessionData.currentMarket.id;
}

function setupFacebookExternalId(sessionData: SessionData) {
  if (sessionData.currentUser) {
    (window as any).facebook_external_id = sessionData.currentUser.id;
  } else {
    (window as any).facebook_external_id = sessionData.anonymousId;
  }
}
