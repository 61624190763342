import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  createGetProductQueryKey,
  type WithMutationPropsByMutation,
  type Product,
  type SingleProduct,
} from 'js/api/';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - /inventory/v1/products/:id/remove_image
 */

// HTTP

interface DeleteProductImageXhrVariables {
  productId: Product['id'];
  url: string;
}

export const deleteProductImageXhr = ({
  productId,
  url,
}: DeleteProductImageXhrVariables): Promise<unknown> => mockResponse({});
// http.delete<unknown>(`/inventory/v1/products/${productId}/remove_image`, {
//   params: {
//     product_id: productId,
//     url: url,
//   },
// });

// MutationFn

type DeleteProductImageMutation = MutationFunction<
  unknown,
  DeleteProductImageXhrVariables
>;

export const deleteProductImageMutationFn: DeleteProductImageMutation = (
  variables
) => deleteProductImageXhr(variables);

// Update cache

export const updateProductCacheForDeleteProductImageMutation = (
  client: QueryClient,
  productId: Product['id'],
  url: string
) => {
  if (!productId) return;

  // client.setQueriesData<SingleProduct | undefined>(
  //   createGetProductQueryKey([productId]),
  //   (data) => {
  //     if (!data) return data;

  //     const updatedImages = data.images.map((img) => {
  //       if (img.url === url) {
  //         return {
  //           imageType: img.imageType,
  //           url: null,
  //         };
  //       }
  //       return img;
  //     });

  //     const updatedData = { ...data, images: updatedImages };
  //     return updatedData;
  //   }
  // );
};

// With Mutation HOC

const withDeleteProductImageMutationPropKey = 'deleteProductImageMutation';

export type WithDeleteProductImageMutationProps = WithMutationPropsByMutation<
  typeof withDeleteProductImageMutationPropKey,
  DeleteProductImageMutation
>;

export function withDeleteProductImageMutation<
  P extends WithDeleteProductImageMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeleteProductImageMutationProps>> {
  return withMutation(
    withDeleteProductImageMutationPropKey,
    deleteProductImageMutationFn,
    (client) => ({
      onSuccess: (_res, { productId, url }) => {
        updateProductCacheForDeleteProductImageMutation(client, productId, url);
      },
    })
  )(Component as ComponentType<WithDeleteProductImageMutationProps>);
}
