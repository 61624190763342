import React from 'react';
import Head from 'next/head';
import { Col, Grid, Row } from 'js/components/partial/FlexboxGrid';

import LoginForm from './LoginForm';
import { Content, IconChairStyled, Title, CaptionTextStyled } from './styled';

import theme from 'js/theme';
import { track, TRACKED_PAGES } from 'js/utils/analytics';

export default class Login extends React.Component {
  override componentDidMount() {
    track(TRACKED_PAGES.LOGIN);
  }

  override render() {
    return (
      <section>
        <Head>
          <title>Vesta - Login</title>
        </Head>
        <Content>
          <Grid>
            <Row>
              <Col xs={12} md={4} mdOffset={4}>
                <IconChairStyled color={theme.colors.darkGreyBlue} />
                <Title>Eames</Title>
                <LoginForm />
                <CaptionTextStyled>by Vesta Home</CaptionTextStyled>
              </Col>
            </Row>
          </Grid>
        </Content>
      </section>
    );
  }
}
