export const lCategoriesMock = {
  l1_categories: [
    {
      id: 15,
      name: 'Indoor',
      status: 'active',
    },
    {
      id: 16,
      name: 'Outdoor',
      status: 'active',
    },
  ],
  l2_categories: [
    {
      id: 105,
      name: 'Seating',
      status: 'active',
      parent_id: 16,
    },
    {
      id: 106,
      name: 'Tables',
      status: 'active',
      parent_id: 16,
    },
    {
      id: 107,
      name: 'Accessories',
      status: 'active',
      parent_id: 16,
    },
  ],
  l3_categories: [
    {
      id: 460,
      name: 'Dining Tables',
      status: 'active',
      parent_id: 106,
    },
    {
      id: 461,
      name: 'Coffee tables',
      status: 'active',
      parent_id: 106,
    },
    {
      id: 462,
      name: 'Side tables & accent tables',
      status: 'active',
      parent_id: 106,
    },
    {
      id: 463,
      name: 'Bar Tables',
      status: 'active',
      parent_id: 106,
    },
  ],
  l4_categories: [],
};
