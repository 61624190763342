import React, { useMemo } from 'react';
import {
  type UseQueryOptions,
  type QueryFunction,
  type QueryClient,
} from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type GetAllUsersXhr } from '..';

/**
 * GET - /api/v1/eames/all_managers
 */

// HTTP

export type GetAllUsersXhrResponse = GetAllUsersXhr;

export const getAllUsersXhr = (
  config: HttpBaseConfig = {}
): Promise<GetAllUsersXhr> =>
  http
    .get<GetAllUsersXhrResponse>(`/api/v1/eames/users/all_managers`, config)
    .then((res) => res.data);

// Query Key

const GET_ALL_USERS_QUERY_KEY_NAMESPACE = 'getAllUsers';

type GetAllUsersQueryKeyNamespace = typeof GET_ALL_USERS_QUERY_KEY_NAMESPACE;

export type GetAllUsersQueryKey = [GetAllUsersQueryKeyNamespace];

export const createGetAllUsersQueryKey = (): GetAllUsersQueryKey => [
  GET_ALL_USERS_QUERY_KEY_NAMESPACE,
];

// QueryFn

export const getAllUsersQueryFn: QueryFunction<
  GetAllUsersXhr,
  GetAllUsersQueryKey
> = ({ signal }) => getAllUsersXhr({ signal });

// Query Options

export type GetAllUsersQueryOptions = UseQueryOptions<
  GetAllUsersXhr,
  unknown,
  GetAllUsersXhr,
  GetAllUsersQueryKey
>;

// Query

export type GetAllUsersQueryProps = Omit<
  QueryProps<GetAllUsersXhr, unknown, GetAllUsersXhr, GetAllUsersQueryKey>,
  'queryFn' | 'queryKey'
>;

export type GetAllUsersQueryRenderFn = GetAllUsersQueryProps['children'];

export type GetAllUsersQueryResult = Parameters<GetAllUsersQueryRenderFn>[0];

export const GetAllUsersQuery = (props: GetAllUsersQueryProps) => {
  const queryKey = useMemo(createGetAllUsersQueryKey, []);
  return <Query {...props} queryKey={queryKey} queryFn={getAllUsersQueryFn} />;
};

// Query Cache Helpers

export const invalidateAllUsersQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_ALL_USERS_QUERY_KEY_NAMESPACE);
};
