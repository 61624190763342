import React from 'react';

import { toClassNameObj } from 'js/utils';

interface Props {
  className?: string;
  color?: string;
}

function BulletIcon({ className = '', color = '#009cff' }: Props) {
  return (
    <svg
      {...toClassNameObj(className)}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="7"
      viewBox="0 0 11 7"
    >
      <path
        className="color"
        fill={color}
        fillRule="evenodd"
        d="M5.586 3.828L2.634.877A1 1 0 0 0 1.22 2.29l3.66 3.66c.194.194.45.291.704.292a.987.987 0 0 0 .707-.291L9.95 2.29A1 1 0 0 0 8.539.877L5.586 3.828z"
      />
    </svg>
  );
}

export default BulletIcon;
