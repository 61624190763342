import { type KeysToCamelCase } from 'js/types';
import { type Option } from 'js/components/partial/Forms/Select';
import { TimestamptsRaw } from '../helpers';

export enum TagType {
  PROJECT_STYLE = 'project_style',
  ITEM_TYPE = 'filter', //'secondary_item_type',
  COLOR_FAMILY = 'color_family',
  ROOM_TYPE = 'room_type',
  MATERIAL = 'material',
  STYLE = 'style',
  BED_SIZE = 'mattress_bed_size',
  RUG_SIZE = 'rug_size',
  BRAND = 'brand',
}

export interface TagRaw extends TimestamptsRaw {
  id: number;
  tag_type_id: number;
  code_dependency: null;
  description: string | null;
  display_value: string | null;
  slug: string | null;
  value: string;
}

export type Tag = KeysToCamelCase<TagRaw>;

export type TagBaseRaw = Pick<TagRaw, 'id' | 'value' | 'display_value'>;

export type TagBase = KeysToCamelCase<TagBaseRaw>;

export type TagMultiSelectOption = Option<TagRaw['id']>;
