import React from 'react';
import { QueryClient, UseQueryOptions, type QueryFunction } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type ItemType } from '../';

/**
 * GET - /api/v1/eames/item_types
 */

// HTTP

export interface GetItemTypesXhr {
  data: ItemType[];
}

export interface GetItemTypesXhrParams {
  onlyActive?: boolean;
}

export interface GetItemTypesXhrConfig extends HttpBaseConfig {
  params?: GetItemTypesXhrParams;
}

export const getItemTypesXhr = (
  config: GetItemTypesXhrConfig = {}
): Promise<ItemType[]> =>
  http
    .get<ItemType[]>(`/api/v1/eames/item_types`, config)
    .then((res) => res.data);

// Query Key

const GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE = 'getItemTypes';

type GetItemTypesQueryKeyNamespace =
  typeof GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE;

export type GetItemTypesQueryKey = [
  GetItemTypesQueryKeyNamespace,
  GetItemTypesXhrParams? /* params */
];

export const createGetItemTypesQueryKey = (
  key: SkipFirst<GetItemTypesQueryKey>
): GetItemTypesQueryKey => [GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetItemTypesQueryFn = QueryFunction<ItemType[], GetItemTypesQueryKey>;

export const getItemTypesQueryFn: GetItemTypesQueryFn = ({
  queryKey: [_d, params],
  signal,
}) => getItemTypesXhr({ params, signal });

// create Query Options

export type GetItemTypesQueryOptions = UseQueryOptions<
  ItemType[],
  unknown,
  ItemType[],
  GetItemTypesQueryKey
>;

export interface GetItemTypesQueryOptionsProps {
  params?: GetItemTypesXhrParams;
  options?: Omit<GetItemTypesQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetItemTypesQueryOptions = ({
  params,
  options,
}: GetItemTypesQueryOptionsProps): GetItemTypesQueryOptions => ({
  queryFn: getItemTypesQueryFn,
  queryKey: createGetItemTypesQueryKey([params]),
  ...options,
});

// Query

export interface GetItemTypesQueryProps
  extends Omit<
    QueryProps<ItemType[], unknown, ItemType[], GetItemTypesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetItemTypesXhrParams;
}

export type GetItemTypesQueryRenderFn = GetItemTypesQueryProps['children'];

export type GetItemTypesQueryResult = Parameters<GetItemTypesQueryRenderFn>[0];

export const GetItemTypesQuery = ({
  params,
  ...props
}: GetItemTypesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetItemTypesQueryKey([params])}
      queryFn={getItemTypesQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateItemTypesQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE);
};
