import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /v1/users/password_reset
 */

// HTTP

export interface ResetUserPasswordBody {
  email?: string;
}

export interface ResetUserPasswordXhrVariables {
  body: ResetUserPasswordBody;
}

export const resetUserPasswordXhr = ({
  body,
}: ResetUserPasswordXhrVariables): Promise<unknown> => mockResponse({});
// http.post<unknown>(`/v1/users/password_reset`, body);

// MutationFn

type ResetUserPasswordMutation = MutationFunction<
  unknown,
  ResetUserPasswordXhrVariables
>;

export const resetUserPasswordMutationFn: ResetUserPasswordMutation = (
  variables
) => resetUserPasswordXhr(variables);

// With Mutation HOC

const withResetUserPasswordMutationPropKey = 'resetUserPasswordMutation';

export type WithResetUserPasswordMutationProps = WithMutationPropsByMutation<
  typeof withResetUserPasswordMutationPropKey,
  ResetUserPasswordMutation
>;

export function withResetUserPasswordMutation<
  P extends WithResetUserPasswordMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithResetUserPasswordMutationProps>> {
  return withMutation(
    withResetUserPasswordMutationPropKey,
    resetUserPasswordMutationFn
  )(Component as ComponentType<WithResetUserPasswordMutationProps>);
}
