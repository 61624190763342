import React, { type HTMLAttributes, type ReactNode } from 'react';
import styled, { type ThemeProps } from 'styled-components';

import { type Theme } from 'js/theme';

const CheckboxWrapper = styled.div`
  display: flex;
`;

const CheckboxTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  color: rgb(99, 108, 126);
  text-transform: capitalize;
`;

const CheckboxStyled = styled.input`
  background: none;
  color: ${(props: ThemeProps<Theme> & Props) =>
    props.theme.colors[props.checked ? 'azure' : 'brownishGrey']};
  font-weight: 400;
  height: auto;
  margin-left: 5px;
  margin-bottom: 4px;
  padding: 2px 0 3px 23px;
  position: relative;
  text-align: left;
  white-space: normal;
  width: 20px;
  height: 20px;

  &:hover {
    color: ${(props: ThemeProps<Theme> & Props) =>
      props.theme.colors[props.checked ? 'azureHover' : 'brownishGreyHover']};
  }
`;

interface Props
  extends Pick<HTMLAttributes<HTMLInputElement>, 'onClick' | 'className'> {
  checked?: boolean;
  title?: ReactNode;
  disabled?: boolean;
}

function Checkbox({
  checked = false,
  className = '',
  onClick,
  title = '',
  disabled,
}: Props) {
  return (
    <CheckboxWrapper>
      <CheckboxTitle>{title}</CheckboxTitle>
      <CheckboxStyled
        className={className}
        onClick={onClick}
        onChange={() => null}
        checked={checked}
        type="checkbox"
        disabled={disabled}
      />
    </CheckboxWrapper>
  );
}

export default Checkbox;
