import axios, { type AxiosRequestHeaders } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

import { API_URL, APP_NAME } from 'js/config';

import { HttpException } from './httpException';
import { fetchHeaders } from 'js/utils';

export type HttpBaseConfig = { signal?: AbortSignal };

export const http = axios.create({
  baseURL: API_URL,
  headers: {
    'X-Application': APP_NAME,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  const { headers = {}, data: camelizeData, params } = config;

  return {
    ...config,
    headers: {
      ...fetchHeaders(),
      ...headers,
    },
    params: params
      ? decamelizeKeys(params as Record<string, unknown>)
      : undefined,
    data: camelizeData
      ? decamelizeKeys(camelizeData as Record<string, unknown>)
      : undefined,
  };
});

http.interceptors.response.use(
  (res) => {
    if (res.data) res.data = camelizeKeys(res.data);

    return res;
  },
  (err) => {
    throw new HttpException(err);
  }
);
