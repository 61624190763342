import { useEffect, useState } from 'react';
import { getCSRFFromLocalStorage } from 'js/utils';
import Login from 'js/components/Login';
import { useRouter } from 'next/router';
import { PUBLIC_ROUTES } from 'js/config';
import MobileNotice from 'js/components/MobileNotice';

export function AuthGuard({ children }: { children: JSX.Element }) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  useEffect(() => {
    const csrf = getCSRFFromLocalStorage();
    setAuthorized(!!csrf);
    setLoading(false);
  });
  const isPublic = PUBLIC_ROUTES.some((publicRoute) =>
    router.route.startsWith(publicRoute)
  );

  if (loading) return null;
  if (isPublic || authorized) return children;
  return (
    <div>
      <MobileNotice />
      <Login />
    </div>
  );
}
