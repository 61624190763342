import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProductTypesQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/product_types
 */

// HTTP

export interface CreateProductTypeXhrBody {
  name: string;
}

export const createProductTypeXhr = (
  body: CreateProductTypeXhrBody
): Promise<unknown> => mockResponse({});
// http.post<unknown>(`/inventory/v1/product_types`, body);

// MutationFn

type CreateProductTypeMutation = MutationFunction<
  unknown,
  CreateProductTypeXhrBody
>;

export const createProductTypeMutationFn: CreateProductTypeMutation = (body) =>
  createProductTypeXhr(body);

// With Mutation HOC

const withCreateProductTypeMutationPropKey = 'createProductTypeMutation';

export type WithCreateProductTypeMutationProps = WithMutationPropsByMutation<
  typeof withCreateProductTypeMutationPropKey,
  CreateProductTypeMutation
>;

export function withCreateProductTypeMutation<
  P extends WithCreateProductTypeMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateProductTypeMutationProps>> {
  return withMutation(
    withCreateProductTypeMutationPropKey,
    createProductTypeMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductTypesQueryData(client);
      },
    })
  )(Component as ComponentType<WithCreateProductTypeMutationProps>);
}
