import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateProjectQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/projects/:id/update_walk_through
 */

// HTTP

interface UpdateProjectWalkThroughDateXhrBody {
  walkThrough: string;
}

interface UpdateProjectWalkThroughDateXhrVariables {
  projectId: number;
  params: UpdateProjectWalkThroughDateXhrBody;
}

export const updateProjectWalkThroughDateXhr = ({
  projectId,
  params,
}: UpdateProjectWalkThroughDateXhrVariables): Promise<unknown> =>
  mockResponse({});
// http.put<unknown>(
//   `/inventory/v1/projects/${projectId}/update_walk_through`,
//   null,
//   {
//     params,
//   }
// );

// MutationFn

type UpdateProjectWalkThroughDateMutation = MutationFunction<
  unknown,
  UpdateProjectWalkThroughDateXhrVariables
>;

export const updateProjectWalkThroughDateMutationFn: UpdateProjectWalkThroughDateMutation =
  (variables) => updateProjectWalkThroughDateXhr(variables);

// With Mutation HOC

const withUpdateProjectWalkThroughDateMutationPropKey =
  'updateProjectWalkThroughDateMutation';

export type WithUpdateProjectWalkThroughDateMutationProps =
  WithMutationPropsByMutation<
    typeof withUpdateProjectWalkThroughDateMutationPropKey,
    UpdateProjectWalkThroughDateMutation
  >;

export function withUpdateProjectWalkThroughDateMutation<
  P extends WithUpdateProjectWalkThroughDateMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectWalkThroughDateMutationProps>> {
  return withMutation(
    withUpdateProjectWalkThroughDateMutationPropKey,
    updateProjectWalkThroughDateMutationFn,
    (client) => ({
      onSuccess: async (_, { projectId }) => {
        await invalidateProjectQueryData(client, projectId);
      },
    })
  )(Component as ComponentType<WithUpdateProjectWalkThroughDateMutationProps>);
}
