import React, { type ButtonHTMLAttributes } from 'react';
import Link from 'next/link';
import styled, { css, type ThemeProps } from 'styled-components';

import { type Theme } from 'js/theme';
import { tooltipStyles } from 'js/components/styled';

import { type ButtonBaseProps } from './';

enum ButtonVariant {
  Default = 'DEFAULT',
  Cta = 'CTA',
  Danger = 'DANGER',
  Disabled = 'DISABLED',
  Link = 'LINK',
}

const TEXT_STYLES = {
  [ButtonVariant.Default]: css`
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};

    &:hover {
      color: ${(props: ThemeProps<Theme>) => props.theme.colors.azureHover};
    }
  `,
  [ButtonVariant.Cta]: css`
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};

    &:hover {
      color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
    }
  `,
  [ButtonVariant.Danger]: css`
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};

    &:hover {
      color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
    }
  `,
  [ButtonVariant.Disabled]: css`
    color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayMid};
  `,
};

const STYLES = {
  [ButtonVariant.Default]: css`
    ${TEXT_STYLES.DEFAULT};

    background-color: ${(props: ThemeProps<Theme>) =>
      props.theme.colors.azureLight};

    &:hover {
      background-color: ${(props: ThemeProps<Theme>) =>
        props.theme.colors.azureLightHover};
    }
  `,
  [ButtonVariant.Cta]: css`
    ${TEXT_STYLES.CTA};

    background-color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};

    &:hover {
      background-color: ${(props: ThemeProps<Theme>) =>
        props.theme.colors.azureHover};
    }
  `,
  [ButtonVariant.Danger]: css`
    ${TEXT_STYLES.DANGER};

    background-color: ${(props: ThemeProps<Theme>) =>
      props.theme.colors.danger};

    &:hover {
      background-color: ${(props: ThemeProps<Theme>) =>
        props.theme.colors.dangerDark};
    }
  `,
  [ButtonVariant.Disabled]: css`
    ${TEXT_STYLES.DISABLED};

    background-color: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
    border-color: ${(props: ThemeProps<Theme>) =>
      props.theme.colors.grayXLight};
    cursor: not-allowed;
  `,
  LINK: css`
    ${TEXT_STYLES.DEFAULT};

    padding: 0;
    background: none;
    text-transform: capitalize;

    &:disabled {
      color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayMid};
      cursor: auto;
    }
  `,
};

const getButtonStyles = (
  props: ButtonBaseProps
): ReturnType<typeof css> => css<ButtonBaseProps>`
  border-radius: 3px;
  border: 1px solid transparent;
  cursor: pointer;
  display: ${props.block ? 'block' : 'inline-block'};
  font-size: 14px;
  font-weight: ${(props: ButtonBaseProps) =>
    props.link ? 400 : props.small ? 300 : 500};
  height: ${(props: ButtonBaseProps) =>
    props.link ? 'auto' : `${props.small ? 30 : 40}px`};
  line-height: 1.42857143;
  outline: 0;
  padding: ${(props: ButtonBaseProps) =>
    props.small ? '4px 10px' : '10px 15px'};
  position: relative;
  text-align: center;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;

  &.delete {
    background: #de3939;
    color: #fff;

    &:hover {
      background: #de3939;
      color: #fff;
    }
  }

  &.save-images {
    position: absolute;
    right: 30px;
    bottom: 20px;
  }

  ${props.block
    ? css`
        width: 100%;
      `
    : ''};

  ${() => {
    if (props.link) return STYLES.LINK;
    if (props.disabled) return STYLES.DISABLED;
    if (props.danger) return STYLES.DANGER;
    if (props.cta) return STYLES.CTA;
    return STYLES.DEFAULT;
  }};

  ${LinkStyled} {
    ${() => {
      if (props.disabled) return TEXT_STYLES.DISABLED;
      if (props.danger) return TEXT_STYLES.DANGER;
      if (props.cta) return TEXT_STYLES.CTA;
      return TEXT_STYLES.DEFAULT;
    }};
  }

  ${props.styles ? `${props.styles as string}` : ''};

  ${props.tooltip ? tooltipStyles(props.tooltip) : ''};
`;

/**
 * ButtonStyled
 */

export type ButtonProps = ButtonBaseProps &
  Pick<ButtonHTMLAttributes<unknown>, 'type'>;

export const ButtonStyled = styled.button<ButtonProps>`
  ${(props) => getButtonStyles(props)};
`;

/**
 * LinkStyled
 */

export interface LinkStyledButtonProps extends ButtonBaseProps {
  to: string;
}

export const LinkStyled = styled(
  ({
    block,
    cta,
    danger,
    disabled,
    link,
    small,
    styles,
    to: href,
    ...rest
  }: LinkStyledButtonProps) => <Link href={href} {...rest} />
)`
  ${(props) => getButtonStyles(props)};
`;

export interface AnchorStyledButtonProps extends ButtonBaseProps {
  href: string;
}

export const AnchorStyled = ButtonStyled.withComponent('a');
