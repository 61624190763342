import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  GetRoomsQueryKey,
  GetRoomsXhr,
  Project,
  createGetRoomsQueryKey,
  http,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  invalidateGetProjectNotesQueryData,
  type ProjectNote,
  type EamesNoteImagesUpdateParams,
} from '../';
import { SkipFirst } from 'js/types';

/**
 * PUT - /api/v1/eames/eames_notes/:id
 */

// HTTP

export type EditProjectNoteBody = {
  body: string;
  imageParams?: EamesNoteImagesUpdateParams;
};

export interface UpdateProjectNoteXhrVariables {
  noteId: number;
  body: EditProjectNoteBody;
  projectId: Project['id'];
  roomId?: number;
  itemId?: number;
  progressCallBack?: (progress: number) => void;
}

export const updateProjectNoteXhr = ({
  noteId,
  body,
  roomId,
  itemId,
}: UpdateProjectNoteXhrVariables): Promise<ProjectNote> =>
  http
    .put<ProjectNote>(`/api/v1/eames/eames_notes/${noteId}`, {
      ...body,
      ...(roomId ? { roomId } : {}),
      ...(itemId ? { itemId } : {}),
    })
    .then((res) => res.data);

// MutationFn

type UpdateProjectNoteMutation = MutationFunction<
  ProjectNote,
  UpdateProjectNoteXhrVariables
>;

export const updateProjectNoteMutationFn: UpdateProjectNoteMutation = (
  variables
) => updateProjectNoteXhr(variables);

// With Mutation HOC

const withUpdateProjectNoteMutationPropKey = 'updateProjectNoteMutation';

export type WithUpdateProjectNoteMutationProps = WithMutationPropsByMutation<
  typeof withUpdateProjectNoteMutationPropKey,
  UpdateProjectNoteMutation
>;

export function withUpdateProjectNoteMutation<
  P extends WithUpdateProjectNoteMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProjectNoteMutationProps>> {
  return withMutation(
    withUpdateProjectNoteMutationPropKey,
    updateProjectNoteMutationFn,
    (client) => ({
      onSuccess: async (result, variables) => {
        const { projectId, roomId, itemId } = variables;
        await invalidateGetProjectNotesQueryData(client, [{ projectId }]);
        if (itemId != undefined && roomId != undefined) {
          await updateCacheForUpdateItemNoteMutation(
            client,
            result,
            [projectId, { showItems: true }],
            roomId,
            itemId
          );
        }
      },
    })
  )(Component as ComponentType<WithUpdateProjectNoteMutationProps>);
}

export const updateCacheForUpdateItemNoteMutation = (
  client: QueryClient,
  updatedNote: ProjectNote,
  keyParams: SkipFirst<GetRoomsQueryKey>,
  roomId: number,
  itemId: number
) => {
  client.setQueriesData<GetRoomsXhr | undefined>(
    createGetRoomsQueryKey(keyParams),
    (data) => {
      const index = data?.findIndex((room) => room.id === roomId);
      if (!data || index === -1 || index == null) return data;

      const indexItem = data[index].items.findIndex(
        (item) => item.id === itemId
      );
      if (indexItem === -1 || indexItem == null) return data;

      const updatedData = [...data];
      const updatedRoomItem = { ...updatedData[index].items[indexItem] };
      updatedRoomItem.eamesNote = updatedNote;
      updatedData[index].items[indexItem] = updatedRoomItem;
      return updatedData;
    }
  );
};
