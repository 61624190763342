import { css, FlattenSimpleInterpolation } from 'styled-components';

const THEME_CONF = 'flexboxgrid';

interface ContainerConfig {
  sm: number;
  md: number;
  lg: number;
}

interface BreakpointsConfig {
  xs: number;
  sm: number;
  md: number;
  lg: number;
}

export interface BaseConfig {
  gridSize: number;
  gutterWidth: number;
  outerMargin: number;
  mediaQuery: string;
  container: ContainerConfig;
  breakpoints: BreakpointsConfig;
  media?: any;
}

const BASE_CONF: BaseConfig = {
  gridSize: 12,
  gutterWidth: 1,
  outerMargin: 2,
  mediaQuery: 'only screen',
  container: {
    sm: 46,
    md: 61,
    lg: 76,
  },
  breakpoints: {
    xs: 0,
    sm: 48,
    md: 64,
    lg: 75,
  },
};

const configCache: [string, BaseConfig | undefined] = ['', undefined];

const makeCacheId = (props: { theme?: Record<string, any> }): string =>
  JSON.stringify((props.theme && props.theme[THEME_CONF]) || {});

const resolveConfig = (props: { theme?: Record<string, any> }): BaseConfig => {
  const themeConf = (props.theme && props.theme[THEME_CONF]) || {};

  const conf: BaseConfig = {
    ...BASE_CONF,
    ...themeConf,
    container: {
      ...BASE_CONF.container,
      ...themeConf.container,
    },
    breakpoints: {
      ...BASE_CONF.breakpoints,
      ...themeConf.breakpoints,
    },
  };

  conf.media = (
    Object.keys(conf.breakpoints) as Array<keyof BreakpointsConfig>
  ).reduce((media, breakpoint) => {
    const breakpointWidth = conf.breakpoints[breakpoint];
    media[breakpoint] = makeMedia(
      [
        conf.mediaQuery,
        breakpoint !== 'xs' && `(min-width: ${breakpointWidth}em)`,
      ]
        .filter(Boolean)
        .join(' and ')
    );
    return media;
  }, {} as Record<string, (...args: Parameters<typeof css>) => FlattenSimpleInterpolation>);

  return conf;
};

export type ModificatorType = 'xs' | 'sm' | 'md' | 'lg';

export const DIMENSION_NAMES = ['xs', 'sm', 'md', 'lg'];

export const CONTAINER_SIZES: Exclude<ModificatorType, 'xs'>[] = [
  'sm',
  'md',
  'lg',
];

export default function config(props: {
  theme?: Record<string, any>;
}): BaseConfig {
  const cacheId = makeCacheId(props);
  if (configCache[0] === cacheId) {
    return configCache[1]!;
  }

  const conf = resolveConfig(props);

  configCache[0] = cacheId;
  configCache[1] = conf;

  return conf;
}

function makeMedia(media: string): (...args: Parameters<typeof css>) => any {
  return (...args) => css`
    @media ${media} {
      ${css(...args)}
    }
  `;
}
