import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { type Style, type StyleStatus, invalidateStylesQueryData } from '../';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/styles/:id
 */

// HTTP

export interface UpdateStyleXhrVariables {
  styleId: number;
  body: UpdateStyleXhrBody;
}

export interface UpdateStyleXhrBody {
  name?: string;
  status?: StyleStatus;
}

export interface UpdateStyleXhrResponse {
  data: Style;
}

export const updateStyleXhr = ({
  styleId,
  body,
}: UpdateStyleXhrVariables): Promise<Style> => mockResponse({});
// http
//   .put<UpdateStyleXhrResponse>(`/inventory/v1/styles/${styleId}`, body)
//   .then((res) => res.data.data);

// MutationFn

type UpdateStyleMutation = MutationFunction<Style, UpdateStyleXhrVariables>;

export const updateStyleMutationFn: UpdateStyleMutation = (variables) =>
  updateStyleXhr(variables);

// With Mutation HOC

const withUpdateStyleMutationPropKey = 'updateStyleMutation';

export type WithUpdateStyleMutationProps = WithMutationPropsByMutation<
  typeof withUpdateStyleMutationPropKey,
  UpdateStyleMutation
>;

export function withUpdateStyleMutation<P extends WithUpdateStyleMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateStyleMutationProps>> {
  return withMutation(
    withUpdateStyleMutationPropKey,
    updateStyleMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateStylesQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateStyleMutationProps>);
}
