import React from 'react';

import { Container, Inner, Title } from './styled';

const LaptopGraphic = () => (
  <svg width="135" height="95" viewBox="0 0 135 95">
    <path
      fill="#009CFF"
      fillRule="nonzero"
      d="M121.089 0H13.134c-1.799 0-3.418 1.44-3.418 3.419v61.894c0 1.799 1.44 3.418 3.418 3.418H121.09c1.8 0 3.419-1.44 3.419-3.418V3.419c0-1.98-1.62-3.419-3.419-3.419zM67.112.72c.9 0 1.62.72 1.62 1.619 0 .9-.72 1.62-1.62 1.62-.9 0-1.62-.72-1.62-1.62 0-.9.72-1.62 1.62-1.62zm52.718 62.074c0 .72-.54 1.259-1.26 1.259H15.653c-.72 0-1.26-.54-1.26-1.26V5.759c0-.72.54-1.26 1.26-1.26H118.75c.72 0 1.26.54 1.26 1.26v57.036h-.18zm14.214 27.528v.72c0 2.159-1.8 3.958-3.959 3.958H3.958C1.8 95 0 93.2 0 91.042v-.72h134.044zm-.72-3.599l-7.917-15.293c-.18-.54-.72-.9-1.44-.9H10.257c-.54 0-1.08.36-1.44.9L.9 86.723c-.54 1.08.18 2.16 1.439 2.16h129.545c1.08.18 1.98-1.08 1.44-2.16zm-82.945.9l1.44-3.778h30.406l1.44 3.778H50.379zm-38.864-5.218l3.779-8.456H119.11l3.778 8.456H11.515z"
    />
    <path
      fill="#293042"
      d="M67.043 24l-5.096 2.93v5.86l5.096 2.93 5.095-2.93v-5.86L67.043 24zm-4.884 3.052l4.873-2.802.01-.006 1.554.893-6.407 3.597-.03.017v-1.699zm5.072-.906l1.578-.886 3.117 1.792v1.695l-4.695-2.601zm-3.32 1.864l3.093-1.737.01-.005 3.162 1.752.011.006v1.716h-6.276V28.01zm-1.742 2.748l-.01-.005v-1.76l1.526-.856v3.458l-1.516-.837zm8.243-2.608l1.503.833.01.006v1.734l-1.513.849V28.15zm-6.49 3.576l-.011-.006v-1.766h6.276v1.744l-3.124 1.752-.01.005-3.132-1.73zm1.59 2.87l-.03-.017 6.414-3.596.03-.017v1.702l-4.873 2.802-.01.006-1.53-.88zm-3.343-1.923l-.01-.005v-1.674l4.646 2.565.031.018-1.567.879-3.1-1.783zM52.981 39.456l-2.456 6.3-2.457-6.3H47l3.042 7.814h.115l3.043-7.814zM83.843 39.202L80.8 47.015h.219l2.456-6.3 2.457 6.3H87l-3.042-7.813zM56.766 39.456v7.559h4.841v-.172h-3.864v-3.575h2.276v-.172h-2.276v-3.468h3.864v-.172zM67.517 42.51c1.079.331 2.414.695 2.414 2.193 0 1.444-1.224 2.406-2.637 2.406-1.046 0-1.913-.524-2.358-1.176l.144-.118c.412.631 1.257 1.113 2.236 1.113 1.09 0 2.07-.6 2.07-1.637 0-1.09-1.102-1.444-2.192-1.797-1.113-.353-2.18-.738-2.18-2.043 0-1.359 1.157-2.225 2.458-2.225.924 0 1.702.439 2.136 1.059l-.122.118c-.4-.589-1.135-.995-2.014-.995-1.024 0-1.97.556-1.97 1.476 0 .93.99 1.305 2.015 1.626M76.361 39.702v7.36h-.979v-7.36H72.79v-.171h6.163v.171z"
    />
  </svg>
);

function MobileNotice() {
  return (
    <Container>
      <Inner>
        <LaptopGraphic />
        <Title>Please use a laptop or iPad in landscape mode</Title>
        <p>
          We are currently working on supporting mobile devices and smaller
          screens. For now, please use a desktop, laptop, or iPad in portrait
          mode to access project management.
        </p>
      </Inner>
    </Container>
  );
}

export default MobileNotice;
