import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  invalidateGetRoomQueryData,
  invalidateGetRoomsQueryData,
  createGetRoomsQueryKey,
  type NormailezedRooms,
  type Room,
  type RoomProduct,
  type GetRoomsXhr,
  type Project,
  type WithMutationPropsByMutation,
} from 'js/api';
import { normalizeListData } from 'js/utils';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/room_products/move
 */

// HTTP

export interface MoveRoomProductsXhrVariables {
  projectId: Project['id'];
  targetProjectId?: Project['id']; // present when move to another project
  targetRoomId?: Room['id']; // present when move to another room
  body:
    | MoveRoomProductsToAnotherProjectXhrBody
    | MoveRoomProductsToAnotherRoomXhrBody;
}

// Types of move
export interface MoveRoomProductsToAnotherProjectXhrBody {
  projectId: Project['id'];
  reason?: string;
  roomProducts: {
    quantityToMove: number;
    roomProductId: RoomProduct['id'];
    roomId: Room['id'];
    deliveryDate?: string;
  }[];
}

export interface MoveRoomProductsToAnotherRoomXhrBody {
  roomProducts: {
    quantityToMove: number;
    roomProductId: RoomProduct['id'];
    roomId: Room['id'];
    deliveryDate?: string;
  }[];
}

export type MoveRoomProductsXhrResponseDataItem = {
  destinationRoomProduct: RoomProduct;
  sourceRoomProduct: RoomProduct;
};

export interface MoveRoomProductsXhrResponse {
  data: MoveRoomProductsXhrResponseDataItem[];
}

export const moveRoomProductsXhr = ({
  body,
}: MoveRoomProductsXhrVariables): Promise<
  MoveRoomProductsXhrResponseDataItem[]
> => mockResponse({});
// http
//   .post<MoveRoomProductsXhrResponse>(`/inventory/v1/room_products/move`, body)
//   .then((res) => res.data.data);

// MutationFn

type MoveRoomProductsMutation = MutationFunction<
  MoveRoomProductsXhrResponseDataItem[],
  MoveRoomProductsXhrVariables
>;

export const moveRoomProductsMutationFn: MoveRoomProductsMutation = (
  variables
) => moveRoomProductsXhr(variables);

// Update cache

export const updateRoomsCacheForMoveRoomProductsMutation = (
  client: QueryClient,
  moveResults: Awaited<ReturnType<typeof moveRoomProductsXhr>>,
  projectId: Project['id'],
  targetRoomId?: Room['id']
) => {
  // client.setQueriesData<GetRoomsXhr | undefined>(
  //   createGetRoomsQueryKey([projectId, undefined]),
  //   (data) => {
  //     if (!data) return data;
  //     const allRooms = normalizeListData(data as never[]) as NormailezedRooms;
  //     const updatedRooms = moveResults.reduce((accum, moveResult) => {
  //       const { sourceRoomProduct: src } = moveResult;
  //       // @TODO Check how works src.quantityPicked > 0)
  //       if (src.quantity > 0 || src.quantityPicked > 0) {
  //         // update product quantities
  //         accum[src.roomId].roomProducts = accum[src.roomId].roomProducts.map(
  //           (product) => (product.id === src.id ? src : product)
  //         );
  //       } else {
  //         // remove from old room
  //         accum[src.roomId].roomProducts = accum[
  //           src.roomId
  //         ].roomProducts.filter((product) => product.id !== src.id);
  //       }
  //       if (!targetRoomId) return accum;
  //       // if moving product to another room (within the same project), add it to the destination room
  //       const { destinationRoomProduct: dst } = moveResult;
  //       const toRoomId = dst.roomId;
  //       // remove from new room
  //       accum[toRoomId].roomProducts = accum[toRoomId].roomProducts.filter(
  //         (product) => product.product.id !== dst.product.id
  //       );
  //       // add/merge
  //       accum[toRoomId].roomProducts.push(dst);
  //       return accum;
  //     }, allRooms);
  //     return Object.values(updatedRooms);
  //   }
  // );
};

// With Mutation HOC

const withMoveRoomProductsMutationPropKey = 'moveRoomProductsMutation';

export type WithMoveRoomProductsMutationProps = WithMutationPropsByMutation<
  typeof withMoveRoomProductsMutationPropKey,
  MoveRoomProductsMutation
>;

export function withMoveRoomProductsMutation<
  P extends WithMoveRoomProductsMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithMoveRoomProductsMutationProps>> {
  return withMutation(
    withMoveRoomProductsMutationPropKey,
    moveRoomProductsMutationFn,
    (client) => ({
      onSuccess: async (
        moveResults,
        { projectId, targetProjectId, targetRoomId }
      ) => {
        // @TODO whay else need invalidate
        updateRoomsCacheForMoveRoomProductsMutation(
          client,
          moveResults,
          projectId,
          targetRoomId
        );

        if (targetRoomId)
          await invalidateGetRoomQueryData(client, [
            targetRoomId,
            projectId,
            true,
          ]);
        if (targetProjectId)
          await invalidateGetRoomsQueryData(client, [
            targetProjectId,
            undefined,
          ]);
      },
    })
  )(Component as ComponentType<WithMoveRoomProductsMutationProps>);
}
