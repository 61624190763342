import React, {
  createContext,
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { SwipeEvents } from '@shared_components/swipe_events';
import styles from './toaster.module.css';
import { Close } from '@shared_components/icons/close';
import { ErrorIcon } from '@shared_components/icons/error';
import { Warning } from '@shared_components/icons/warning';
import { Information } from '@shared_components/icons/information';
import { Failure } from '@shared_components/icons/failure';
import { Confirmation } from '@shared_components/icons/confirmation';
import { IconTextButton } from '@shared_components/icon_text_button/icon_text_button';

export type ToastType = 'error' | 'notice' | 'alert' | 'info';

interface ToastInterface extends AddToastInputs {
  id: number;
}

type AddBreadFn = ({
  type,
  message,
  additionalInfo,
  persistent,
}: AddToastInputs) => void;
export interface AddToastInputs {
  type: ToastType;
  message: string;
  additionalInfo?: React.ReactNode;
  persistent?: boolean;
}

export const GENERIC_ERROR_MESSAGE: AddToastInputs = {
  type: 'error',
  message: 'Oops! Something went wrong. Please try again.',
  persistent: true,
};

export const ToasterContext = createContext<{
  addBread: AddBreadFn;
}>({ addBread: () => {} });

export const ToasterProvider: FunctionComponent<{}> = ({ children }) => {
  // ----------------------------------------
  // state
  // ----------------------------------------
  const [toasts, setToasts] = useState<ToastInterface[]>([]);
  const [currentToastId, setCurrentToastId] = useState(0);

  // ----------------------------------------
  // helper functions
  // ----------------------------------------
  const addBread: AddBreadFn = ({
    type,
    message,
    additionalInfo,
    persistent,
  }) => {
    setToasts([
      ...toasts,
      { id: currentToastId, type, message, additionalInfo, persistent },
    ]);
    setCurrentToastId(currentToastId + 1);
  };

  function closeToast(id: number) {
    setToasts((currentToasts) => {
      const targetToast = currentToasts.find((toast) => toast.id === id);
      if (!targetToast) {
        return currentToasts;
      }

      const index = currentToasts.indexOf(targetToast);

      return [
        ...currentToasts.slice(0, index),
        ...currentToasts.slice(index + 1),
      ];
    });
  }

  function startClosingTimer(toast: ToastInterface) {
    if (toast.persistent) {
      return;
    }

    let timeout = 6000;

    const extraCharacters = toast.message.length - 32;
    const averageWordLength = 5;
    if (extraCharacters > 0) {
      const extraTime = Math.floor(extraCharacters / averageWordLength) * 1000;
      timeout += extraTime;
    }

    setTimeout(() => {
      closeToast(toast.id);
    }, timeout);
  }

  // ----------------------------------------
  // render
  // ----------------------------------------
  return (
    <ToasterContext.Provider value={{ addBread }}>
      {children}

      {/* ---------------- */}
      {/* Toasts Go Here   */}
      {/* ---------------- */}
      <div className={styles.container}>
        <TransitionGroup>
          {toasts.slice(0, 3).map((toast) => (
            <CSSTransition
              key={toast.id}
              timeout={300}
              classNames={{
                exitActive: styles.closing,
              }}
            >
              <Toast
                toast={toast}
                onClose={() => closeToast(toast.id)}
                onMount={startClosingTimer}
              ></Toast>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </ToasterContext.Provider>
  );
};

export const Toast: FunctionComponent<{
  toast: ToastInterface;
  onClose: () => void;
  onMount: (toast: ToastInterface) => void;
}> = ({ toast, onClose, onMount }) => {
  const iconMap: Record<ToastType, ReactNode> = {
    alert: <Warning className="text-oak" style={{ width: 20, height: 20 }} />,
    error: (
      <Failure className="text-pomegranate" style={{ width: 20, height: 20 }} />
    ),
    info: (
      <Information className="text-aqua" style={{ width: 20, height: 20 }} />
    ),
    notice: (
      <Confirmation className="text-green" style={{ width: 20, height: 20 }} />
    ),
  };

  useEffect(() => {
    onMount(toast);
  }, []);

  return (
    <SwipeEvents
      onSwipeLeft={() => !toast.persistent && onClose()}
      onSwipeRight={() => !toast.persistent && onClose()}
    >
      <div
        className={[styles.toast, styles[toast.type]].join(' ')}
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
        aria-label={`${toast.message} ${toast.additionalInfo || ''}`}
      >
        <div className="flex items-start gap-15">
          <div className="flex-shrink-0">{iconMap[toast.type]}</div>
          <div>
            <div>{toast.message}</div>
            <div className="fern-small-body">{toast.additionalInfo}</div>
          </div>
        </div>
        <IconTextButton
          icon={<Close style={{ width: 12, height: 12 }} />}
          onClick={onClose}
          segmentEventContext={'Toaster'}
          title={'Close'}
        ></IconTextButton>
      </div>
    </SwipeEvents>
  );
};
