export const materialsMock = {
  finishes: [
    {
      id: 565,
      name: 'Bone',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 566,
          name: 'Bone',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 568,
              name: 'Dark',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 569,
              name: 'White wash',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 567,
              name: 'Natural',
              image: {
                key: 'https://cdn.filestackcontent.com/0sjClxNAS66tYdDbgJfw',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/0sjClxNAS66tYdDbgJfw',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/0sjClxNAS66tYdDbgJfw',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/0sjClxNAS66tYdDbgJfw',
              },
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
      ],
    },
    {
      id: 570,
      name: 'Ceramic',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 571,
          name: 'Ceramic',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 573,
              name: 'Glaze',
              image: {
                key: 'https://cdn.filestackcontent.com/FrSJbSgZSOa1F4NiwH9t',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/FrSJbSgZSOa1F4NiwH9t',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/FrSJbSgZSOa1F4NiwH9t',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/FrSJbSgZSOa1F4NiwH9t',
              },
              description: null,
              type: 'finishes',
              instructions:
                'Glaze type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially u',
            },
            {
              id: 572,
              name: 'Clay',
              image: {
                key: 'https://cdn.filestackcontent.com/nUYxTj1PS7C4DcYOYL2q',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/nUYxTj1PS7C4DcYOYL2q',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/nUYxTj1PS7C4DcYOYL2q',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/nUYxTj1PS7C4DcYOYL2q',
              },
              description: null,
              type: 'finishes',
              instructions:
                'Clay  1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially u',
            },
          ],
        },
      ],
    },
    {
      id: 574,
      name: 'Glass',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 575,
          name: 'Glass',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 576,
              name: 'Clear',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 578,
              name: 'Smokey',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 579,
              name: 'Black',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 577,
              name: 'Frosted',
              image: {
                key: 'https://cdn.filestackcontent.com/rPDOMGBBSQ2jn2BoGv37',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/rPDOMGBBSQ2jn2BoGv37',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/rPDOMGBBSQ2jn2BoGv37',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/rPDOMGBBSQ2jn2BoGv37',
              },
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
      ],
    },
    {
      id: 580,
      name: 'Metal',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 581,
          name: 'Aluminum',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 582,
              name: 'Divano, New Line, Bonach',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 583,
              name: 'Powder coated',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
              sub_materials: [
                {
                  id: 584,
                  name: 'Black',
                  image: null,
                  description: null,
                  type: 'finishes',
                  instructions: null,
                },
                {
                  id: 585,
                  name: 'White',
                  image: null,
                  description: null,
                  type: 'finishes',
                  instructions: null,
                },
                {
                  id: 586,
                  name: 'Grey',
                  image: null,
                  description: null,
                  type: 'finishes',
                  instructions: null,
                },
              ],
            },
          ],
        },
        {
          id: 587,
          name: 'Chrome',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 588,
              name: 'Black Chrome',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 589,
          name: 'Brass',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 590,
              name: 'Brushed brass',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 591,
              name: 'Polished brack',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 592,
              name: 'Plated brass',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 593,
          name: 'Bronze',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 594,
              name: 'Oil rubbed',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 595,
          name: 'Iron',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 596,
              name: 'Anti-copper',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 597,
          name: 'Nickel',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 598,
              name: 'Polished',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 599,
              name: 'Brushed',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 600,
              name: 'Black',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 601,
          name: 'Platinum',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 602,
          name: 'Wire',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 603,
          name: 'Zinc',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
      ],
    },
    {
      id: 604,
      name: 'Stone',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 605,
          name: 'Alabaster',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 606,
              name: 'S&H, multiple vendors - lighting',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 607,
          name: 'Agate',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 608,
              name: 'Multiple vendors - accessories',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 609,
          name: 'Carrara',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 610,
              name: 'Marble',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 611,
          name: 'Concrete',
          image: {
            key: 'https://cdn.filestackcontent.com/GpXMDKScqkBvpjIGob5Q',
            thumbnail_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/GpXMDKScqkBvpjIGob5Q',
            small_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/GpXMDKScqkBvpjIGob5Q',
            normal_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/GpXMDKScqkBvpjIGob5Q',
          },
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 612,
          name: 'Granite',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 613,
          name: 'Graphite',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 614,
          name: 'Limestone',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
      ],
    },
    {
      id: 615,
      name: 'Wood',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 616,
          name: 'Oak',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 617,
              name: 'Light',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 618,
              name: 'Dark',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 619,
              name: 'Medium',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 620,
              name: 'Natural',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 621,
          name: 'Acacia',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 622,
              name: 'Still to be define - getting info from Vendor',
              image: {
                key: 'https://cdn.filestackcontent.com/zabQa5DGT2abcEQWNwv3',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/zabQa5DGT2abcEQWNwv3',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/zabQa5DGT2abcEQWNwv3',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/zabQa5DGT2abcEQWNwv3',
              },
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 623,
          name: 'Bamboo',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 624,
              name: 'Still to be define - getting info from Vendor',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 625,
          name: 'Ash',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 626,
              name: 'Still to be define - getting info from Vendor',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 627,
          name: 'Birch',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 628,
              name: 'Light',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 629,
              name: 'Dark',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 630,
              name: 'Medium',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 631,
              name: 'Natural',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
        {
          id: 632,
          name: 'Teak',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 634,
              name: 'Dark',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 635,
              name: 'Medium',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 636,
              name: 'Natural',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
            {
              id: 633,
              name: 'Oatmeal',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
            },
          ],
        },
      ],
    },
    {
      id: 693,
      name: 'Test category',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 694,
          name: 'Test subcategory Wood',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 695,
              name: 'Wood1',
              image: {
                key: 'https://cdn.filestackcontent.com/OhTrO7SESwsrtf2rw1gg',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/OhTrO7SESwsrtf2rw1gg',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/OhTrO7SESwsrtf2rw1gg',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/OhTrO7SESwsrtf2rw1gg',
              },
              description: null,
              type: 'finishes',
              instructions:
                'Warning: Automatically focusing a form control can confuse visually-impaired people using screen-reading technology and people with cognitive impairments. When autofocus is assigned, screen-readers "teleport" their user to the form control without warning them beforehand.',
            },
            {
              id: 698,
              name: 'Wood2',
              image: {
                key: 'https://cdn.filestackcontent.com/TgCQRnFTsiq5dKpZthUg',
                thumbnail_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/TgCQRnFTsiq5dKpZthUg',
                small_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/TgCQRnFTsiq5dKpZthUg',
                normal_url:
                  'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/TgCQRnFTsiq5dKpZthUg',
              },
              description: null,
              type: 'finishes',
              instructions:
                'This is the one of the important attribute introduced in HTML5 for UX. When used on an element, focus is set on that element on page load or when its dialog is shown (such as when a modal window is shown).\nThis can provide both direction and convenience for a user, reducing the need to click or tab to a field when a page opens.\n\nThis attribute is true when present on an element, and false when missing. Only one element on each page can have the autofocus field.',
            },
          ],
        },
        {
          id: 708,
          name: 'Add',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
        {
          id: 710,
          name: 'test',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
      ],
    },
    {
      id: 711,
      name: 'LolTest1',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
      sub_materials: [
        {
          id: 712,
          name: 'LOLTEST2',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
          sub_materials: [
            {
              id: 714,
              name: '555',
              image: null,
              description: null,
              type: 'finishes',
              instructions: null,
              sub_materials: [
                {
                  id: 717,
                  name: '666',
                  image: null,
                  description: null,
                  type: 'finishes',
                  instructions: null,
                  sub_materials: [
                    {
                      id: 718,
                      name: '777',
                      image: null,
                      description: null,
                      type: 'finishes',
                      instructions: null,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 713,
          name: 'nulltest222',
          image: null,
          description: null,
          type: 'finishes',
          instructions: '85589',
        },
        {
          id: 716,
          name: 'ttest material',
          image: null,
          description: null,
          type: 'finishes',
          instructions: null,
        },
      ],
    },
    {
      id: 720,
      name: 'TEST',
      image: null,
      description: null,
      type: 'finishes',
      instructions: null,
    },
  ],
  materials: [
    {
      id: 637,
      name: 'Leather',
      image: null,
      description: null,
      type: 'materials',
      instructions: null,
      sub_materials: [
        {
          id: 638,
          name: 'Vegan',
          image: {
            key: 'https://cdn.filestackcontent.com/ypHRkEHQYK0A3JO6KFdw',
            thumbnail_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/ypHRkEHQYK0A3JO6KFdw',
            small_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/ypHRkEHQYK0A3JO6KFdw',
            normal_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/ypHRkEHQYK0A3JO6KFdw',
          },
          description: null,
          type: 'materials',
          instructions:
            "Vegan     Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially u",
        },
        {
          id: 639,
          name: 'Cowhide',
          image: {
            key: 'https://cdn.filestackcontent.com/ovxcoWK5SVKMpF6XLeZQ',
            thumbnail_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:120,height:120,fit:crop/https://cdn.filestackcontent.com/ovxcoWK5SVKMpF6XLeZQ',
            small_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:360,height:360,fit:crop/https://cdn.filestackcontent.com/ovxcoWK5SVKMpF6XLeZQ',
            normal_url:
              'https://process.filestackapi.com/A9wiNbSIQQkG1iCbdkCFLz/output=f:jpg/rotate=deg:exif/resize=width:2048,height:2048,fit:clip/https://cdn.filestackcontent.com/ovxcoWK5SVKMpF6XLeZQ',
          },
          description: null,
          type: 'materials',
          instructions:
            'Cowhide   Delete Instructions Delete InstructionsDelete\nInstructionsDelete\nInstructions',
        },
        {
          id: 640,
          name: 'Buffalo',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
        },
        {
          id: 641,
          name: 'Analine dyed',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
        },
        {
          id: 642,
          name: 'Grain',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
        },
      ],
    },
    {
      id: 643,
      name: 'Fabrics',
      image: null,
      description: null,
      type: 'materials',
      instructions: null,
      sub_materials: [
        {
          id: 644,
          name: 'Boucle - Copenhagen',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 645,
              name: 'Ivory',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 646,
              name: 'Putty',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 647,
              name: 'Cypress',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 648,
              name: 'Onyx - 800',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 649,
          name: 'Linen - Siena',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 650,
              name: 'Natural',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 651,
              name: 'Sand',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 652,
              name: 'Pewter',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 653,
          name: 'Faux Velter Diamond',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 654,
              name: 'Slate',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 655,
              name: 'Onyx',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 656,
              name: 'Steel',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 657,
              name: 'Blush',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 658,
          name: 'Tweed - Corsica',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 659,
              name: 'Basalt',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 660,
              name: 'Sandstone',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 661,
              name: 'Granite',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 662,
          name: 'Structured Woven - Sardinia',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 663,
              name: 'Ivory',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 664,
              name: 'Onyx',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 665,
              name: 'Harvest',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 666,
          name: 'Textured Woven Bermuda',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 667,
              name: 'Ivory',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 668,
              name: 'Pewter',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 669,
              name: 'Charcoal',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
            {
              id: 670,
              name: 'Marine',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
        {
          id: 671,
          name: 'Sherpa Himalaya',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
          sub_materials: [
            {
              id: 672,
              name: 'Ivory',
              image: null,
              description: null,
              type: 'materials',
              instructions: null,
            },
          ],
        },
      ],
    },
    {
      id: 704,
      name: 'Stone',
      image: null,
      description: null,
      type: 'materials',
      instructions: null,
      sub_materials: [
        {
          id: 705,
          name: 'Marbled',
          image: null,
          description: null,
          type: 'materials',
          instructions: null,
        },
      ],
    },
  ],
};
