import React, { type HTMLAttributes, type PropsWithChildren } from 'react';
import {
  type FlattenSimpleInterpolation,
  type InterpolationValue,
} from 'styled-components';

import { toClassNames } from 'js/utils';

import {
  LinkStyled,
  AnchorStyled,
  ButtonStyled,
  type ButtonProps,
  type AnchorStyledButtonProps,
  type LinkStyledButtonProps,
} from './styled';

export type ButtonType = 'button' | 'submit';

export interface ButtonBaseProps extends HTMLAttributes<unknown> {
  block?: boolean;
  link?: boolean;
  small?: boolean;
  disabled?: boolean;
  danger?: boolean;
  cta?: boolean;
  styles?: string | InterpolationValue[] | FlattenSimpleInterpolation;
  tooltip?: string;
  target?: string;
  innerRef?: React.Ref<unknown>;
}

export type Props =
  | ButtonProps
  | AnchorStyledButtonProps
  | LinkStyledButtonProps;

function Button({
  className = '',
  children,
  ...rest
}: PropsWithChildren<Props>) {
  const props = {
    className: toClassNames('button', className),
    ...rest,
  };

  // wrapping router <Link/> with a button to avoid unsupported prop warnings
  if ('to' in props && props.to && !props.disabled) {
    return <LinkStyled {...props}>{children}</LinkStyled>;
  }

  if ('href' in props && props.href && !props.disabled) {
    return <AnchorStyled {...props}>{children}</AnchorStyled>;
  }

  return (
    <ButtonStyled type={'type' in props ? props.type : 'button'} {...props}>
      {children}
    </ButtonStyled>
  );
}

export default Button;
