import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  isInfiniteData,
  type WithMutationPropsByMutation,
} from 'js/api/';
import { type GetPricesXhr, processPricesQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * DELETE - `/api/v1/eames/projects/${projectId}/price_lists/${priceListId}`
 */

// HTTP

interface DeletePriceXhrVariables {
  projectId: number;
  priceListId: number;
}

export const deletePriceXhr = ({
  projectId,
  priceListId,
}: DeletePriceXhrVariables): Promise<unknown> =>
  http.delete<unknown>(
    `/api/v1/eames/projects/${projectId}/price_lists/${priceListId}`
  );

// MutationFn

type DeletePriceMutation = MutationFunction<unknown, DeletePriceXhrVariables>;

export const deletePriceMutationFn: DeletePriceMutation = (variables) =>
  deletePriceXhr(variables);

// With Mutation HOC

const withDeletePriceMutationPropKey = 'deletePriceMutation';

export type WithDeletePriceMutationProps = WithMutationPropsByMutation<
  typeof withDeletePriceMutationPropKey,
  DeletePriceMutation
>;

export function withDeletePriceMutation<P extends WithDeletePriceMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithDeletePriceMutationProps>> {
  return withMutation(
    withDeletePriceMutationPropKey,
    deletePriceMutationFn,
    (client) => ({
      // onSuccess: (_, { projectId, priceListId }) => {
      //   processPricesQueryData(client, [projectId], ([key, data]) => {
      //     // Do not update cache for infinite query, just do refetch in component directly
      //     if (!isInfiniteData(data)) {
      //       const updatedPrices = data.prices.filter(
      //         (price) => price.id !== priceListId
      //       );
      //       const patch: GetPricesXhr = { ...data, prices: updatedPrices };
      //       client.setQueryData(key, patch);
      //     }
      //   });
      // },
    })
  )(Component as ComponentType<WithDeletePriceMutationProps>);
}
