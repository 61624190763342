import React, { type ReactNode, type ComponentType } from 'react';
import styled, { css, type ThemeProps } from 'styled-components';

import { toClassNameObj } from 'js/utils';
import { type Theme } from 'js/theme';

interface DefaultValueRendererProps {
  placeholder?: ReactNode;
  label?: ReactNode;
}

function DefaultValueRenderer({
  placeholder,
  label,
}: DefaultValueRendererProps) {
  return <span>{label || placeholder}</span>;
}

interface ContainerProps {
  fluid?: boolean;
  disabled?: boolean;
}

const Container = styled.span<ContainerProps>`
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  border: solid 1px
    ${(props: ThemeProps<Theme>) => props.theme.colors.lightBlueGrey};
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  display: inline-block;
  font-size: 14px;
  height: 40px;
  max-width: 100%;
  overflow: hidden;
  padding: 10px 38px 10px 14px;
  position: relative;
  text-overflow: ellipsis;
  touch-action: manipulation;
  white-space: nowrap;

  ${(props) =>
    props.fluid
      ? css`
          width: 100%;
        `
      : ''};

  ${(props) =>
    props.disabled
      ? css`
          opacity: 0.8;
        `
      : css`
          cursor: pointer;
        `};

  &::after {
    border-color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
    border-radius: 1px;
    border-style: solid;
    border-width: 0 0 2px 2px;
    content: '';
    height: 7px;
    right: 18px;
    position: absolute;
    top: 15px;
    transform: rotate(-45deg);
    width: 7px;
  }
`;

const HiddenSelect = styled.select`
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export type Option<T = string> = { value: T; label: string };

type OptionsRenderer<T = string> = (options: Option<T>[]) => ReactNode[];

const defaultOptionsRenderer: OptionsRenderer = (options) =>
  options.map(({ value, label }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));

type SelectedSelector<T = string> = (
  options: Option<T>[],
  value: Option<T>
) => Record<string, unknown>;

const defaultSelectedSelector: SelectedSelector = (options, value) =>
  (value && options.find((option) => option.value === value.value)) ?? {};

interface SelectProps<T = string>
  extends ContainerProps,
    Pick<React.SelectHTMLAttributes<HTMLSelectElement>, 'onChange' | 'id'> {
  value?: Option<T> | null;
  options: Option<T>[];
  id?: string;
  className?: string;
  placeholder?: string;
  optionsRenderer?: OptionsRenderer<T>;
  selectedSelector?: SelectedSelector<T>;
  ValueComponent?: ComponentType<DefaultValueRendererProps>;
}

function Select<
  T extends string | number | readonly string[] | undefined = string
>({
  value = null,
  options,
  className = '',
  disabled = false,
  fluid = false,
  id = '',
  onChange,
  optionsRenderer = defaultOptionsRenderer as unknown as OptionsRenderer<T>,
  placeholder = 'Please select',
  selectedSelector = defaultSelectedSelector as unknown as SelectedSelector<T>,
  ValueComponent = DefaultValueRenderer,
}: SelectProps<T>) {
  const selectAttrs: Pick<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'onChange' | 'id' | 'value'
  > = {
    id,
    onChange,
    value: value ? value.value : 0,
  };

  return (
    <Container fluid={fluid} disabled={disabled} {...toClassNameObj(className)}>
      <ValueComponent
        placeholder={placeholder}
        {...(value ? selectedSelector(options, value) : {})}
      />
      {!disabled && (
        <HiddenSelect {...selectAttrs}>
          <option value={0} disabled>
            {placeholder}
          </option>
          {optionsRenderer(options)}
        </HiddenSelect>
      )}
    </Container>
  );
}

export default Select;
