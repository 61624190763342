import React from 'react';

import { toClassNames } from 'js/utils';

interface Props extends React.SVGProps<SVGSVGElement> {
  className?: string;
  styles?: React.CSSProperties;
  color?: string;
}

function Cross({
  className = '',
  color = '#30b3ef',
  styles = {},
  ...props
}: Props) {
  return (
    <svg
      className={toClassNames('icon', 'icon-cross', className)}
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      style={styles}
      {...props}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          className="color"
          transform="translate(-4.000000, -4.000000)"
          fill={color}
        >
          <path
            d="M11,9 L11,4.00684547 C11,3.44994876 10.5522847,3 10,3 C9.44386482,3 9,3.45078007 9,4.00684547 L9,9 L4.00684547,9 C3.44994876,9 3,9.44771525 3,10 C3,10.5561352 3.45078007,11 4.00684547,11 L9,11 L9,15.9931545 C9,16.5500512 9.44771525,17 10,17 C10.5561352,17 11,16.5492199 11,15.9931545 L11,11 L15.9931545,11 C16.5500512,11 17,10.5522847 17,10 C17,9.44386482 16.5492199,9 15.9931545,9 L11,9 Z"
            transform="translate(10.000000, 10.000000) rotate(45.000000) translate(-10.000000, -10.000000) "
          />
        </g>
      </g>
    </svg>
  );
}

export default Cross;
