export function getUtmQueryParams(query: object): object {
  const output: Record<string, any> = {};

  Object.entries(query).forEach(([key, value]) => {
    if (key.includes('utm_')) {
      output[key] = value;
    }
  });

  return output;
}
