import React from 'react';
import { type QueryClient, type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type PriceList,
} from 'js/api/';

/**
 * GET - /inventory/v1/projects/:project_id/prices/:price_list_id
 */

// HTTP

export type GetPriceListByIdXhr = PriceList;

export interface GetPriceListByIdXhrConfig extends HttpBaseConfig {
  params?: GetPriceListByIdXhrParams;
}

export interface GetPriceListByIdXhrParams {
  // itemsPage?: number;
  // itemsPerPage?: number;
}

export const getPriceListByIdXhr = (
  projectId: number,
  priceListId: number,
  config: GetPriceListByIdXhrConfig = {}
): Promise<GetPriceListByIdXhr> =>
  http
    .get<GetPriceListByIdXhr>(
      `/api/v1/eames/projects/${projectId}/price_lists/${priceListId}`,
      config
    )
    .then((res) => res.data);

const DEFAULT_PRICE_ITEMS_PER_PAGE = 30;

const GET_PRICE_LIST_BY_ID_QUERY_KEY_NAMESPACE = 'price';

// Query Key

type GetPriceListByIdQueryKeyNamespace =
  typeof GET_PRICE_LIST_BY_ID_QUERY_KEY_NAMESPACE;

export type GetPriceListByIdQueryKey = [
  GetPriceListByIdQueryKeyNamespace,
  number /* project id */,
  number /* price list id */,
  GetPriceListByIdXhrParams? /* params */
];

export const mapGetPriceListByIdQuery = (
  key: SkipFirst<GetPriceListByIdQueryKey>
): GetPriceListByIdQueryKey => [
  GET_PRICE_LIST_BY_ID_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

type QetPriceListByIdQueryFn = QueryFunction<
  GetPriceListByIdXhr,
  GetPriceListByIdQueryKey
>;

export const getPriceListByIdQueryFn: QetPriceListByIdQueryFn = ({
  queryKey: [_d, projectId, priceListId, params],
  signal,
  pageParam,
}) =>
  getPriceListByIdXhr(projectId, priceListId, { params, signal, ...pageParam });

// Query

export interface GetPriceListByIdQueryProps
  extends Omit<
    QueryProps<
      GetPriceListByIdXhr,
      Error,
      GetPriceListByIdXhr,
      GetPriceListByIdQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params?: GetPriceListByIdXhrParams;
  projectId: number;
  priceListId: number;
}

export type GetPriceListByIdQueryResult = Parameters<
  GetPriceListByIdQueryProps['children']
>[0];

export const GetPriceListByIdQuery = ({
  projectId,
  priceListId,
  params,
  ...props
}: GetPriceListByIdQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={mapGetPriceListByIdQuery([projectId, priceListId, params])}
      queryFn={getPriceListByIdQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetPriceListByIdQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetPriceListByIdQueryKey>
) => {
  const key = mapGetPriceListByIdQuery(keyParams);
  await client.invalidateQueries(key);
};
