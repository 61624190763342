const COMMON_HEADERS = {
  'Content-Type': 'application/json',
};

const OPTIONS = {
  headers: {
    ...COMMON_HEADERS,
  },
};

function get(url: string, extraHeaders?: object) {
  return fetch(url, {
    headers: {
      ...COMMON_HEADERS,
      ...extraHeaders,
    },
    method: 'GET',
  }).then(handleResponse);
}

function put(url: string, body: object) {
  return fetch(url, {
    ...OPTIONS,
    method: 'PUT',
    body: JSON.stringify(body),
  }).then(handleResponse);
}

function patch(url: string, body: object) {
  return fetch(url, {
    ...OPTIONS,
    method: 'PATCH',
    body: JSON.stringify(body),
  }).then(handleResponse);
}

function post(url: string, body: object, extraHeaders?: object) {
  return fetch(url, {
    headers: {
      ...COMMON_HEADERS,
      ...extraHeaders,
    },
    method: 'POST',
    body: JSON.stringify(body),
  }).then(handleResponse);
}

function destroy(url: string, body?: object) {
  const payload: { body?: string; method: string } = {
    ...OPTIONS,
    method: 'DELETE',
  };

  if (body) {
    payload['body'] = JSON.stringify(body);
  }

  return fetch(url, payload).then(handleResponse);
}

export const HttpService = {
  get,
  put,
  patch,
  post,
  destroy,
};

function handleResponse(response: Response) {
  if (response.ok) {
    return response.json();
  } else {
    return response
      .json()
      .then((body) => Promise.reject({ ...body, status: response.status }))
      .catch((error) => {
        return Promise.reject({
          error,
          status: response.status,
          statusText: response.statusText,
        });
      });
  }
}
