import { camelizeKeys } from 'humps';

export const mockResponse = async <P = any>(
  data: any,
  delay = 200
): Promise<P> => {
  return new Promise((res) =>
    setTimeout(() => res(camelizeKeys(data) as unknown as P), delay)
  );
};
