import { type ComponentType } from 'react';
import { QueryClient, type MutationFunction } from 'react-query';

import {
  http,
  withMutation,
  type WithMutationPropsByMutation,
  type Style,
  type Tag,
  type Collection,
  type ProductType,
} from 'js/api/';

import { createGetProductQueryKey, type SingleProduct } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * PUT - /inventory/v1/products/:id
 */

// HTTP

interface UpdateProductsParams {
  updateAuditTag?: 'Audit' | 'Audit Complete';
  styleIds?: Style['id'][];
  tagIds?: Tag['id'][];
  collectionIds?: Collection['id'][];
  productTypeIds?: ProductType['id'][];
  l1CategoryId?: number | null;
  l2CategoryId?: number | null;
  l3CategoryId?: number | null;
  l4CategoryId?: number | null;
  // Send only alone and true when upload to shopify
  uploadToShopify?: boolean;
}
interface UpdateProductsXhrVariables {
  productId: number;
  body: Partial<SingleProduct> & UpdateProductsParams;
}

export interface UpdateProductsXhrResponse {
  data: Partial<SingleProduct>;
}

export const updateProductXhr = ({
  productId,
  body,
}: UpdateProductsXhrVariables): Promise<Partial<SingleProduct>> =>
  mockResponse({});
// http
//   .put<UpdateProductsXhrResponse>(`/inventory/v1/products/${productId}`, body)
//   .then((res) => res.data.data);

// MutationFn

type UpdateProductMutation = MutationFunction<
  Partial<SingleProduct>,
  UpdateProductsXhrVariables
>;

export const updateProductMutationFn: UpdateProductMutation = (variables) =>
  updateProductXhr(variables);

// Update cache

export const updateProductCacheForUpdateProductMutation = (
  client: QueryClient,
  product: Awaited<ReturnType<typeof updateProductXhr>>
) => {
  if (!product.id) return;

  client.setQueriesData<SingleProduct | undefined>(
    createGetProductQueryKey([product.id]),
    (data) => {
      if (!data) return data;
      // @TODO Cheack  behavior on delete smth
      // and check for accordance with the entity of the product
      const updatedData = { ...data, ...product };
      return updatedData;
    }
  );
};

// With Mutation HOC

const withUpdateProductMutationPropKey = 'updateProductMutation';

export type WithUpdateProductMutationProps = WithMutationPropsByMutation<
  typeof withUpdateProductMutationPropKey,
  UpdateProductMutation
>;

export function withUpdateProductMutation<
  P extends WithUpdateProductMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateProductMutationProps>> {
  return withMutation(
    withUpdateProductMutationPropKey,
    updateProductMutationFn,
    (client) => ({
      onSuccess: (product) => {
        updateProductCacheForUpdateProductMutation(client, product);
      },
    })
  )(Component as ComponentType<WithUpdateProductMutationProps>);
}
