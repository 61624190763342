import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';

import {
  type Project,
  type CreateProjectXhrBody,
  createGetProjectsFacetsQuery,
} from '../';

/**
 * POST - /api/v1/eames/projects
 */

// HTTP

export type CreateProjectXhrResponse = Project & {
  errors?: Record<keyof CreateProjectXhrBody, string[]>[];
};

/* add '400' to valid response statuses to handle errors directly in project edit component */
const validateStatus = (status: number) =>
  status === 400 || status === 422 || (status >= 200 && status < 300);

export const createProjectXhr = (
  body: CreateProjectXhrBody
): Promise<CreateProjectXhrResponse> =>
  http
    .post<CreateProjectXhrResponse>(`/api/v1/eames/projects`, body, {
      validateStatus,
    })
    .then((res) => res.data);

// MutationFn

type CreateProjectMutation = MutationFunction<
  CreateProjectXhrResponse,
  CreateProjectXhrBody
>;

export const createProjectMutationFn: CreateProjectMutation = (body) =>
  createProjectXhr(body);

// With Mutation HOC

const withCreateProjectMutationPropKey = 'createProjectMutation';

export type WithCreateProjectMutationProps = WithMutationPropsByMutation<
  typeof withCreateProjectMutationPropKey,
  CreateProjectMutation
>;

export function withCreateProjectMutation<
  P extends WithCreateProjectMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateProjectMutationProps>> {
  return withMutation(
    withCreateProjectMutationPropKey,
    createProjectMutationFn,
    (client) => {
      return {
        onSuccess: () => {
          client.invalidateQueries(createGetProjectsFacetsQuery());
        },
      };
    }
  )(Component as ComponentType<WithCreateProjectMutationProps>);
}
