import {
  type Room,
  type RoomsMenuOption,
  SELECT_ALL_ROOMS,
  type RoomType,
} from '..';

import { type Option } from 'js/components/partial/Forms/Select';
import { UNASSIGNED_ROOM_NAME } from 'js/config';

export type RoomOption = Option<Room['id']>;
export interface RoomOptionWithType extends RoomOption {
  roomType: RoomType | '';
}

export const mapRoomToOption = (
  room: Pick<Room, 'id' | 'name'>
): RoomOption => {
  return {
    value: room.id,
    label: room.name,
  };
};

/**
 * Return an options list for the rooms dropdown in MoveProductModal
 */
export const mapRoomsToRoomSelectOptions = (
  rooms: Room[]
): RoomOptionWithType[] => {
  return rooms
    .reduce(
      (accum, room) => {
        return accum.concat({
          label: room.name,
          roomType: room.roomType,
          value: room.id,
        });
      },
      [{ label: 'Select Room', roomType: '', value: 0 } as RoomOptionWithType] // first option
    )
    .sort((a, b) => {
      if (a.label === UNASSIGNED_ROOM_NAME) return -1;
      if (b.label === UNASSIGNED_ROOM_NAME) return 1;
      return 0;
    });
};

/**
 * Return an options list for the RoomsMenu
 */
export const mapRoomsToMenuOptions = (rooms: Room[]) => {
  let totalProducts = 0;

  const options = rooms.reduce((accum, room) => {
    const qty = room.itemsCount;
    totalProducts += qty;
    return accum.concat({
      label: `${room.name} (${qty})`,
      name: room.name,
      roomType: room.roomType,
      value: room.id,
    });
  }, [] as RoomsMenuOption[]);

  // @TODO: use  logic from roomsMenuOptionsSelector
  options.sort((a, b) => {
    if (a.name === UNASSIGNED_ROOM_NAME) return -1;
    if (b.name === UNASSIGNED_ROOM_NAME) return 1;
    return 0;
  });

  const firstOption: RoomsMenuOption = {
    label: `All (${totalProducts})`,
    name: 'All',
    roomType: SELECT_ALL_ROOMS,
    value: 0,
  };
  options.unshift(firstOption);

  return options;
};
