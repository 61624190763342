import { type KeysToCamelCase } from 'js/types';
import {
  type RoomProductItemStatus,
  type TimestamptsRaw,
  type UserRaw,
  type CustomerOrderLineItem,
} from 'js/api/';

export enum PriceListStatus {
  Pending = 'pending',
  Sold = 'sold',
}

export type PriceListUserRaw = Pick<
  UserRaw,
  'id' | 'family_name' | 'given_name' | 'email'
>;

export type PriceList = KeysToCamelCase<PriceListRaw>;

export interface PriceListRaw extends TimestamptsRaw {
  id: number;
  name: string;
  total_price: number | null;
  total_price_with_discount: number | null;
  discount_percentage: number;
  created_by: PriceListUserRaw;
  last_edited_by: PriceListUserRaw;
  items_eligible: number;
  items_included: number;
}

export type PriceListItem = KeysToCamelCase<PriceListItemRaw>;

export interface PriceListItemRaw {
  id: number;
  price_id: number;
  discount: string;
  location: null;
  cycles: number;
  room_product_item_id: number;
  status: RoomProductItemStatus;
  room_product_id: number;
  thumbnail_url: string;
  sku: string;
  price_with_discount: number;
  retail_price: string;
  customer_name: string;
  room_id: number;
  room_name: string;
  room_type: string;
  item_id: number;
  item_name: string;
  item_sold_price: number;
  item_date_sold: string;
  eligible: boolean;
  price_item_id: number;
  width?: number;
  height?: number;
  depth?: number;
  quantity: number;
}

export interface PriceListPdfData {
  projectName: string;
  priceList: PriceList;
  items: CustomerOrderLineItem[];
}
