import styled from 'styled-components';

import config, { DIMENSION_NAMES } from '../config';

const offsetProps = DIMENSION_NAMES.map((d) => d + 'Offset');

interface ColProps {
  xs?: number | Boolean;
  sm?: number | Boolean;
  md?: number | Boolean;
  lg?: number | Boolean;
  xsOffset?: number;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  reverse?: boolean;
}

const Col = styled.div<ColProps>`
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: ${(p) => config(p).gutterWidth / 2}rem;
  padding-left: ${(p) => config(p).gutterWidth / 2}rem;

  ${(p) =>
    p.reverse &&
    `
    flex-direction: column-reverse;
  `}

  ${(p) =>
    (Object.keys(p) as Array<keyof ColProps>)
      .filter((k) => ~DIMENSION_NAMES.indexOf(k))
      .sort(
        (k1, k2) => DIMENSION_NAMES.indexOf(k1) - DIMENSION_NAMES.indexOf(k2)
      )
      .map(
        (k) =>
          config(p).media[k]`${
            typeof p[k] == 'number'
              ? // Integer value
                `
        flex-basis: ${(100 / config(p).gridSize) * (p[k] as number)}%;
        max-width: ${(100 / config(p).gridSize) * (p[k] as number)}%;
        display: block;
      `
              : // Boolean
              p[k]
              ? // Auto-width
                `
          flex-grow: 1;
          flex-basis: 0;
          max-width: 100%;
          display: block;
        `
              : // Hide element
                'display: none;'
          }`
      )}

  ${(p) =>
    (Object.keys(p) as Array<keyof ColProps>)
      .filter((k) => ~offsetProps.indexOf(k))
      .map(
        (k) =>
          config(p).media[k.replace(/Offset$/, '')]`
        margin-left: ${(100 / config(p).gridSize) * (p[k] as number)}%;
      `
      )}
`;

export default Col;
