import React, { useMemo } from 'react';
import { useQuery, type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';
import { mockResponse } from 'js/api/mock/utils';

/**
 * GET - /inventory/v1/projects/:id/pdf/:type
 */

export enum ReportTypes {
  Packing = 'packing',
}

export type ProjectPdfUrl = string | null;

export interface GetProjectPdfXhr {
  pdfUrl: ProjectPdfUrl;
}

// HTTP

export const getProjectPdfXhr = (
  projectId: number,
  type: ReportTypes,
  config: HttpBaseConfig
): Promise<ProjectPdfUrl> =>
  mockResponse(
    'https://s3.amazonaws.com/showroom-filestack/eames/staging/a7/4a/sales-test-address-1637166812-1-moveout_date-[2291].pdf'
  );
// http
//   .get<GetProjectPdfXhr>(
//     `/inventory/v1/projects/${projectId}/pdf/${type}`,
//     config
//   )
//   .then((res) => res.data.pdfUrl);

// Query Key

const GET_PROJECT_PDF_QUERY_KEY_NAMESPACE = 'getProjectPdfById';

type GetProjectPdfQueryKeyNamespace =
  typeof GET_PROJECT_PDF_QUERY_KEY_NAMESPACE;

export type GetProjectPdfQueryKey = [
  GetProjectPdfQueryKeyNamespace,
  number /* project id */,
  ReportTypes
];

export const createGetProjectPdfQueryKey = (
  key: SkipFirst<GetProjectPdfQueryKey>
): GetProjectPdfQueryKey => [GET_PROJECT_PDF_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getProjectPdfQueryFn: QueryFunction<
  ProjectPdfUrl,
  GetProjectPdfQueryKey
> = ({ signal, queryKey: [_d, projectId, type] }) =>
  getProjectPdfXhr(projectId, type, { signal });

// Query

export interface UseGetProjectPdfQueryProps {
  projectId: number;
  type: ReportTypes;
  options?: GetProjectPdfQueryProps['options'];
}

export const useGetProjectPdfQuery = ({
  projectId,
  type,
  options,
}: UseGetProjectPdfQueryProps) => {
  const queryKey = useMemo(
    () => createGetProjectPdfQueryKey([projectId, type]),
    [projectId, type]
  );

  return useQuery(queryKey, getProjectPdfQueryFn, options);
};

export interface GetProjectPdfQueryProps
  extends Omit<
    QueryProps<ProjectPdfUrl, unknown, ProjectPdfUrl, GetProjectPdfQueryKey>,
    'queryFn' | 'queryKey'
  > {
  projectId: number;
  type: ReportTypes;
}

export type GetProjectPdfQueryResult = Parameters<
  GetProjectPdfQueryProps['children']
>[0];

export const GetProjectPdfQuery = ({
  projectId,
  type,
  ...props
}: GetProjectPdfQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProjectPdfQueryKey([projectId, type])}
      queryFn={getProjectPdfQueryFn}
    />
  );
};
