import styled, { css, type ThemeProps } from 'styled-components';
import { type Theme } from 'js/theme';
import { AlertType } from './';

export const AlertMessage = styled.p`
  margin: 0;
  padding: 11px 16px;

  &:not(:first-of-type) {
    margin-top: 5px;
  }
`;

interface AlertStyledProps {
  type?: AlertType;
}

export const AlertStyled = styled.div<AlertStyledProps>`
  text-align: left;
  border-radius: 3px;
  margin-bottom: 10px;

  ${(props) =>
    props.type === AlertType.Danger
      ? css`
          background: ${(props: ThemeProps<Theme>) => props.theme.colors.beige};

          ${AlertMessage} {
            color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.lipstickTwo};
          }
        `
      : ''};
  ${(props) =>
    props.type === AlertType.Info
      ? css`
          ${AlertMessage} {
            color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
          }
        `
      : ''};
`;
