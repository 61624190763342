import React from 'react';
import { QueryClient, UseQueryOptions, type QueryFunction } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { TagType, type Tag } from '../';

/**
 * GET - /api/v1/public/tags
 */

// HTTP

export interface GetTagsXhr {
  result: Array<Tag & { tagTypeName?: string }>;
}

export interface GetTagsXhrParams {
  tagType?: TagType;
  tagTypeId?: number;
  itemId?: number;
  withTagTypeName?: boolean;
}

export interface GetTagsXhrConfig extends HttpBaseConfig {
  params?: GetTagsXhrParams;
}

export const getTagsXhr = (
  config: GetTagsXhrConfig = {}
): Promise<Array<Tag & { tagTypeName?: string }>> => {
  return http
    .get<GetTagsXhr>(`/api/v1/public/tags`, config)
    .then((res) => res.data.result);
};

// Query Key

const GET_TAGS_QUERY_KEY_NAMESPACE = 'getTags';

type GetTagsQueryKeyNamespace = typeof GET_TAGS_QUERY_KEY_NAMESPACE;

export type GetTagsQueryKey = [
  GetTagsQueryKeyNamespace,
  GetTagsXhrParams? /* params */
];

export const createGetTagsQueryKey = (
  key: SkipFirst<GetTagsQueryKey>
): GetTagsQueryKey => [GET_TAGS_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetTagsQueryFn = QueryFunction<Tag[], GetTagsQueryKey>;

export const getTagsQueryFn: GetTagsQueryFn = ({
  queryKey: [_d, params],
  signal,
}) => getTagsXhr({ params, signal });

// create Query Options

export type GetTagsQueryOptions = UseQueryOptions<
  Tag[],
  unknown,
  Tag[],
  GetTagsQueryKey
>;

export interface GetTagsQueryOptionsProps {
  params?: GetTagsXhrParams;
  options?: Omit<GetTagsQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetTagsQueryOptions = ({
  params,
  options,
}: GetTagsQueryOptionsProps): GetTagsQueryOptions => ({
  queryFn: getTagsQueryFn,
  queryKey: createGetTagsQueryKey([params]),
  ...options,
});

// Query

export interface GetTagsQueryProps
  extends Omit<
    QueryProps<Tag[], unknown, Tag[], GetTagsQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetTagsXhrParams;
}

export type GetTagsQueryRenderFn = GetTagsQueryProps['children'];

export type GetTagsQueryResult = Parameters<GetTagsQueryRenderFn>[0];

export const GetTagsQuery = ({ params, ...props }: GetTagsQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetTagsQueryKey([params])}
      queryFn={getTagsQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateTagsQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_TAGS_QUERY_KEY_NAMESPACE);
};
