import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  invalidateAllGetCustomerOrderLineItemsQueryData,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';
import {
  invalidatePricesQueryData,
  invalidateGetPriceListByIdQueryData,
  PriceList,
} from '../';

/**
 * PUT - /inventory/v1/projects/:project_id/prices/:id
 */

// HTTP

export interface UpdatePriceXhrBody {
  name?: string;
  discountPercentage?: number;
  includeAllItems?: boolean;
  roomId?: number;
  id: number;
}

interface UpdatePriceXhrVariables {
  projectId: number;
  priceListId: number;
  body: UpdatePriceXhrBody;
}

export const updatePriceXhr = ({
  projectId,
  priceListId,
  body,
}: UpdatePriceXhrVariables): Promise<PriceList> =>
  http
    .put(`/api/v1/eames/projects/${projectId}/price_lists/${priceListId}`, body)
    .then((res) => res.data);

// MutationFn

type UpdatePriceMutation = MutationFunction<PriceList, UpdatePriceXhrVariables>;

export const updatePriceMutationFn: UpdatePriceMutation = (variables) =>
  updatePriceXhr(variables);

// With Mutation HOC

const withUpdatePriceMutationPropKey = 'updatePriceMutation';

export type WithUpdatePriceMutationProps = WithMutationPropsByMutation<
  typeof withUpdatePriceMutationPropKey,
  UpdatePriceMutation
>;

export function withUpdatePriceMutation<P extends WithUpdatePriceMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdatePriceMutationProps>> {
  return withMutation(
    withUpdatePriceMutationPropKey,

    updatePriceMutationFn,
    (client) => ({
      onSuccess: async (_newPriceList, { projectId, priceListId }) => {
        await invalidatePricesQueryData(client, [projectId]);

        await invalidateGetPriceListByIdQueryData(client, [
          projectId,
          priceListId,
        ]);
        await invalidateAllGetCustomerOrderLineItemsQueryData(client);
        // Do not keep cache of specific price list instead of invalidate because it make some side effects
      },
    })
  )(Component as ComponentType<WithUpdatePriceMutationProps>);
}
