import React from 'react';

import { AlertStyled, AlertMessage } from './styled';

export enum AlertType {
  Danger = 'danger',
  Info = 'info',
}

interface Props extends Pick<React.HTMLAttributes<unknown>, 'className'> {
  type?: AlertType;
  messages: string[];
}

export default class Alert extends React.Component<Props> {
  private renderMessages() {
    return this.props.messages.map((message, index) => (
      <AlertMessage key={index}>{message}</AlertMessage>
    ));
  }

  override render() {
    const { className, type = AlertType.Danger } = this.props;
    return (
      <AlertStyled className={className} type={type}>
        {this.renderMessages()}
      </AlertStyled>
    );
  }
}
