export const locationService = {
  getPathName: () => window.location.pathname,
};

export function getPageName(): string {
  const pathname = locationService.getPathName();
  return pathNameToPageName(pathname);
}

export function pathNameToPageName(pathname: string): string {
  if (pathname === '/') {
    return 'Homepage';
  }
  if (pathname === '/n/service') {
    return 'Service Page';
  }
  if (pathname === '/n/our-story') {
    return 'Our Story Page';
  }
  if (pathname === '/n/faq') {
    return 'FAQ Page';
  }
  if (pathname === '/n/room_types') {
    return 'RoomType List Page';
  }
  if (/\/n\/room_types\/[a-zA-Z]*/g.test(pathname)) {
    return 'RoomType Details Page';
  }
  if (/\/n\/items\/[a-zA-Z]*/g.test(pathname)) {
    return 'Item Details Page';
  }
  if (/\/n\/items/g.test(pathname)) {
    return 'All Items Page';
  }
  if (/\/n\/item_types\/.*\/items/g.test(pathname)) {
    return 'ItemType Details Page';
  }
  if (/\/n\/item_types\/.*\/items/g.test(pathname)) {
    return 'ItemType Details Page';
  }
  if (pathname === '/n/blog') {
    return 'Blog List Page';
  }
  if (/\/n\/blog\/.*/g.test(pathname)) {
    return 'Blog Details Page';
  }
  if (/\/n\/search.*/g.test(pathname)) {
    return 'Search Page';
  }
  if (/\/n\/l.*/g.test(pathname)) {
    return 'Landing Page';
  }
  if (/\/n\/partnerships.*/g.test(pathname)) {
    return 'Partnerships Page';
  }
  if (/\/n\/faq.*/g.test(pathname)) {
    return 'FAQ Page';
  }

  return 'Unknown';
}
