import styled, { css, type ThemeProps } from 'styled-components';

import Button from 'js/components/partial/Button';
import { Dropdown } from 'js/components/partial/SelectDropdown/styled';
import { type Theme } from 'js/theme';
import { type ReactNode } from 'react';

export { default as CheckboxButton } from './CheckboxButton';
export { default as Checkbox } from './Checkbox';
export { default as QuantityInput } from './QuantityInput';
export { default as RadioButton } from './RadioButton';
export { default as Select } from './Select';
export { DateFormGroup } from './DatePicker';
export { FormGroup } from './FormGroup';

interface LabelProps {
  required?: boolean;
}

export const Label = styled.label<LabelProps>`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.battleshipGrey};
  text-transform: capitalize;
  display: inline-block;

  ${(props) =>
    props.required
      ? css`
          &::after {
            content: ' *';
            color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.lipstickTwo};
          }
        `
      : ''};
`;

export const LabelText = Label.withComponent('span');

interface WebkitSpinButtonProps {
  type?: string;
}

const WebkitSpinButton = css<WebkitSpinButtonProps>`
  ${(props) =>
    props.type !== 'text'
      ? css`
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        `
      : ''};
`;

interface BasicInputStyles {
  active?: boolean;
  error?: boolean;
}

export const BasicInputStyles = css<BasicInputStyles>`
  outline: 0;
  box-shadow: none;
  text-align: left;
  display: block;
  font-size: 14px;
  line-height: 1.42857143;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  height: 40px;
  padding: 6px 15px;
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  border: 1px solid
    ${(props: ThemeProps<Theme>) => props.theme.colors.lightBlueGrey};
  border-radius: 3px;

  ${(props) =>
    props.active
      ? css`
          border-color: ${(props: ThemeProps<Theme>) =>
            props.theme.colors.azure};
        `
      : ''};
  ${(props) =>
    props.error
      ? css`
          border-color: ${(props: ThemeProps<Theme>) =>
            props.theme.colors.lipstickTwo};
        `
      : ''};

  &:focus {
    border-color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
  }

  ${WebkitSpinButton};
`;

export const Input = styled.input`
  ${BasicInputStyles};
`;

export const InputAddon = styled.span`
  padding: 0 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  text-align: center;
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.3s ease-in-out;

  ${WebkitSpinButton};
`;

interface InputGroupBorderColorProps {
  focused?: boolean;
}

const inputGroupBorderColor = (
  props: InputGroupBorderColorProps & ThemeProps<Theme>
) => props.theme.colors[props.focused ? 'azure' : 'lightBlueGrey'];

export const InputGroup = styled.div<InputGroupBorderColorProps>`
  display: flex;
  border: 1px solid ${inputGroupBorderColor};
  border-radius: 3px;
  transition: border-color 0.3s ease-in-out;

  ${Input} {
    border: 0;
    flex-grow: 1;
    height: 38px;
  }

  ${InputAddon}:first-child {
    border-right: 1px solid ${inputGroupBorderColor};
  }

  ${InputAddon}:last-child {
    border-left: 1px solid ${inputGroupBorderColor};
  }
`;

export const InputButton = styled(Input.withComponent('button'))`
  position: relative;

  &::after {
    top: 15px;
    width: 7px;
    right: 18px;
    height: 7px;
    content: '';
    position: absolute;
    border-radius: 1px;
    border-style: solid;
    border-color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
  }
`;

interface FormGroupStyledProps {
  error?: boolean;
  minWidth?: number;
  marginBottom?: number;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
}

export const FormGroupStyled = styled.div<FormGroupStyledProps>`
  position: relative;
  margin-bottom: ${(props) =>
    props.marginBottom !== undefined ? `${props.marginBottom}px` : `16px`};

  ${(props) => props.minWidth && `min-width: ${props.minWidth}px`}

  ${(props) =>
    props.error
      ? css`
          ${Label}, ${LabelText} {
            color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.lipstickTwo};
          }

          ${Input}, ${InputGroup}, ${InputAddon}, ${InputButton}, ${Dropdown} {
            border-color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.lipstickTwo};
          }
        `
      : ''};
  ${(props) =>
    props.disabled
      ? css`
          ${Input}, ${InputGroup}, ${InputAddon}, ${InputButton}, ${Dropdown} {
            background-color: ${(props: ThemeProps<Theme>) =>
              props.theme.colors.grayXXLight};
          }
        `
      : ''};
`;

export const FormActions = styled.div`
  padding-top: 9px;
  text-align: center;
`;

interface FormButtonProps {
  first?: boolean;
}

export const FormButton = styled(Button)<FormButtonProps>`
  min-width: 140px;

  ${(props) =>
    !props.first
      ? css`
          margin-left: 20px;
        `
      : ''};
`;

export const TextArea = styled.textarea`
  ${BasicInputStyles};
  resize: none;
  height: auto;
`;

export const DatePickerWrapper = styled.div`
  input {
    ${BasicInputStyles}
    height: 38px;
  }
`;
