import React from 'react';
import { QueryClient, UseQueryOptions, type QueryFunction } from 'react-query';

import { SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type ProductType } from '../';
import { mockResponse } from 'js/api/mock/utils';
import { productTypesMock } from 'js/api/mock/productTypesMock';

/**
 * GET - /inventory/v1/product_types
 */

// HTTP

export interface GetProductTypesXhr {
  data: ProductType[];
}

export interface GetProductTypesXhrParams {
  onlyActive?: boolean;
}

export interface GetProductTypesXhrConfig extends HttpBaseConfig {
  params?: GetProductTypesXhrParams;
}

export const getProductTypesXhr = (
  config: GetProductTypesXhrConfig = {}
): Promise<ProductType[]> => mockResponse(productTypesMock);
// http
//   .get<GetProductTypesXhr>(`/inventory/v1/product_types`, config)
//   .then((res) => res.data.data);

// Query Key

const GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE = 'getProductTypes';

type GetProductTypesQueryKeyNamespace =
  typeof GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE;

export type GetProductTypesQueryKey = [
  GetProductTypesQueryKeyNamespace,
  GetProductTypesXhrParams? /* params */
];

export const createGetProductTypesQueryKey = (
  key: SkipFirst<GetProductTypesQueryKey>
): GetProductTypesQueryKey => [GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

type GetProductTypesQueryFn = QueryFunction<
  ProductType[],
  GetProductTypesQueryKey
>;

export const getProductTypesQueryFn: GetProductTypesQueryFn = ({
  queryKey: [_d, params],
  signal,
}) => getProductTypesXhr({ params, signal });

// create Query Options

export type GetProductTypesQueryOptions = UseQueryOptions<
  ProductType[],
  unknown,
  ProductType[],
  GetProductTypesQueryKey
>;

export interface GetProductTypesQueryOptionsProps {
  params?: GetProductTypesXhrParams;
  options?: Omit<GetProductTypesQueryOptions, 'queryFn' | 'queryKey'>;
}

export const createGetProductTypesQueryOptions = ({
  params,
  options,
}: GetProductTypesQueryOptionsProps): GetProductTypesQueryOptions => ({
  queryFn: getProductTypesQueryFn,
  queryKey: createGetProductTypesQueryKey([params]),
  ...options,
});

// Query

export interface GetProductTypesQueryProps
  extends Omit<
    QueryProps<ProductType[], unknown, ProductType[], GetProductTypesQueryKey>,
    'queryFn' | 'queryKey'
  > {
  params?: GetProductTypesXhrParams;
}

export type GetProductTypesQueryRenderFn =
  GetProductTypesQueryProps['children'];

export type GetProductTypesQueryResult =
  Parameters<GetProductTypesQueryRenderFn>[0];

export const GetProductTypesQuery = ({
  params,
  ...props
}: GetProductTypesQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetProductTypesQueryKey([params])}
      queryFn={getProductTypesQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateProductTypesQueryData = async (client: QueryClient) => {
  await client.invalidateQueries(GET_PRODUCT_TYPES_QUERY_KEY_NAMESPACE);
};
