import React from 'react';
import { type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type VestaHomeProduct } from '../models';
import { mockResponse } from 'js/api/mock/utils';
import { vestaProduct } from 'js/api/mock/vestaProduct';

/**
 * GET - /vesta_home/v1/products/:id
 */

// HTTP

export interface GetVestaProductXhr {
  data: VestaHomeProduct;
}

export const getVestaProductXhr = (
  productId: number,
  config: HttpBaseConfig = {}
): Promise<VestaHomeProduct> => mockResponse(vestaProduct);
// http
//   .get<GetVestaProductXhr>(`/vesta_home/v1/products/${productId}`, config)
//   .then((res) => res.data.data);

// Query Key

const GET_PRODUCT_BY_VESTA_HOME_QUERY_KEY_NAMESPACE = 'getProductByVestaHome';

type GetVestaProductQueryKeyNamespace =
  typeof GET_PRODUCT_BY_VESTA_HOME_QUERY_KEY_NAMESPACE;

export type GetVestaProductQueryKey = [
  GetVestaProductQueryKeyNamespace,
  number /* product id */
];

export const createGeVestatProductQueryKey = (
  key: SkipFirst<GetVestaProductQueryKey>
): GetVestaProductQueryKey => [
  GET_PRODUCT_BY_VESTA_HOME_QUERY_KEY_NAMESPACE,
  ...key,
];

// QueryFn

export const getVestaProductQueryFn: QueryFunction<
  VestaHomeProduct,
  GetVestaProductQueryKey
> = ({ queryKey: [_d, productId] }) => getVestaProductXhr(productId);

// Query

export interface GetVestaProductQueryProps
  extends Omit<
    QueryProps<
      VestaHomeProduct,
      unknown,
      VestaHomeProduct,
      GetVestaProductQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  productId: number;
}

export type GetVestaProductQueryResult = Parameters<
  GetVestaProductQueryProps['children']
>[0];

export const GetVestaProductQuery = ({
  productId,
  ...props
}: GetVestaProductQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGeVestatProductQueryKey([productId])}
      queryFn={getVestaProductQueryFn}
    />
  );
};
