import styled, { type ThemeProps } from 'styled-components';

import Alert from 'js/components/partial/Alert';
import Button, { type Props } from 'js/components/partial/Button';
import { Input } from 'js/components/partial/Forms';
import { type Theme } from 'js/theme';
import IconCheck from 'js/icons/Check';
import IconChair from 'js/icons/Chair';

export const Content = styled.div`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  left: 0;
  margin-top: -16px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 1;
`;

export const IconChairStyled = styled(IconChair)`
  height: 50px;
  width: auto;
`;

export const IconCheckStyled = styled(IconCheck)`
  height: 18px;
  width: auto;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  path {
    fill: ${(props: ThemeProps<Theme>) => props.theme.colors.greenLime};
  }
`;

export const Title = styled.h1`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0.6px;
  line-height: normal;
  margin: 0;
  text-transform: uppercase;
`;

export const Form = styled.div`
  margin-top: 26px;
  transition: border-color 0.3s ease-in-out;
`;

export const InputStyled = styled(Input)`
  margin-bottom: 20px;
`;

export const ButtonStyled = styled(Button)<Props>`
  margin-top: 10px;
  width: 140px;
`;

export const AlertStyled = styled(Alert)`
  margin-top: 25px;
`;

export const CaptionTextStyled = styled.p`
  color: black;
  padding-top: 10px;
  font-size: 10px;
`;
