import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import {
  http,
  invalidateComponentsInfiniteQueryData,
  withMutation,
  type WithMutationPropsByMutation,
} from 'js/api/';

/**
 * PUT - /api/v1/eames/items/:id
 */

// HTTP

interface UpdateComponentXhrVariables {
  itemId: number;
  body: UpdateComponentXhrBody;
}

export interface UpdateComponentXhrBody {
  projectId?: number;
  baseCost?: number;
  retailPrice?: number;
  name?: string;
  description?: string;
  // images?: ImageBase[];
  itemTypeId?: number;
  supplier?: string;
  quantity?: number;
}

export const updateComponentXhr = ({
  body,
  itemId,
}: UpdateComponentXhrVariables): Promise<unknown> =>
  http
    .put<unknown>(`/api/v1/eames/items/${itemId}`, body)
    .then((res) => res.data);

// MutationFn

type UpdateComponentMutation = MutationFunction<
  unknown,
  UpdateComponentXhrVariables
>;

export const updateComponentMutationFn: UpdateComponentMutation = (variables) =>
  updateComponentXhr(variables);

// With Mutation HOC

const withUpdateComponentMutationPropKey = 'updateComponentMutation';

export type WithUpdateComponentMutationProps = WithMutationPropsByMutation<
  typeof withUpdateComponentMutationPropKey,
  UpdateComponentMutation
>;

export function withUpdateComponentMutation<
  P extends WithUpdateComponentMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithUpdateComponentMutationProps>> {
  return withMutation(
    withUpdateComponentMutationPropKey,
    updateComponentMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateComponentsInfiniteQueryData(client);
      },
    })
  )(Component as ComponentType<WithUpdateComponentMutationProps>);
}
