import styled, { css } from 'styled-components';
import config, { CONTAINER_SIZES } from '../config';

interface GridProps {
  fluid?: boolean;
  children?: any;
  iPad?: boolean;
}

const Grid = styled.div<GridProps>`
  margin-right: auto;
  margin-left: auto;
  padding-right: ${(p) => config(p).outerMargin + 'rem'};
  padding-left: ${(p) => config(p).outerMargin + 'rem'};

  ${(p) =>
    !p.fluid &&
    css`
      ${CONTAINER_SIZES.map(
        (t) =>
          config(p).container[t] &&
          config(p).media[t]`
        width: ${config(p).container[t]}rem;
      `
      )}
    `}
`;

export default Grid;
