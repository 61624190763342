import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidatePricesQueryData, type PriceList } from '../';

/**
 * POST - /api/v1/eames/projects/:projectId/price_lists
 */

// HTTP

export enum PriceItemStatusFilter {
  All = 'all',
  Sold = 'sold',
  OnSite = 'on_site',
  Reserved = 'reserved',
}
interface CreatePriceXhrVariables {
  projectId: number;
  body: CreatePriceXhrBody;
}

export interface PriceItemInput {
  id?: number;
  roomProductItemId: number;
  discount: number;
  eligible: boolean;
  itemRetailPrice: number;
}

export interface CreatePriceXhrBody {
  name: string;
  filter_by?: PriceItemStatusFilter | null;
}

export const createPriceXhr = ({
  projectId,
  body,
}: CreatePriceXhrVariables): Promise<PriceList> =>
  http
    .post<PriceList>(`/api/v1/eames/projects/${projectId}/price_lists`, body)
    .then((res) => res.data);

// MutationFn

type CreatePriceMutation = MutationFunction<PriceList, CreatePriceXhrVariables>;

export const createPriceMutationFn: CreatePriceMutation = (variables) =>
  createPriceXhr(variables);

// With Mutation HOC

const withCreatePriceMutationPropKey = 'createPriceMutation';

export type WithCreatePriceMutationProps = WithMutationPropsByMutation<
  typeof withCreatePriceMutationPropKey,
  CreatePriceMutation
>;

export function withCreatePriceMutation<P extends WithCreatePriceMutationProps>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreatePriceMutationProps>> {
  return withMutation(
    withCreatePriceMutationPropKey,
    createPriceMutationFn,
    (client) => ({
      onSuccess: async (_newPriceList, { projectId }) => {
        await invalidatePricesQueryData(client, [projectId]);
      },
    })
  )(Component as ComponentType<WithCreatePriceMutationProps>);
}
