export const collectionsMock = [
  {
    id: 35,
    name: 'Collection Test 2',
    description: null,
    created_at: '2022-07-22T15:29:48.822Z',
    updated_at: '2022-07-22T15:29:48.822Z',
    status: 'active',
  },
  {
    id: 34,
    name: 'Collection Test 1',
    description: null,
    created_at: '2022-07-22T15:29:37.567Z',
    updated_at: '2022-07-22T15:30:36.283Z',
    status: 'active',
  },
];
