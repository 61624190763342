import React from 'react';
import { type QueryClient, type QueryFunction } from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type RoomProduct,
} from 'js/api';
import { mockResponse } from 'js/api/mock/utils';
import { roomProductMock } from 'js/api/mock/roomProductMock';

/**
 * GET - /inventory/v1/room_products/:roomProductId
 */

// HTTP

export interface GetRoomProductXhr {
  data: RoomProduct;
}

export const getRoomProductXhr = (
  roomProductId: RoomProduct['id'],
  config: HttpBaseConfig
): Promise<GetRoomProductXhr> => mockResponse({ data: roomProductMock });
// http
//   .get<GetRoomProductXhr>(
//     `/inventory/v1/room_products/${roomProductId}`,
//     config
//   )
//   .then((res) => res.data);

// Query Key

const GET_ROOM_PRODUCT_QUERY_KEY_NAMESPACE = 'roomProduct';

type GetRoomProductQueryKeyNamespace =
  typeof GET_ROOM_PRODUCT_QUERY_KEY_NAMESPACE;

export type GetRoomProductQueryKey = [
  GetRoomProductQueryKeyNamespace,
  number /* roomProduct id */
];

export const createGetRoomProductQueryKey = (
  key: SkipFirst<GetRoomProductQueryKey>
): GetRoomProductQueryKey => [GET_ROOM_PRODUCT_QUERY_KEY_NAMESPACE, ...key];

// QueryFn

export const getRoomProductQueryFn: QueryFunction<
  GetRoomProductXhr,
  GetRoomProductQueryKey
> = ({ queryKey: [_d, projectId], signal }) =>
  getRoomProductXhr(projectId, { signal });

// Query

export interface GetRoomProductQueryProps
  extends Omit<
    QueryProps<
      GetRoomProductXhr,
      Error,
      GetRoomProductXhr,
      GetRoomProductQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  roomProductId: RoomProduct['id'];
}

export type GetRoomProductQueryResult = Parameters<
  GetRoomProductQueryProps['children']
>[0];

export const GetRoomProductQuery = ({
  roomProductId,
  ...props
}: GetRoomProductQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetRoomProductQueryKey([roomProductId])}
      queryFn={getRoomProductQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetRoomProductQueryData = async (
  client: QueryClient,
  keyParams: SkipFirst<GetRoomProductQueryKey>
) => {
  await client.invalidateQueries(createGetRoomProductQueryKey(keyParams));
};
