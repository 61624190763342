import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import {
  type Material,
  type MaterialId,
  invalidateProductMaterialsQueryData,
} from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/materials
 * PUT - /inventory/v1/materials/:id
 */

// HTTP

export interface CreateSubMaterialType
  extends Pick<Material, 'name' | 'type' | 'instructions'> {
  image?: string;
}

export interface CreateSubMaterialXhrVariables {
  parentMaterialId: MaterialId;
  material: CreateSubMaterialType;
}

export interface CreateSubMaterialXhrResponse {
  data: Omit<Material, 'subMaterials'>;
}

export const createSubMaterialXhr = async ({
  parentMaterialId,
  material,
}: CreateSubMaterialXhrVariables): Promise<Material | null> => mockResponse({});
// {
//   const createResponse = await http.post<CreateSubMaterialXhrResponse>(
//     `/inventory/v1/materials`,
//     material
//   );
//   const createdMaterial = createResponse.data.data;
//   const bodyForAssign = {
//     material: { type: createdMaterial.type },
//     submaterial: { id: createdMaterial.id },
//   };

//   try {
//     const assignResponse = await http.put<CreateSubMaterialXhrResponse>(
//       `/inventory/v1/materials/${parentMaterialId}`,
//       bodyForAssign
//     );
//     return assignResponse.data.data;
//   } catch (error) {
//     await http.delete<unknown>(`/inventory/v1/materials/${createdMaterial.id}`);
//     throw Error(`Create submaterial ${createdMaterial.name} is failed`);
//   }
// };

// MutationFn

type CreateSubMaterialMutation = MutationFunction<
  Material | null,
  CreateSubMaterialXhrVariables
>;

export const createSubMaterialMutationFn: CreateSubMaterialMutation = (
  variables
) => createSubMaterialXhr(variables);

// With Mutation HOC

const withCreateSubMaterialMutationPropKey = 'createSubMaterialMutation';

export type WithCreateSubMaterialMutationProps = WithMutationPropsByMutation<
  typeof withCreateSubMaterialMutationPropKey,
  CreateSubMaterialMutation
>;

export function withCreateSubMaterialMutation<
  P extends WithCreateSubMaterialMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateSubMaterialMutationProps>> {
  return withMutation(
    withCreateSubMaterialMutationPropKey,
    createSubMaterialMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateProductMaterialsQueryData(client);
      },
    })
  )(Component as ComponentType<WithCreateSubMaterialMutationProps>);
}
