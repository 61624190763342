import { type ComponentType } from 'react';
import { type MutationFunction } from 'react-query';

import { http, withMutation, type WithMutationPropsByMutation } from 'js/api/';
import { invalidateCollectionsQueryData } from '..';
import { mockResponse } from 'js/api/mock/utils';

/**
 * POST - /inventory/v1/collections
 */

// HTTP

export interface CreateCollectionXhrBody {
  name: string;
}

export const createCollectionXhr = (
  body: CreateCollectionXhrBody
): Promise<unknown> => mockResponse({});
// http.post<unknown>(`/inventory/v1/collections`, body);

// MutationFn

type CreateCollectionMutation = MutationFunction<
  unknown,
  CreateCollectionXhrBody
>;

export const createCollectionMutationFn: CreateCollectionMutation = (body) =>
  createCollectionXhr(body);

// With Mutation HOC

const withCreateCollectionMutationPropKey = 'createCollectionMutation';

export type WithCreateCollectionMutationProps = WithMutationPropsByMutation<
  typeof withCreateCollectionMutationPropKey,
  CreateCollectionMutation
>;

export function withCreateCollectionMutation<
  P extends WithCreateCollectionMutationProps
>(
  Component: ComponentType<P>
): ComponentType<Omit<P, keyof WithCreateCollectionMutationProps>> {
  return withMutation(
    withCreateCollectionMutationPropKey,
    createCollectionMutationFn,
    (client) => ({
      onSuccess: async () => {
        await invalidateCollectionsQueryData(client);
      },
    })
  )(Component as ComponentType<WithCreateCollectionMutationProps>);
}
