import React from 'react';
import { type QueryFunction } from 'react-query';

import { http, Query, type QueryProps, type HttpBaseConfig } from 'js/api/';

import { type GetNewProductPayloadXhr } from '../models';
import { mockResponse } from 'js/api/mock/utils';
import { newProductMock } from 'js/api/mock/newProductMock';

/**
 * GET - /inventory/v1/products/new_product
 * Get initial data to create new product form
 */

// HTTP

export const getNewProductXhr = (
  config: HttpBaseConfig = {}
): Promise<GetNewProductPayloadXhr> => mockResponse(newProductMock);
// http
//   .get<GetNewProductPayloadXhr>(`/inventory/v1/products/new_product`, config)
//   .then((res) => res.data);

// Query Key

const GET_NEW_PRODUCT_QUERY_KEY_NAMESPACE = 'getNewProduct';

type GetNewProductQueryKeyNamespace =
  typeof GET_NEW_PRODUCT_QUERY_KEY_NAMESPACE;

export type GetNewProductQueryKey = [GetNewProductQueryKeyNamespace];

export const createGetNewProductQueryKey = (): GetNewProductQueryKey => [
  GET_NEW_PRODUCT_QUERY_KEY_NAMESPACE,
];

// QueryFn

export const getNewProductQueryFn: QueryFunction<
  GetNewProductPayloadXhr,
  GetNewProductQueryKey
> = () => getNewProductXhr();

// Query

export type GetNewProductQueryProps = Omit<
  QueryProps<
    GetNewProductPayloadXhr,
    unknown,
    GetNewProductPayloadXhr,
    GetNewProductQueryKey
  >,
  'queryFn' | 'queryKey'
>;

export type GetNewProductQueryResult = Parameters<
  GetNewProductQueryProps['children']
>[0];

export const GetNewProductQuery = (props: GetNewProductQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetNewProductQueryKey()}
      queryFn={getNewProductQueryFn}
    />
  );
};
