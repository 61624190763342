import { useEffect, type ReactElement } from 'react';
import {
  useQuery,
  useInfiniteQuery,
  useQueries,
  type QueryKey,
  type QueryFunction,
  type QueriesOptions,
  type QueriesResults,
  type UseQueryOptions,
  type UseQueryResult,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
  type InfiniteData,
} from 'react-query';

export interface QueryBaseProps<
  TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> {
  queryKey: TQueryKey;
  queryFn: QueryFunction<TQueryFnData, TQueryKey>;
}

// Query

export interface QueryProps<
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends QueryBaseProps<TQueryFnData, TQueryKey> {
  children: (
    result: UseQueryResult<TData, TError>
  ) => ReactElement<unknown> | null;
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >;
  // Run options.onSuccess on mount if refetchOnMount = false
  alwaysRunOnSuccess?: boolean;
}

export function Query<
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  children,
  queryKey,
  queryFn,
  options,
  alwaysRunOnSuccess,
}: QueryProps<
  TQueryFnData,
  TError,
  TData,
  TQueryKey
>): ReactElement<unknown> | null {
  const result = useQuery(queryKey, queryFn, options);

  // Run options.onSuccess on mount if refetchOnMount = false
  useEffect(() => {
    if (
      options?.onSuccess &&
      !options.refetchOnMount &&
      alwaysRunOnSuccess &&
      result.isSuccess
    ) {
      options.onSuccess(result.data);
    }
  }, []);

  return children(result);
}

// InfiniteQuery

export interface InfiniteQueryProps<
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> extends QueryBaseProps<TQueryFnData, TQueryKey> {
  children: (
    result: UseInfiniteQueryResult<TData, TError>
  ) => ReactElement<unknown> | null;
  options?: Omit<
    UseInfiniteQueryOptions<
      TQueryFnData,
      TError,
      TData,
      TQueryFnData,
      TQueryKey
    >,
    'queryKey' | 'queryFn'
  >;
}

export function InfiniteQuery<
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>({
  children,
  queryKey,
  queryFn,
  options,
}: InfiniteQueryProps<
  TQueryFnData,
  TError,
  TData,
  TQueryKey
>): ReactElement<unknown> | null {
  const result = useInfiniteQuery(queryKey, queryFn, options);

  return children(result);
}

// Helpers

export function isInfiniteData<D extends {}>(
  data: D | InfiniteData<D>
): data is InfiniteData<D> {
  return 'pages' in data && 'pageParams' in data;
}

// Queries

export interface QueriesProps<T extends any[]> {
  queries: readonly [...QueriesOptions<T>];
  children: (results: QueriesResults<T>) => ReactElement<unknown> | null;
}

export function Queries<T extends any[]>({
  queries,
  children,
}: QueriesProps<T>) {
  const results = useQueries(queries);

  return children(results);
}
