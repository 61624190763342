import { makeMock } from '@utility_functions/makeMock';

// todo - move me

export type SaveReferralCodeResponse = {
  applicability: string;
  code: string;
  dollarsOff: number | number;
  percentOff: null | number;
  duration: number;
  referrerGivenName: string;
  referrerFamilyName: string;
};

export const saveReferralCodeResponseMock = makeMock<SaveReferralCodeResponse>({
  applicability: 'subscription',
  code: 'AA0414xFRIENDS',
  dollarsOff: 50,
  percentOff: null,
  duration: 2,
  referrerGivenName: 'AJ',
  referrerFamilyName: 'A',
});
